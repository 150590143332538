import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './Menu.css';

class Menu extends Component {
  state = {
    readyToRedirect: false,
    redirectAddress: ''
  };

  handleRedirect = (redirectAddress) => {
    this.setState({ readyToRedirect: true });
    setTimeout(() => {
      this.setState({ redirectAddress });
    }, 500);
  };

  render = () => {
    if (this.state.redirectAddress) {
      return <Redirect push to={this.state.redirectAddress} />;
    }

    return (
      <div className={`Menu ${this.state.readyToRedirect ? 'fade-out' : ''}`}>
        {/* <div className='description'>
                    <h3 className='description'>Lorem</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                </div> */}
        <div className="items">
          <div
            className="item selection"
            onClick={() => this.handleRedirect('/organisation')}
          >
            <div className="icon">
              <span className="fas fa-award" />
            </div>

            <div className="content">
              <h4 className="header">
                {this.props.polyglot.t(
                  `Organisation.menu.diplomasByAuthorization`
                )}
              </h4>
              <div className="description">
                <p>
                  {this.props.polyglot.t(
                    'Organisation.menu.getAccessToDegreesInfo'
                  )}{' '}
                  <em>
                    {this.props.polyglot.t('Organisation.menu.ediplomas')}
                  </em>
                  .
                </p>
              </div>
              <button className="arrow">
                <span className="fas fa-arrow-right" />
              </button>
            </div>
          </div>

          <div
            className="item selection"
            onClick={() => this.handleRedirect('/organisation/verify')}
          >
            <div className="icon">
              <span className="fas fa-check-circle" />
            </div>

            <div className="content">
              <h4 className="header">
                {this.props.polyglot.t(
                  `Organisation.menu.signatureVerification`
                )}
              </h4>
              <div className="description">
                <p>
                  {this.props.polyglot.t(
                    'Organisation.menu.verifySignatureValidityDiplomasIssuedBy'
                  )}{' '}
                  <em>
                    {this.props.polyglot.t('Organisation.menu.ediplomas')}
                  </em>
                  .
                </p>
              </div>
              <button className="arrow">
                <span className="fas fa-arrow-right" />
              </button>
            </div>
          </div>

          <div
            className="item selection"
            onClick={() => this.handleRedirect('/organisation/check')}
          >
            <div className="icon">
              <span className="fas fa-search" />
            </div>

            <div className="content">
              <h4 className="header">
                {this.props.polyglot.t(`Organisation.menu.diplomaCheck`)}
              </h4>
              <div className="description">
                <p>
                  {this.props.polyglot.t(
                    `Organisation.menu.verifyDegreeAuthenticity`
                  )}{' '}
                  <em>
                    {this.props.polyglot.t('Organisation.menu.ediplomas')}
                  </em>{' '}
                  {this.props.polyglot.t('Organisation.menu.onlyPublicSector')}.
                </p>
              </div>
              <button className="arrow">
                <span className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default Menu;
