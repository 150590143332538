import React from 'react';
import styled, { keyframes } from 'styled-components';

// import './Spinner.css'

const loop = keyframes`
  0% {
    stroke-dashoffset: 1px;
  }
  70%{
    stroke-dasharray: 20px;
  }
  100% {
    stroke-dashoffset: 111px;
  }
`;

const Circle = styled.circle`
  fill: none;
  stroke: #00c0f3;
  stroke-width: 2px;
  stroke-dasharray: 113px; /* 2*r*pi */
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: ${loop} 4.5s linear 1 alternate-reverse;
  animation-iteration-count: infinite;
  opacity: 1;
  cx: ${(props) => props.cx};
  cy: ${(props) => props.cy};
  r: ${(props) => props.r};
`;

const Svg = styled.svg`
  width: ${(props) => parseInt(props.cx) * 2}px;
  height: ${(props) => parseInt(props.cy) * 2}px;
`;

export const Spinner = (props) => {
  return (
    <Svg width={100} height={100}>
      <Circle cx={props.cx} cy={props.cy} r={props.r} />
    </Svg>
  );
};
