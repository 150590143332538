import React, { Component } from 'react';
import Select from 'react-select';
import { Redirect, Link } from 'react-router-dom';
import { scrollTop } from '../lib/Util';
import './ClientSelect.css';

class ClientSelect extends Component {
  state = {
    selectedClient: {
      display_name: '',
      name: ''
    },
    next: false,
    clients: []
  };

  UNSAFE_componentWillMount = async () => {
    // Fetch client list
    const res = await fetch('/api/user/clients/');
    const jsonres = await res.json();
    const { clients } = jsonres;
    this.setState({
      clients: clients.map((client) => ({
        key: client.id,
        value: client.name,
        label: client.display_name
      }))
    });
  };

  onChange = (selection) => {
    const display_name = this.state.clients.filter(
      (client) => client.value === selection.value
    )[0].label;
    const newSelected = {
      name: selection.value,
      display_name
    };
    this.setState({ selectedClient: newSelected });
  };

  onBack = () => {
    sessionStorage.setItem('clientId', this.state.selectedClient.name);
    this.setState({
      next: true
    });
  };

  onNext = () => {
    sessionStorage.setItem('clientId', this.state.selectedClient.name);
    this.setState({
      next: true
    });
  };

  componentDidMount = () => {
    scrollTop();
  };

  t = (text) => this.props.polyglot.t(text);

  render = () => (
    <div className="ClientSelect">
      <div className="step-description">
        <h2>{this.props.polyglot.t('Graduate.title1')}</h2>
        <p>{this.props.polyglot.t('Graduate.paragraph1a')}</p>
        <p>
          {this.props.polyglot.t('Graduate.paragraph1b')}{' '}
          <a
            href="https://authorization.ediplomas.gr/mydiplomas/consents"
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.props.polyglot.t('Graduate.paragraph1c')}{' '}
            <span className="fas fa-external-link-alt"></span>
          </a>{' '}
          {this.props.polyglot.t('Graduate.paragraph1d')}
        </p>
      </div>
      {/* <Dropdown placeholder={this.props.polyglot.t('Graduate.placeholder1')} fluid search selection options={this.state.clients} onChange={this.onChange}/> */}
      {/* <Form> */}
      <p className="selected-client">
        {/* <span className="fas fa-briefcase" />{' '} */}
        <strong>{this.props.polyglot.t('Graduate.organization')}</strong>
        <br />
        <Select
          className="dropdown"
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            paddingTop: 10,
            colors: {
              ...theme.colors,
              primary: '#003476',
              primary25: 'rgb(224, 224, 224)'
            }
          })}
          styles={{
            container: (provided) => ({
              ...provided,
              paddingTop: 5
            })
          }}
          options={this.state.clients}
          onChange={this.onChange}
          isSearchable
        />
      </p>
      <div className="buttons">
        <Link to="/" className="back-link">
          <span className="fas fa-arrow-left" />{' '}
          {this.props.polyglot.t('Graduate.back')}
        </Link>
        <button
          onClick={this.onNext}
          className={`ui fancy button ${
            this.state.selectedClient.name ? '' : 'disabled'
          }`}
        >
          {this.props.polyglot.t('Graduate.continue')}{' '}
          <span className="fas fa-arrow-right" />
        </button>
      </div>
      {this.state.next && <Redirect push to="/graduate/2" />}
    </div>
  );
}

export default ClientSelect;
