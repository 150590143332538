import React from 'react';
import Footer from '../Footer';
import './News.css';

import Helmet from 'react-helmet';

const EBSIReport = (props) => (
  <div className="NewsPage">
    <div className="content">
      <div className="breadcrumbs">
        <a className="home" href="/">
          <span className="fas fa-home"> </span>{' '}
          {props.polyglot.t(`newsBreadcrumbs.home`)}
        </a>{' '}
        |
        <span className="paths">
          {props.polyglot.t(`newsBreadcrumbs.news`)} >{' '}
          <a href="/news/ebsi">
            {' '}
            EBSI: Cypriot Citizen’s journey as a Greek University graduate
          </a>
        </span>
      </div>
      <h1 className="header">
        EBSI: Cypriot Citizen’s journey as a Greek University graduate
      </h1>
      <Helmet>
        <title>
          eDiplomas - EBSI: Cypriot Citizen’s journey as a Greek University
          graduate
        </title>
      </Helmet>
      <p>
        With the verification of university diplomas being a critical aspect in
        the mobility of learners and professionals and vital for the success of
        the European HEIs network, the eDiplomas team has been closely
        monitoring the initiatives that focus on cross-border scenarios, one of
        which is the{' '}
        <a href="https://digital-strategy.ec.europa.eu/en/policies/blockchain-partnership">
          European Blockchain Partnership
        </a>{' '}
        (EBP).
      </p>
      <p>
        In the European Blockchain Partnership (EBP) 29 countries decided to
        join their efforts for providing a{' '}
        <a href="https://ec.europa.eu/digital-building-blocks/wikis/display/CEFDIGITAL/EBSI">
          European Blockchain Services Infrastructure
        </a>{' '}
        (EBSI), with the goal of enabling the partners to leverage Distributed
        Ledger Technologies in cross-border services. The EBP initiated an
        incubator programme to help tap into the potential of the EBSI
        infrastructure. With the support of the EBSI team, Early Adopters were
        able to familiarize with EBSI components and identify technical or
        logical gaps that had to be bridged in order to demonstrate real-life
        scenarios involving the exchange of Verifiable Credentials in their
        respective area.
      </p>
      <p>
        In this framework, the group of{' '}
        <b>
          Cyprus and Greece joined forces around the challenge of a Cypriot
          citizen who has graduated from a Greek University and needs to get her
          license in order to practice her profession in Cyprus
        </b>
        . The technology readiness of the suggested solution combined with the
        fact that it addresses a real world scenario with obvious benefits for
        both member states, led to the selection of the Greek – Cypriot cluster
        to participate in the first round of the EBSI cross-border demos that
        took place in December 2021. The demo was part of a multi-university
        pilot aiming on providing a cross-border solution that facilitates the
        issuance and verification of University diplomas across member states.
      </p>
      <h1 className="header"> The scenario </h1>
      <p>Figure 1 illustrates the use case covered by the project.</p>
      <img src="/ebsi.png" width="50%" />
      <p>Figure 1. Cross-border scenario definition</p>
      <p>
        The eDiplomas platform authenticates the Cypriot citizen based on the
        verifiable attestation (VC-ID) issued by the Republic of Cyprus. Then
        the citizen retrieves her digital diploma issued by the University of
        Athens and the eDiplomas platform issues a verifiable credential
        (VC-Diploma) for the requested diploma, which is saved by the user in
        her CY-EBSI wallet. Following that, the citizen applies to become a
        member of the{' '}
        <a href="https://www.etek.org.cy/">
          Cyprus Scientific and Technical Chamber{' '}
        </a>
        by presenting her VC-ID and VC-Diploma in order to get a license to
        practice her profession in Cyprus (VC-License).
      </p>
      <h1 className="header">Team members</h1>
      <p>From Greece:</p>
      <ul>
        <li>
          <a href="https://www.gunet.gr/en/">
            Greek Universities Network GUnet
          </a>
        </li>
        <li>
          <a href="https://www.minedu.gov.gr/">
            Greek Ministry of Education and Religious Affairs
          </a>
        </li>
      </ul>
      <p>From Cyprus:</p>
      <ul>
        <li>
          <a href="https://acgoldman.com/">
            AC Goldman Solutions & Services GSS Ltd
          </a>
        </li>
        <li>
          <a href="http://www.talos-rtd.com/easyconsole.cfm/id/1/lang/en">
            RTD TALOS LTD
          </a>
        </li>
        <li>
          <a href="https://www.edex.ac.cy/">
            University of Nicosia – Educational Excellence Corporation Ltd
          </a>
        </li>
        <li>
          <a href="https://www.dmrid.gov.cy/dmrid/research.nsf/home_en/home_en?opendocument">
            Deputy Ministry of Research, Innovation and Digital Policy
          </a>
        </li>
      </ul>
      <p>
        <i>
          Many thanks to the EBSI team for their unparalleled support and
          guidance and our Cypriot partners for the excellent collaboration.
        </i>
      </p>
    </div>
    <Footer polyglot={props.polyglot} />
  </div>
);

export default EBSIReport;
