import React from 'react';
import { Heading } from '../Common';
import MailExample from '../../components/MailExample';

const requestForRegistration = (
  <>
    <span>Name: Acme Corporation Ltd</span>
    <br />
    <span>Sector: Public</span>
    <br />
    <span>Organisation TIN: 123456789</span>
    <br />
    <br />
    <span>Contact Details</span>
    <br />
    <span>Name Surname: John Doe</span>
    <br />
    <span>Telephone: 2106263647</span>
  </>
);

const requestForOauth2Registration = (
  <>
    <span>Name: Acme Corporation Ltd</span>
    <br />
    <span>Sector: Private</span>
    <br />
    <span>Organisation TIN: 123456789</span>
    <br />
    <br />
    <span>Contact Details of Technical Support</span>
    <br />
    <span>Name Surname: John Doe</span>
    <br />
    <span>Telephone: 2106263647</span>
  </>
);

const OrganisationInfoEn = (props) => (
  <>
    <div className="inner section">
      <Heading
        text="eDiplomas Services for Organisations"
        id={`${props.idPrefix}organisations-clients`}
        level="3"
      />
      <p>
        The <a href="https://register.ediplomas.gr">eDiplomas Register</a>{' '}
        application is addressed to Organisations that wish to join the
        eDiplomas platform and access the services of the certifications of
        degrees through their own applications or through the representatives
        they have designated. Registered organisations in the eDiplomas Register
        platform can be legal entities from the private or public sector. Once
        an Organisation is registered, it can access the certifications of the
        Higher Education Institutions in the country that are connected to
        eDiplomas (
        <a href="https://ediplomas.gr/institutions">eDiplomas Institutions</a>).
      </p>
      <p>
        The access of an Organisation to a degree requires that the holder of
        the degree has previously selected the Organisation from the eDiplomas
        environment and has authorized access to her degrees. The access
        authorization is issued for a specific Organisation and is sent by the
        title holder to the Organisation via a simple link that any
        representative of the Organisation can use to obtain the digitally
        signed details of the titles.
      </p>
    </div>
    <div className="inner section">
      <Heading
        text="Organisation Registration for ediplomas.gr"
        id={`${props.idPrefix}registration-steps`}
        level="3"
      />
      <p>
        In short, the steps for an organisation to register in eDiplomas and
        activate its access to the services for organisations are the following:
      </p>
      <ol>
        <li>Request for initialization of a legal entity on eDiplomas</li>
        <li>
          Log in to eDiplomas Register by the Organisation's Administrator
        </li>
        <li>Invitation acceptance by the Organisation's representatives</li>
        <li>Confirmation of the Organisation's registration in eDiplomas</li>
      </ol>
      <p>The individual steps are described in more detail below.</p>
      <Heading
        text="Step 1. Request for initialization of a legal entity on eDiplomas"
        id={`${props.idPrefix}registration-step1`}
        level="5"
      />
      <p>
        The initial registration regards the TIN, the sector and the name of the
        legal entity, as well as its contact details. The registration is
        performed after sending the required data from a corporate email address
        of the entity to{' '}
        <a href="mailto:eDiplomas.registration@gunet.gr">
          eDiplomas.registration@gunet.gr
        </a>
        , according to the template below, and is followed by an update on the
        status of the request.
      </p>
      <h5>Request email template</h5>
      <MailExample
        mailto="eDiplomas.registration@gunet.gr"
        subject="eDiplomas Register: Request for initialization of a legal entity on eDiplomas"
        content={requestForRegistration}
      />
      <Heading
        text="Step 2. Log in to eDiplomas Register by the Organisation's Administrator"
        id={`${props.idPrefix}registration-step2`}
        level="5"
      />
      <p>
        The initialization of an Organisation in eDipomas is practically done by
        the Administrator of the Organisation loggin into{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a>. The
        Organisation's Administrator is the member or members of the
        Organisation who have access to the TAXISnet account of the
        Organisation. The role of the Administrator in the eDiplomas ecosystem
        is exclusively limited to the environment of{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a> and to
        the invitation of members of the Organisation who will have the role of
        the Organisation Representatives in the eDiplomas services. The
        registration process of the Representatives begins when the
        Administrator indicates the email addresses of the Representatives, so
        that an invitation can be sent to them from the{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a> platform.
        The Organisation's Administrator cannot have access to eDiplomas
        services for organisations (Degrees through Authorizations, Ex-Officio
        Degree Check) Access to those services is only given to the Organisation
        Representatives.
      </p>
      <Heading
        text="Step 3. Invitation acceptance by the Organisation's representatives"
        id={`${props.idPrefix}registration-step3`}
        level="5"
      />
      <p>
        Each invitation to a Representative contains a unique link to{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a> and
        regards the access to a specific service (i.e. Ex-officio degree check
        or access to degrees through authorization). When the recipients follow
        the link, they will have to log in with their personal TAXISnet account
        and then accept the role of Representative for their organisation. After
        accepting the role, the Representative's account is linked with the
        Organisation's account and access to the eDiplomas services for
        Organisations is activated. Through{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a> the
        Representatives can:
        <ul>
          <li>
            Be informed of all the eDiplomas roles assigned to them by their
            Organisation.
          </li>
          <li>
            Dissociate their TAXISnet account from eDiplomas and cancel their
            roles.
          </li>
        </ul>
      </p>
      <p>
        Each Representative may participate in one Organisation, but may have
        access to one or more eDiplomas services in that Organisation.
      </p>
      <Heading
        text="Step 4. Confirmation of the Organisation's registration in eDiplomas"
        id={`${props.idPrefix}registration-step4`}
        level="5"
      />
      <p>
        After the completion of the above steps, the cycle of connecting the
        Organisation to the eDiplomas platform is completed, so
        <ul>
          <li>
            The name of the Organisation appears in the list of{' '}
            <a href="https://ediplomas`.gr/graduate">eDiplomas Organisations</a>{' '}
            to which a degree holder may grant access to their titles.
          </li>
          <li>
            Access to{' '}
            <a href="https://ediplomas.gr/organisation/menu">
              eDiplomas services for Organisations
            </a>{' '}
            is activated.
          </li>
        </ul>
      </p>
      <p>
        Representatives can log in to eDiplomas through their personal TAXISnet
        account, in order to assume the role of their Organisation on the
        platform and perform tasks on its behalf.
      </p>
    </div>
    <div className="inner section">
      <Heading
        text="Organisation Registration for API Usage"
        id={`${props.idPrefix}registration-oauth2`}
        level="3"
      />
      <p>
        Organisations that have complete information systems and wish to connect
        their applications directly to eDiplomas using the relevant API, can
        register by sending a registration request to{' '}
        <a href="mailto:eDiplomas.registration@gunet.gr">
          eDiplomas.registration@gunet.gr
        </a>{' '}
        according to the template below.
      </p>
      <h5>Request email template</h5>
      <MailExample
        mailto="eDiplomas.registration@gunet.gr"
        subject="eDiplomas Register: Request for Organisation registration for API Usage"
        content={requestForOauth2Registration}
      />
    </div>
    <div className="inner section">
      <Heading
        text="Contact & Support"
        id={`${props.idPrefix}support`}
        level="3"
      />
      <p>
        General clarifications and support for the connection of Organisations
        to eDiplomas is provided by GUnet to the interested organisation, by
        sending from a corporate email address of the organisation the relevant
        query to <a href="mailto:info@gunet.gr">info@gunet.gr</a>, noting in the
        Subject of the email:
        <ul>
          <li>
            <strong>
              Organisation Registration [Organisation Name, Organisation TIN]
            </strong>{' '}
            when the question concerns the connection of a new Organisation.
          </li>
          <li>
            <strong>
              Organisation Support [Organisation Name, Organisation TIN]
            </strong>{' '}
            when the question concerns an already registered Organisation.
          </li>
        </ul>
      </p>
    </div>
  </>
);

export default OrganisationInfoEn;
