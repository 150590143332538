import React from 'react';
import DevelopersGuideEn from './en/DevelopersGuideEn';
import DevelopersGuideEl from './el/DevelopersGuideEl';

const DevelopersGuide = (props) => {
  if (props.polyglot.currentLocale === 'el') {
    return (
      <div id="start">
        <DevelopersGuideEl polyglot={props.polyglot} />
      </div>
    );
  }
  return (
    <div id="start">
      <DevelopersGuideEn polyglot={props.polyglot} />
    </div>
  );
};

export default DevelopersGuide;
