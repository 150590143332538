import React from 'react';
import CodeExample from '../../components/CodeExample';
import {
  OAuth2Endpoints,
  OAuth2Callback,
  DiplomaAccessHeader,
  DiplomaAccessResponseEn
} from '../APIExamples';
import { Heading } from '../Common';

const DevelopersGuideEn = () => (
  <div className="Developers-Guide">
    <h1 className="header">Developers</h1>
    <div className="section">
      <h2 className="header" id="dev_connection-oauth2-guide">
        Integration using oAuth2 Guide for third-party services
      </h2>
      <div className="inner section">
        <p>
          This page describes the connection of a third party application with
          the eDiplomas platoform. Applications that are connected with
          eDiplomas can, assuming they have the authorization required, access
          Degree information through an API.
        </p>
        <p>
          In the following sections describe the functionality of such apps,
          called <em>app-clients of eDiplomas</em>
        </p>
        <div className="noteblock">
          <strong>Note:</strong> Before connecting an app-client with eDiplomas,
          they have to be{' '}
          <a href="/documentation/organisations#org_org-with-app">registered</a>{' '}
          to the platform.
        </div>
      </div>
      <div className="inner section">
        <Heading text="Quick Start" id="dev_quick-start" level="3" />
        <div className="inner section">
          <Heading text="OAuth 2" id="dev_oauth2" level="4" />
          <p>
            In its core, eDiplomas consists of an OAuth2 system. The
            implementation and connection with an OAuth2 system details are
            described in{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749"
              target="_blank"
              rel="noopener noreferrer"
            >
              RFC 6749
            </a>
            . For the connection with eDiplomas' OAuth2 infrastructure, a OAuth2
            Client is required, which will be developed according to RFC 6749
            and will support the use of the Authorization Code Grant method as
            described in{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#inner section-4.1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Section 4.1.
            </a>{' '}
            An OAuth2 Client interacts with an Authorization Server (The
            user/graduate authorizes a client) and with a Resource Server (The
            client uses the graduate's/user's token, in order to gain access to
            information). The Authorization Service has an authorization
            endpoint and a user token obtaining endpoint. In eDiplomas, these
            endpoints are:
          </p>
          <CodeExample data={OAuth2Endpoints.results} indented />
          <p>
            The client, in its turn, during its registration, will have to
            register an endpoint to which the user will be redirected after the
            process in the Authorization Server is complete. The OAuth2 Client
            will send this part of information to the Authorization Server
            according to the specification in RFC 6749.
          </p>
          <CodeExample data={OAuth2Callback.results} indented />
          <p>
            For connecting to eDiplomas, the use of a developed OAuth2 Client
            which will be set according to the instructions above, is suggested.
            In case that is not sufficient, please refer to the section that
            contains the flow's description.
          </p>
        </div>
        <div className="inner section">
          <Heading text="Access to Degrees" id="dev_diploma-access" level="4" />
          <p>
            If the client has acquired the user's tokens through OAuth, it can
            now use them for gaining access to the user's degrees corresponding
            to these tokens. The OAuth2 Client has to make a{' '}
            <em className="highlight">GET</em> request to the{' '}
            <em className="highlight">Resource Server</em> in which the
            user's/graduate's, whose degree is being searched for, access token
            will be included. The <em className="highlight">access token</em>{' '}
            will be added to this request through the{' '}
            <em className="highlight">Authorization header</em> by using the{' '}
            <em className="highlight">Bearer token</em> method, according to the
            specification in{' '}
            <a
              href="https://tools.ietf.org/html/rfc6750"
              target="_blank"
              rel="noopener noreferrer"
            >
              RFC 6750
            </a>
            , in{' '}
            <a
              href="https://tools.ietf.org/html/rfc6750#inner section-2.1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Section 2.1. (Authorization Request Header Field)
            </a>
            . If the <em className="highlight">access token</em> is valid, the
            Resource Server will respond with the information that refers to the
            scopes of the specific <em className="highlight">access token</em>.
          </p>
          <CodeExample
            header="Authorization Header:"
            data={DiplomaAccessHeader.results}
            indented={false}
          />
          <CodeExample
            header="Response:"
            data={DiplomaAccessResponseEn.results}
            indented
          />
        </div>
      </div>
      <div className="inner section">
        <Heading
          text="Authorization for custom client"
          id="dev_authorization-flow"
          level="3"
        />
        <div className="inner section">
          <Heading
            text="Authorization Process"
            id="dev_authorization-process"
            level="4"
          />
          <p>
            The client's application will need to direct the user/graduate to
            the Authorization Server, where they will authorize access to
            information regarding their degrees. The successful authorization
            will redirect the user back to the callbackEndpoint which includes
            the OAuth2 code in its parameters. The authorization request is
            described in RFC 6749 in{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#inner section-4.1.1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Section 4.1.1
            </a>{' '}
            and the Authorization Server response in{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#section-4.1.2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Section 4.1.2
            </a>
            . This code has to be presented to the tokenEndpoint by the OAuth2
            Client in order for the user's token to be obtained. For the OAuth2
            Client interaction with the{' '}
            <em className="highlight">Authorization Server's</em>{' '}
            <em className="highlight">token endpoint</em>, providing the
            credentials through the use of HTTP Basic Authentication is
            required, as described by the{' '}
            <a
              href="https://tools.ietf.org/html/rfc7617"
              target="_blank"
              rel="noopener noreferrer"
            >
              RFC 7617
            </a>
            . The request for user's tokens by using the code is described in{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#section-4.1.3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Section 4.1.3
            </a>{' '}
            and the Authorization Server's response in{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#section-4.1.4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Section 4.1.4
            </a>
            .
          </p>
        </div>
        <div className="inner section">
          <Heading text="Token Renewal" id="dev_token-renewal" level="4" />
          <p>
            For the <em className="highlight">access tokens'</em> renewal, after
            their expiration, <em className="highlight">refresh tokens</em> are
            used. The OAuth2 Client needs to make a GET request to the{' '}
            <em className="highlight">Token Endpoint</em> with the parameters{' '}
            <em className="highlight">grant_type</em> with value "refresh_token"
            and <em className="highlight">refresh_token</em> containing the
            actual refresh token. Note: Like in every other request to the{' '}
            <em className="highlight">Token Endpoint</em>, HTTP Basic
            Authentication is required (ref. Authorization Process). The token
            renewal is described in RFC 6749,{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#inner section-6"
              target="_blank"
              rel="noopener noreferrer"
            >
              Section 6
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default DevelopersGuideEn;
