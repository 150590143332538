import React from 'react';
import OrganisationsGuideEn from './en/OrganisationsGuideEn';
import OrganisationsGuideEl from './el/OrganisationsGuideEl';

const OrganisationsGuide = (props) => {
  if (props.polyglot.currentLocale === 'el') {
    return (
      <div id="start">
        <OrganisationsGuideEl polyglot={props.polyglot} />
      </div>
    );
  }
  return (
    <div id="start">
      <OrganisationsGuideEn polyglot={props.polyglot} />
    </div>
  );
};

export default OrganisationsGuide;
