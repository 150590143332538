import React from 'react';
import { CopyToClipboardNative } from '../components/CopyToClipboardNative';

import CollapseDiploma from './CollapseDiploma';
import { formatDate } from '../lib/Util';
import { utcDatetime } from '../lib/Scopes';

export const TokenResult = (props) => {
  const {
    diploma,
    index,
    active,
    selectDiploma,
    selectedDiploma,
    selectedFilename,
    copied
  } = props;
  return (
    <div>
      <CollapseDiploma
        key={index}
        index={index}
        subheaders={[
          diploma.issuerInstitution,
          diploma.issuerUnit.toUpperCase()
        ]}
        headers={[
          props.polyglot.t(`educationalLevels.${diploma.level}`),
          diploma.title
        ]}
        selectDiploma={selectDiploma}
        active={active}
      >
        {selectedDiploma && (
          <CollapseDiploma.DataTable className="diploma-details">
            {diploma.gradeValue && (
              <CollapseDiploma.Pair
                field={props.polyglot.t('diplomaFields.gradeDescription')}
              >
                {new Number(diploma.gradeValue * 10).toFixed(2)}{' '}
                {diploma.gradeDescription &&
                  `(${diploma.gradeDescription.toUpperCase()})`}
              </CollapseDiploma.Pair>
            )}
            {diploma.dateIssued && diploma.validFrom && (
              <CollapseDiploma.Pair
                field={props.polyglot.t('diplomaFields.date')}
              >
                <div className="pair">
                  <span className="mini-header">
                    {props.polyglot.t('diplomaFields.dateIssued')}:
                  </span>
                  <span className="value">
                    {formatDate(diploma.dateIssued)}
                  </span>
                </div>
                <div className="pair">
                  <span className="mini-header">
                    {props.polyglot.t('diplomaFields.validFrom')}:
                  </span>
                  <span className="value">{formatDate(diploma.validFrom)}</span>
                </div>
              </CollapseDiploma.Pair>
            )}
            <CollapseDiploma.Pair
              field={props.polyglot.t('diplomaFields.issuerInstitution')}
            >
              {diploma.issuerInstitution}
            </CollapseDiploma.Pair>
            <CollapseDiploma.Pair
              field={props.polyglot.t('diplomaFields.issuerUnit')}
            >
              {diploma.issuerUnit}
            </CollapseDiploma.Pair>
            <CollapseDiploma.Pair
              field={props.polyglot.t('diplomaFields.ownerInfo')}
            >
              <div className="pair">
                <span className="mini-header">
                  {props.polyglot.t('diplomaFields.firstNameEl')}:
                </span>
                <span className="value">{diploma.firstNameEl}</span>
              </div>
              <div className="pair">
                <span className="mini-header">
                  {props.polyglot.t('diplomaFields.lastNameEl')}:
                </span>
                <span className="value">{diploma.lastNameEl}</span>
              </div>
              <div className="pair">
                <span className="mini-header">
                  {props.polyglot.t('diplomaFields.middleNameEl')}:
                </span>
                <span className="value">{diploma.middleNameEl}</span>
              </div>
              <div className="pair">
                <span className="mini-header">
                  {props.polyglot.t('diplomaFields.parentNames')}:
                </span>
                <span className="value">
                  {[diploma.fatherNameEl, diploma.motherNameEl]
                    .filter((i) => i)
                    .join(', ')}
                </span>
              </div>
            </CollapseDiploma.Pair>
            <CollapseDiploma.Pair
              field={props.polyglot.t('diplomaFields.signedFor')}
            >
              {diploma.clientDisplayName} ({diploma.clientID})
            </CollapseDiploma.Pair>
            <CollapseDiploma.Pair
              field={props.polyglot.t('diplomaFields.signedAt')}
            >
              {utcDatetime(diploma.timestamp)}
            </CollapseDiploma.Pair>
            <CollapseDiploma.Pair
              field={props.polyglot.t('diplomaFields.signature')}
            >
              <div className="ui action input signature">
                <input
                  className="signature-input"
                  type="text"
                  onFocus={props.handleFocusSignature}
                  ref={props.signatureInputRef}
                  value={selectedDiploma.signedAuditId}
                />
                <CopyToClipboardNative
                  text={selectedDiploma.signedAuditId}
                  onCopy={props.handleCopySignature}
                >
                  <button className="ui button">
                    <span className="not-mobile">
                      {props.polyglot.t('Organisation.copy')}
                    </span>
                  </button>
                </CopyToClipboardNative>
              </div>
              <div
                id="copiedNotification"
                className={`${copied === 'true' ? 'show disappeared' : ''}`}
              >
                {props.polyglot.t(`Organisation.tokenEntry.signatureCopied`)}
              </div>
            </CollapseDiploma.Pair>
          </CollapseDiploma.DataTable>
        )}
        <span className="actions">
          <form
            id="downloadPdf"
            method="GET"
            action={`/api/client/diploma_pdf/${selectedFilename}`}
            target="_blank"
          >
            <input
              type="text"
              name="diploma_index"
              value={diploma ? diploma.diplomaIndex : -1}
            />
            <input
              type="hidden"
              name="signature"
              value={selectedDiploma ? selectedDiploma.signedAuditId : ''}
            />
            <input
              type="text"
              name="language"
              value={props.polyglot.currentLocale}
            />
            <button className="ui fancy button">
              <span className="fas fa-file-download" />{' '}
              {props.polyglot.t('Organisation.pdfVersion')}
            </button>
          </form>
          <form
            id="downloadPrintable"
            method="GET"
            action={`/api/client/diploma_printable/${selectedFilename}`}
            /*target="_blank"*/
          >
            <input
              type="text"
              name="diploma_index"
              value={diploma ? diploma.diplomaIndex : -1}
            />
            <input
              type="hidden"
              name="signature"
              value={selectedDiploma ? selectedDiploma.signedAuditId : ''}
            />
            <input
              type="text"
              name="language"
              value={props.polyglot.currentLocale}
            />
            <button className="ui fancy button">
              <span className="fas fa-print" />{' '}
              {props.polyglot.t('Organisation.print')}
            </button>
          </form>
        </span>
      </CollapseDiploma>
    </div>
  );
};
