import React from 'react';
import { AuthConsumer } from './AuthContext';

const LoggedIn = (props) => (
  <AuthConsumer>
    {({ loggedIn }) => {
      if (loggedIn) {
        return props.children;
      }
      return null;
    }}
  </AuthConsumer>
);

export default LoggedIn;
