import React, { useState } from 'react';
import './News.css';
import ExternalRedirect from '../lib/ExternalRedirect';

const entries = [
  {
    id: 0,
    titleIcon: 'fas fa-video',
    titleText: 'eDiplomas for Graduates',
    thumbnail: '/presentationContent/thumbnail-graduates.png',
    video: {
      src: 'https://www.youtube.com/embed/WgG2QVGNpxQ'
    }
  },
  {
    id: 1,
    titleIcon: 'fas fa-video',
    titleText: 'eDiplomas for Verifiers',
    thumbnail: '/presentationContent/thumbnail-verifiers.png',
    video: {
      src: 'https://www.youtube.com/embed/5NAyxpR3SN4'
    }
  },
  {
    id: 2,
    titleIcon: 'fas fa-link',
    titleText: 'eDiplomas joins EMREX',
    thumbnail: '/presentationContent/thumbnail-emrex.png',
    link: {
      href: '/news/emrex'
    }
  }
];

const News = (props) => {
  const [active, setActive] = useState(0);

  const getActiveEntry = () => {
    for (const entry of entries) {
      if (entry.id === active) {
        return entry;
      }
    }
  };

  const renderMain = () => {
    const activeEntry = getActiveEntry();
    if (activeEntry.video) {
      return <VideoItem entry={activeEntry} />;
    } else if (activeEntry.text) {
      return <TextItem entry={activeEntry} />;
    } else if (activeEntry.link) {
      return <ExternalRedirect push={true} to={activeEntry.link.href} />;
    }
  };

  return (
    <React.Fragment>
      <h2 class="news">
        <span>{props.header}</span>
      </h2>
      <div id="NewsReel">
        <div className="Main">{renderMain()}</div>
        <div className="Side">
          <ul>
            {entries.map((entry) => (
              <li
                onClick={() => setActive(entry.id)}
                className={`${entry.id === active ? 'active' : ''}`}
                key={entry.id}
              >
                <img src={entry.thumbnail} />
                <span className={`${entry.titleIcon ? entry.titleIcon : ''}`} />
                <span>{entry.titleText}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

const VideoItem = (props) => (
  <iframe
    width="656"
    height="369"
    src={`${props.entry.video.src}?rel=0`}
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  ></iframe>
);

const TextItem = (props) => (
  <React.Fragment>
    <h2>{props.entry.titleText}</h2>
    <span className="TextEntry">{props.entry.text.content}</span>
    <br />
    <button className="ui fancy button">Read More</button>
  </React.Fragment>
);

export default News;
