const SingleAPIExample = {
  query: [{ ssn: '"31120012345"' }],
  results: [
    {
      certificateID: '"abc123"',
      titleID: '"123abc"',
      registrationID: '"587/1234abc"',
      systemID: '"123e4567-e89b-12d3-a456-426655440000"',
      gradeDescription: '"ΑΡΙΣΤΑ"',
      gradeValue: 0.811,
      dateIssued: '"20001231"',
      validFrom: '"20000931"',
      status: '"valid"',
      statusDate: '"20000931"',
      firstNameEl: '"ΧΡΗΣΤΟΣ"',
      firstNameEn: '"XRISTOS"',
      lastNameEl: '"ΔΟΚΙΜΑΣΤΙΚΟΣ"',
      lastNameEn: '"DOKIMASTIKOS"',
      middleNameEl: '"ΙΩΑΝΝΗΣ"',
      middleNameEn: '"IOANNIS"',
      fatherNameEl: '"ΑΛΕΞΑΝΔΡΟΣ"',
      fatherNameEn: '"ALEXANDROS"',
      motherNameEl: '"ΜΑΡΙΑ"',
      motherNameEn: '"MARIA"',
      birthDate: '"19750931"',
      birthYear: '"1975"',
      gender: '"1"',
      citizenship: '"GR"',
      SSN: '"31120012345"',
      SSNCOUNTRY: '"GR"',
      TIN: '"123456789"',
      TINCOUNTRY: '"GR"'
    }
  ]
};

const MultipleAPIExample = {
  query: [{ ssn: '"31120012345"' }],
  results: [
    {
      certificateID: '"abc123"',
      titleID: '"123abc"',
      registrationID: '"587/1234abc"',
      systemID: '"123e4567-e89b-12d3-a456-426655440000"',
      gradeDescription: '"ΑΡΙΣΤΑ"',
      gradeValue: 0.811,
      dateIssued: '"20001231"',
      validFrom: '"20000931"',
      status: '"valid"',
      statusDate: '"20000931"',
      firstNameEl: '"ΧΡΗΣΤΟΣ"',
      firstNameEn: '"XRISTOS"',
      lastNameEl: '"ΔΟΚΙΜΑΣΤΙΚΟΣ"',
      lastNameEn: '"DOKIMASTIKOS"',
      middleNameEl: '"ΙΩΑΝΝΗΣ"',
      middleNameEn: '"IOANNIS"',
      fatherNameEl: '"ΑΛΕΞΑΝΔΡΟΣ"',
      fatherNameEn: '"ALEXANDROS"',
      motherNameEl: '"ΜΑΡΙΑ"',
      motherNameEn: '"MARIA"',
      birthDate: '"19750931"',
      birthYear: '"1975"',
      gender: '"1"',
      citizenship: '"GR"',
      SSN: '"31120012345"',
      SSNCOUNTRY: '"GR"',
      TIN: '"123456789"',
      TINCOUNTRY: '"GR"'
    },
    {
      certificateID: '"abc123"',
      titleID: '"123xyz"',
      registrationID: '"587/1234xyz"',
      systemID: '"123e4567-e89b-12d3-b1252-426655440000"',
      gradeDescription: '"ΑΡΙΣΤΑ"',
      gradeValue: 0.89,
      dateIssued: '"20011229"',
      validFrom: '"20011229"',
      status: '"valid"',
      statusDate: '"20011229"',
      firstNameEl: '"ΧΡΗΣΤΟΣ"',
      firstNameEn: '"XRISTOS"',
      lastNameEl: '"ΔΟΚΙΜΑΣΤΙΚΟΣ"',
      lastNameEn: '"DOKIMASTIKOS"',
      middleNameEl: '"ΙΩΑΝΝΗΣ"',
      middleNameEn: '"IOANNIS"',
      fatherNameEl: '"ΑΛΕΞΑΝΔΡΟΣ"',
      fatherNameEn: '"ALEXANDROS"',
      motherNameEl: '"ΜΑΡΙΑ"',
      motherNameEn: '"MARIA"',
      birthDate: '"19750931"',
      birthYear: '"1975"',
      gender: '"1"',
      citizenship: '"GR"',
      SSN: '"31120012345"',
      SSNCOUNTRY: '"GR"',
      TIN: '"123456789"',
      TINCOUNTRY: '"GR"'
    }
  ]
};

const NoAPIExample = {
  query: [{ ssn: '"31120012345"' }],
  results: []
};

const InvalidReqExample = {
  results: { error: '"Could not parse request json."' }
};

const InvalidAuthTokenExample = {
  results: { error: '"Invalid authorization token."' }
};

const OAuth2Endpoints = {
  results: {
    authorizationEndpoint: '"https://authorization.ediplomas.gr/oauth"',
    tokenEndpoint: '"https://authorization.ediplomas.gr/oauth/token"',
    resourceServer: '"https://store.ediplomas.gr/data"'
  }
};

const OAuth2Callback = {
  results: { callbackEndpoint: '"https://myclient.example.org/oauth/callback"' }
};

const DiplomaAccessHeader = {
  results: { Authorization: '"Bearer 614ead11bb0faf8e"' }
};

const DiplomaAccessResponseEl = {
  results: {
    "data": [
      {
        "diploma": {
          "certificateID": "abc123",
          "firstNameEl": "ΧΡΗΣΤΟΣ",
          "firstNameEn": "CHRISTOS",
          "lastNameEl": "ΔΟΚΙΜΑΣΤΙΚΟΣ",
          "lastNameEn": "DOKIMASTIKOS",
          "middleNameEl": "ΙΩΑΝΝΗΣ",
          "middleNameEn": "IOANNIS",
          "fatherNameEl": "ΑΛΕΞΑΝΔΡΟΣ",
          "fatherNameEn": "ALEXANDROS",
          "motherNameEl": "ΜΑΡΙΑ",
          "motherNameEn": "MARIA",
          "gradeValue": 0.97,
          "gradeDescription": "ΑΡΙΣΤΑ",
          "dateIssued": "20001231",
          "validFrom": "20000930",
          "birthDate": "19750930",
          "templateID": "1",
          "title": "ΠΕΡΙ ΤΗΝ ΠΛΗΡΟΦΟΡΙΚΗΝ ΚΑΙ ΤΑΣ ΤΗΛΕΠΙΚΟΙΝΩΝΙΑΣ",
          "issuerInstitution": "Εθνικό και Καποδιστριακό Πανεπιστήμιο Αθηνών",
          "issuerUnit": "Τμήμα Πληροφορικής και Τηλεπικοινωνιών",
          "level": "6",
          "maintainerInstitution": "Εθνικό και Καποδιστριακό Πανεπιστήμιο Αθηνών",
          "maintainerInstitution_Code": "uoa",
          "maintainerUnit": "Τμήμα Πληροφορικής και Τηλεπικοινωνιών",
          "auditResultId": 65,
          "timestamp": 1679904274,
          "clientID": "myclient",
          "clientDisplayName": "myclient"
        },
        "signature": "f20arciABn00OIr/op+BWtE0ITs6qyqmiYDOAoNk+Iofl7N8cLqelMioYn5CtJBdpQYYhDCEarFwTv1TKHcdCA==",
        "payload": "{\"certificateID\":\"abc123\",\"firstNameEl\":\"ΧΡΗΣΤΟΣ\",\"firstNameEn\":\"CHRISTOS\",\"lastNameEl\":\"ΔΟΚΙΜΑΣΤΙΚΟΣ\",\"lastNameEn\":\"DOKIMASTIKOS\",\"middleNameEl\":\"ΙΩΑΝΝΗΣ\",\"middleNameEn\":\"IOANNIS\",\"fatherNameEl\":\"ΑΛΕΞΑΝΔΡΟΣ\",\"fatherNameEn\":\"ALEXANDROS\",\"motherNameEl\":\"ΜΑΡΙΑ\",\"motherNameEn\":\"MARIA\",\"gradeValue\":0.97,\"gradeDescription\":\"ΑΡΙΣΤΑ\",\"dateIssued\":\"20001231\",\"validFrom\":\"20000930\",\"birthDate\":\"19750930\",\"templateID\":\"1\",\"title\":\"ΠΕΡΙ ΤΗΝ ΠΛΗΡΟΦΟΡΙΚΗΝ ΚΑΙ ΤΑΣ ΤΗΛΕΠΙΚΟΙΝΩΝΙΑΣ\",\"issuerInstitution\":\"Εθνικό και Καποδιστριακό Πανεπιστήμιο Αθηνών\",\"issuerUnit\":\"Τμήμα Πληροφορικής και Τηλεπικοινωνιών\",\"level\":\"6\",\"maintainerInstitution\":\"Εθνικό και Καποδιστριακό Πανεπιστήμιο Αθηνών\",\"maintainerInstitution_Code\":\"uoa\",\"maintainerUnit\":\"Τμήμα Πληροφορικής και Τηλεπικοινωνιών\",\"auditResultId\":65,\"timestamp\":1679904274,\"clientID\":\"myclient\",\"clientDisplayName\":\"My Client\"}",
        "auditId": "65",
        "aud": "myclient",
        "signedAuditId": "SOrZbnLv0rLf7KmCfdPyJ6RfK8aC59dTWtbJfjCl53zpci+d0lgK3YxeX4t9naNeqT5ZddN1Z080K5ncSXeWDA==",
        "signedAuditVersion": "1"
      }
    ],
    "status": [
      {
        "auth": "1200"
      },
      {
        "uniwa": "1200"
      },
      {
        "uoa": "1200"
      }
    ],
    "metadata": {
      "scopes": [
        "title",
        "issuer",
        "level",
        "grade",
        "dates",
        "identifiers"
      ],
      "sub": "08109500986"
    }
  }
};

const DiplomaAccessResponseEn = {
  results: {
    "data": [
      {
        "diploma": {
          "certificateID": "abc123",
          "firstNameEl": "ΧΡΗΣΤΟΣ",
          "firstNameEn": "CHRISTOS",
          "lastNameEl": "ΔΟΚΙΜΑΣΤΙΚΟΣ",
          "lastNameEn": "DOKIMASTIKOS",
          "middleNameEl": "ΙΩΑΝΝΗΣ",
          "middleNameEn": "IOANNIS",
          "fatherNameEl": "ΑΛΕΞΑΝΔΡΟΣ",
          "fatherNameEn": "ALEXANDROS",
          "motherNameEl": "ΜΑΡΙΑ",
          "motherNameEn": "MARIA",
          "gradeValue": 0.97,
          "gradeDescription": "ΑΡΙΣΤΑ",
          "dateIssued": "20001231",
          "validFrom": "20000930",
          "birthDate": "19750930",
          "templateID": "1",
          "title": "ΠΕΡΙ ΤΗΝ ΠΛΗΡΟΦΟΡΙΚΗΝ ΚΑΙ ΤΑΣ ΤΗΛΕΠΙΚΟΙΝΩΝΙΑΣ",
          "issuerInstitution": "Εθνικό και Καποδιστριακό Πανεπιστήμιο Αθηνών",
          "issuerUnit": "Τμήμα Πληροφορικής και Τηλεπικοινωνιών",
          "level": "6",
          "maintainerInstitution": "Εθνικό και Καποδιστριακό Πανεπιστήμιο Αθηνών",
          "maintainerInstitution_Code": "uoa",
          "maintainerUnit": "Τμήμα Πληροφορικής και Τηλεπικοινωνιών",
          "auditResultId": 65,
          "timestamp": 1679904274,
          "clientID": "myclient",
          "clientDisplayName": "myclient"
        },
        "signature": "f20arciABn00OIr/op+BWtE0ITs6qyqmiYDOAoNk+Iofl7N8cLqelMioYn5CtJBdpQYYhDCEarFwTv1TKHcdCA==",
        "payload": "{\"certificateID\":\"abc123\",\"firstNameEl\":\"ΧΡΗΣΤΟΣ\",\"firstNameEn\":\"CHRISTOS\",\"lastNameEl\":\"ΔΟΚΙΜΑΣΤΙΚΟΣ\",\"lastNameEn\":\"DOKIMASTIKOS\",\"middleNameEl\":\"ΙΩΑΝΝΗΣ\",\"middleNameEn\":\"IOANNIS\",\"fatherNameEl\":\"ΑΛΕΞΑΝΔΡΟΣ\",\"fatherNameEn\":\"ALEXANDROS\",\"motherNameEl\":\"ΜΑΡΙΑ\",\"motherNameEn\":\"MARIA\",\"gradeValue\":0.97,\"gradeDescription\":\"ΑΡΙΣΤΑ\",\"dateIssued\":\"20001231\",\"validFrom\":\"20000930\",\"birthDate\":\"19750930\",\"templateID\":\"1\",\"title\":\"ΠΕΡΙ ΤΗΝ ΠΛΗΡΟΦΟΡΙΚΗΝ ΚΑΙ ΤΑΣ ΤΗΛΕΠΙΚΟΙΝΩΝΙΑΣ\",\"issuerInstitution\":\"Εθνικό και Καποδιστριακό Πανεπιστήμιο Αθηνών\",\"issuerUnit\":\"Τμήμα Πληροφορικής και Τηλεπικοινωνιών\",\"level\":\"6\",\"maintainerInstitution\":\"Εθνικό και Καποδιστριακό Πανεπιστήμιο Αθηνών\",\"maintainerInstitution_Code\":\"uoa\",\"maintainerUnit\":\"Τμήμα Πληροφορικής και Τηλεπικοινωνιών\",\"auditResultId\":65,\"timestamp\":1679904274,\"clientID\":\"myclient\",\"clientDisplayName\":\"My Client\"}",
        "auditId": "65",
        "aud": "myclient",
        "signedAuditId": "SOrZbnLv0rLf7KmCfdPyJ6RfK8aC59dTWtbJfjCl53zpci+d0lgK3YxeX4t9naNeqT5ZddN1Z080K5ncSXeWDA==",
        "signedAuditVersion": "1"
      }
    ],
    "status": [
      {
        "auth": "1200"
      },
      {
        "uniwa": "1200"
      },
      {
        "uoa": "1200"
      }
    ],
    "metadata": {
      "scopes": [
        "title",
        "issuer",
        "level",
        "grade",
        "dates",
        "identifiers"
      ],
      "sub": "08109500986"
    }
  }
};

export {
  SingleAPIExample,
  MultipleAPIExample,
  InvalidReqExample,
  InvalidAuthTokenExample,
  OAuth2Endpoints,
  OAuth2Callback,
  DiplomaAccessHeader,
  DiplomaAccessResponseEl,
  DiplomaAccessResponseEn,
  NoAPIExample
};
