import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ExternalRedirect from '../lib/ExternalRedirect';
import ClientSelect from './ClientSelect';
import CompletedProcess from './CompletedProcess';
import Terms from './Terms';
import Footer from '../Footer';
import { scrollTop } from '../lib/Util';
import MenuGraduate from './MenuGraduate';
import { Steps } from '../components/Steps';
import './Graduate.css';

class Graduate extends Component {
  componentDidMount = () => {
    scrollTop();
  };

  getClientId = () => sessionStorage.getItem('clientId');

  getSteps = () => {
    const t = (text) => this.props.polyglot.t(text);
    return [
      t('Graduate.step1.text'),
      t('Graduate.step2.text'),
      t('Graduate.step3.text')
    ];
  };

  render = () => (
    <div className="Graduate Page">
      <div className="container">
        <div className="content">
          <h2 className="container-header">
            <i className="fas fa-graduation-cap" />{' '}
            {this.props.polyglot.t('Graduate.graduate')}
          </h2>
          <Switch>
            <Route
              path="/graduate/menu"
              render={() => <MenuGraduate polyglot={this.props.polyglot} />}
            />
            <Route
              path="/graduate/2"
              render={() => {
                if (!this.getClientId()) {
                  return <Redirect to="/graduate" />;
                }
                return (
                  <>
                    <div className="steps-wrapper">
                      <Steps steps={this.getSteps()} active={2} />
                    </div>
                    <Terms polyglot={this.props.polyglot} />
                  </>
                );
              }}
            />
            <Route
              path="/graduate/3"
              render={() => {
                if (!this.getClientId()) {
                  return <Redirect to="/graduate" />;
                }
                return (
                  <ExternalRedirect
                    push={false}
                    to={`/api/user/oauth/${this.getClientId()}`}
                    language={this.props.polyglot.currentLocale}
                  />
                );
              }}
            />
            <Route
              path="/graduate/complete"
              render={(props) => (
                <>
                  <div className="steps-wrapper">
                    <Steps steps={this.getSteps()} active={3} />
                  </div>
                  <CompletedProcess
                    location={props.location}
                    polyglot={this.props.polyglot}
                  />
                </>
              )}
            />
            <Route
              path="/graduate"
              render={() => (
                <>
                  <div className="steps-wrapper">
                    <Steps steps={this.getSteps()} active={1} />
                  </div>
                  <ClientSelect polyglot={this.props.polyglot} />
                </>
              )}
            />
          </Switch>
        </div>
      </div>
      <Footer polyglot={this.props.polyglot} />
    </div>
  );
}

export default Graduate;
