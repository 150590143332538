import React from 'react';

const CodeBlock = (props) => (
  <span className={`value ${props.indented ? 'indented' : ''}`}>
    {props.name && (
      <>
        <span className="word">"{props.name}"</span>:&nbsp;
      </>
    )}
    {(typeof props.value === 'number' || typeof props.value === 'string') && (
      <span className="word">{props.value}</span>
    )}
    {!Array.isArray(props.value) && typeof props.value === 'object' && (
      <>
        <span className="brace">{'{'}</span>
        {props.indented && <br />}
        {Object.keys(props.value).map((key, index) => (
          <CodeBlock
            key={key}
            name={key}
            value={props.value[key]}
            indented={props.indented}
            lastValue={index === Object.keys(props.value).length - 1}
          />
        ))}
        <span className="brace">{'}'}</span>
      </>
    )}
    {Array.isArray(props.value) && (
      <>
        <span className="brace">[</span>
        {props.value.map((value, index) => (
          <CodeBlock
            key={index}
            value={value}
            indented={props.indented}
            lastValue={index === props.value.length - 1}
          />
        ))}
        <span className="brace">]</span>
      </>
    )}
    {!props.lastValue && ', '}
    {props.indented && <br />}
  </span>
);

const CodeExample = (props) => (
  <div className="example code">
    {props.header}&nbsp;
    {props.indented && <br />}
    <CodeBlock value={props.data} indented={props.indented} lastValue />
  </div>
);

export default CodeExample;
