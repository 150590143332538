import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './MenuLink.css';

class MenuLink extends Component {
  state = {
    show: false
  };

  UNSAFE_componentWillMount = () => {
    let seen = sessionStorage.getItem('menulink-seen');
    seen = seen || 1;
    if (seen <= 2) {
      sessionStorage.setItem('menulink-seen', seen + 1);
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
    document.addEventListener('mousedown', this.handleClick, false);
  };

  componentWillUnmount = () =>
    document.removeEventListener('mousedown', this.handleClick, false);

  handleClick = () => this.setState({ show: false });

  render = () => (
    <div
      onMouseMoveCapture={() => this.setState({ show: false })}
      className={`link-menu ${this.state.show ? 'show-popup' : ''}`}
      data-tooltip={this.props.polyglot.t(`Organisation.navigateToMenu`)}
    >
      <Link to="/organisation/menu">
        <span className="far fa-compass" />
      </Link>
    </div>
  );
}

export default MenuLink;
