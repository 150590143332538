import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './LogoutPage.css';

class LogoutPage extends Component {
  state = {
    error: false,
    renderBody: false,
    loggedOutTaxis: false
  };

  UNSAFE_componentWillMount = () => {
    if (this.props.location.search === '?taxis') {
      this.setState({ loggedOutTaxis: true });
    } else {
      this.setState({ loggedOutTaxis: false });
    }
  };

  render = () => (
    <>
      <div className="Logout">
        <div className="step-description">
          <h2>{this.props.polyglot.t(`Organisation.logout.logout`)}</h2>
          <p>{this.props.polyglot.t(`Organisation.logout.haveLoggedOut`)}</p>
          <hr />
          <Link to="/" className="back-link">
            <span className="fas fa-arrow-left" />{' '}
            {this.props.polyglot.t('Organisation.logout.backHome')}
          </Link>
        </div>
      </div>
    </>
  );
}

export default LogoutPage;
