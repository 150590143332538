const specialChars = {};
specialChars['ά'] = 'α';
specialChars['ό'] = 'ο';
specialChars['έ'] = 'ε';
specialChars['ύ'] = 'υ';
specialChars['ϋ'] = 'υ';
specialChars['ΰ'] = 'υ';
specialChars['ή'] = 'η';
specialChars['ί'] = 'ι';
specialChars['ϊ'] = 'ι';
specialChars['ΐ'] = 'ι';
specialChars['ώ'] = 'ω';
specialChars['ς'] = 'σ';

const greekToLatin = {};
greekToLatin['α'] = 'a';
greekToLatin['ά'] = 'a';
greekToLatin['β'] = 'v';
greekToLatin['γ'] = 'g';
greekToLatin['δ'] = 'd';
greekToLatin['ε'] = 'e';
greekToLatin['έ'] = 'e';
greekToLatin['ζ'] = 'z';
greekToLatin['η'] = 'h';
greekToLatin['ή'] = 'h';
greekToLatin['θ'] = 'th';
greekToLatin['ι'] = 'i';
greekToLatin['ί'] = 'i';
greekToLatin['ϊ'] = 'i';
greekToLatin['ΐ'] = 'i';
greekToLatin['κ'] = 'k';
greekToLatin['λ'] = 'l';
greekToLatin['μ'] = 'm';
greekToLatin['ν'] = 'n';
greekToLatin['ξ'] = 'x';
greekToLatin['ο'] = 'o';
greekToLatin['ό'] = 'o';
greekToLatin['π'] = 'p';
greekToLatin['ρ'] = 'r';
greekToLatin['σ'] = 's';
greekToLatin['τ'] = 't';
greekToLatin['υ'] = 'u';
greekToLatin['ύ'] = 'u';
greekToLatin['ΰ'] = 'u';
greekToLatin['φ'] = 'f';
greekToLatin['χ'] = 'ch';
greekToLatin['ψ'] = 'ps';
greekToLatin['ω'] = 'o';
greekToLatin['ώ'] = 'o';
greekToLatin['ς'] = 's';

function normalizeString(input) {
  let normalized = input.toLowerCase();
  for (const [key, value] of Object.entries(specialChars)) {
    normalized = normalized.replace(new RegExp(key, 'g'), value);
  }
  return normalized;
}

function convertToLatin(input) {
  if (!input) {
    return '';
  }
  let str = normalizeString(input);
  for (const [key, value] of Object.entries(greekToLatin)) {
    str = str.replace(new RegExp(key, 'g'), value);
  }
  return str;
}

export { normalizeString, convertToLatin };
