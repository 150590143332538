import React, { Component } from 'react';
import Footer from './Footer';
import { Spinner } from './components/Spinner';
import { Collapse } from './components/Collapse';
import { scrollTop } from './lib/Util';
import './AdoptionRates.css';

class AdoptionRates extends Component {
  state = {
    institutions: [],
    loading: 0,
    resultsReady: false,
    collapseOpen: []
  };

  fetchInfo = async (institution) => {
    let url = '/api/client/stats/';
    if (this.props.betaAdopters) {
      url = '/api-test/client/stats/';
    }
    this.setState((prevState) => ({
      ...prevState,
      loading: prevState.loading + 1
    }));
    try {
      let diplomasInfo = await fetch(url + institution);
      diplomasInfo = await diplomasInfo.json();
      diplomasInfo = diplomasInfo.diplomasInfo[0];
      let authInfo = this.state.institutions.filter(
        (i) => i.name === institution
      )[0];
      authInfo = {
        ...authInfo,
        numberOfDiplomas: {
          undergraduate: diplomasInfo
            ? diplomasInfo.numberOfUndergraduateDegrees
            : '-',
          postgraduate: diplomasInfo ? diplomasInfo.numberOfMasterDegrees : '-',
          doctorate: diplomasInfo ? diplomasInfo.numberOfPhd : '-'
        },
        contactInfo: {
          email: diplomasInfo ? diplomasInfo.email : '-',
          telephone: diplomasInfo ? diplomasInfo.phone : '-',
          website: diplomasInfo ? diplomasInfo.url : '-'
        },
        comments: diplomasInfo ? diplomasInfo.comments : '-'
      };
      let institutions = this.state.institutions;
      for (let i = 0; i < institutions.length; i++) {
        if (institutions[i].name === institution) {
          institutions[i] = authInfo;
        }
      }
      institutions.sort((a, b) => a.name > b.name);
      this.setState({ institutions }, scrollTop);
      this.setState({ collapseOpen: institutions.map(() => false) });
      this.setState((prevState) => ({
        ...prevState,
        loading: prevState.loading - 1,
        resultsReady: true
      }));
    } catch (e) {
      this.setState((prevState) => ({
        ...prevState,
        loading: prevState.loading - 1,
        resultsReady: true
      }));
    }
  };

  handleOpen = (collapseId) => {
    this.setState((prevState) => {
      const newCollapseState = prevState.institutions.map(() => false);
      if (collapseId >= 0 && collapseId < newCollapseState.length) {
        newCollapseState[collapseId] = !prevState.collapseOpen[collapseId];
        return { ...prevState, collapseOpen: newCollapseState };
      } else {
        return prevState;
      }
    });
    console.log(this.state);
  };

  handleLanguage = (lang) => {
    this.props.handleLanguage(lang);
  };

  fetchInstitutionsData = async () => {
    let url = 'api/client/institutions_metadata';
    if (this.props.betaAdopters) {
      url = 'api-test/client/institutions_metadata';
    }
    const res = await fetch(url);
    const resJson = await res.json();
    let metadata = resJson.metadata;
    this.setState({ institutions: metadata }, async () => {
      this.state.institutions.map((i) => this.fetchInfo(i.name));
    });
  };

  componentDidMount = async () => {
    scrollTop();
    await this.fetchInstitutionsData();
  };

  render() {
    return (
      <React.Fragment>
        <div className="AdoptionRates">
          <div className="content">
            <h1 className="center header">
              {this.props.polyglot.t(`AdoptionRates.participatingInstitutions`)}
              {this.props.betaAdopters && (
                <span>
                  {' '}
                  ({this.props.polyglot.t('AdoptionRates.betaAdopters')})
                </span>
              )}
            </h1>
            <div className="mobile">
              {this.state.loading > 0 && <Spinner cx={80} cy={80} r={38} />}
              {this.state.loading === 0 && this.state.resultsReady && (
                <div>
                  {this.state.institutions.map((institution, index) => (
                    <Collapse
                      polyglot={this.props.polyglot}
                      key={institution.name}
                      handleClick={() => this.handleOpen(index)}
                      open={this.state.collapseOpen[index]}
                      logo={institution.logo}
                      title={institution.text.name_el}
                      bachelors={institution.numberOfDiplomas.undergraduate}
                      masters={institution.numberOfDiplomas.postgraduate}
                      phds={institution.numberOfDiplomas.doctorate}
                      web={institution.contactInfo.website}
                      phone={institution.contactInfo.telephone}
                      email={institution.contactInfo.email}
                      comments={institution.comments}
                      signedPdf={institution.signedPDF}
                      support={institution.support}
                    />
                  ))}
                </div>
              )}
            </div>
            <table className="table compact outer">
              <thead>
                <tr className="header">
                  <th>{}</th>
                  <th>
                    <span className="fas fa-university"></span>{' '}
                    {this.props.polyglot.t(
                      `AdoptionRates.participatingInstitution`
                    )}
                  </th>
                  <th className="wider">
                    <span className="fas fa-graduation-cap"></span>{' '}
                    {this.props.polyglot.t(`AdoptionRates.registeredDiplomas`)}
                  </th>
                  <th className="wide">
                    <span className="fas fa-file-signature"></span>{' '}
                    {this.props.polyglot.t(`AdoptionRates.signedPdf`)}
                  </th>
                  <th>
                    <span className="fas fa-plus"></span>{' '}
                    {this.props.polyglot.t(`AdoptionRates.comments`)}
                  </th>
                  <th>
                    <span className="fas fa-ticket-alt"></span>{' '}
                    {this.props.polyglot.t(`AdoptionRates.requestServing`)}
                  </th>
                  <th>
                    <span className="fas fa-envelope"></span>{' '}
                    {this.props.polyglot.t(`AdoptionRates.contact`)}
                  </th>
                </tr>
              </thead>
              {this.state.loading > 0 && (
                <tbody>
                  <tr>
                    <td colSpan="6" className="spinner">
                      <Spinner cx={80} cy={80} r={38} />
                    </td>
                  </tr>
                </tbody>
              )}
              {this.state.loading === 0 && this.state.resultsReady && (
                <tbody>
                  {this.state.institutions.map((institution) => (
                    <tr key={institution.name}>
                      <td>
                        <div className="institutionLogoContainer">
                          <img
                            className="institutionLogo"
                            src={institution.logo}
                            alt="institution-logo"
                          />
                        </div>
                      </td>
                      <td className="strong name">
                        {institution.text.name_el}
                      </td>
                      <td className="wider">
                        <div>
                          <span className="mobileHeader">
                            {this.props.polyglot.t(
                              `AdoptionRates.registeredDiplomas`
                            )}
                            :{' '}
                          </span>
                          <table className="table compact inner">
                            <tbody>
                              <tr>
                                <td className="strong">
                                  {this.props.polyglot.t(
                                    `AdoptionRates.diplomas.undergraduate`
                                  )}
                                </td>
                                <td>
                                  {institution.numberOfDiplomas.undergraduate}
                                </td>
                              </tr>
                              <tr>
                                <td className="strong">
                                  {this.props.polyglot.t(
                                    `AdoptionRates.diplomas.postgraduate`
                                  )}
                                </td>
                                <td>
                                  {institution.numberOfDiplomas.postgraduate}
                                </td>
                              </tr>
                              <tr>
                                <td className="strong">
                                  {this.props.polyglot.t(
                                    `AdoptionRates.diplomas.doctorate`
                                  )}
                                </td>
                                <td>
                                  {institution.numberOfDiplomas.doctorate}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                      <td className="tiny-content">
                        <div className="signedPDF">
                          <span className="mobileHeader">
                            {' '}
                            {this.props.polyglot.t(`AdoptionRates.signedPdf`)}:
                          </span>{' '}
                          <span className="content">
                            {' '}
                            {institution.signedPDF
                              ? this.props.polyglot.t(`AdoptionRates.yes`)
                              : this.props.polyglot.t(`AdoptionRates.no`)}
                          </span>
                        </div>
                      </td>
                      <td className="tiny-content">
                        <div className="comments">
                          <span className="mobileHeader">
                            {' '}
                            {this.props.polyglot.t(`AdoptionRates.comments`)}:
                          </span>{' '}
                          <span className="content">
                            {' '}
                            {institution.comments}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className="mobileHeader">
                          {' '}
                          {this.props.polyglot.t(
                            `AdoptionRates.requestServing`
                          )}
                          :
                        </span>
                        <table className="table compact inner">
                          <tbody>
                            <tr>
                              <td className="strong">
                                {this.props.polyglot.t(
                                  `AdoptionRates.diplomas.undergraduate`
                                )}
                              </td>
                              <td>
                                {institution.support.bachelor
                                  ? this.props.polyglot.t(`AdoptionRates.yes`)
                                  : this.props.polyglot.t(`AdoptionRates.no`)}
                              </td>
                            </tr>
                            <tr>
                              <td className="strong">
                                {this.props.polyglot.t(
                                  `AdoptionRates.diplomas.postgraduate`
                                )}
                              </td>
                              <td>
                                {institution.support.master
                                  ? this.props.polyglot.t(`AdoptionRates.yes`)
                                  : this.props.polyglot.t(`AdoptionRates.no`)}
                              </td>
                            </tr>
                            <tr>
                              <td className="strong">
                                {this.props.polyglot.t(
                                  `AdoptionRates.diplomas.doctorate`
                                )}
                              </td>
                              <td>
                                {institution.support.doctorate
                                  ? this.props.polyglot.t(`AdoptionRates.yes`)
                                  : this.props.polyglot.t(`AdoptionRates.no`)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <div>
                          <span className="mobileHeader">
                            {this.props.polyglot.t(`AdoptionRates.contact`)}:
                          </span>
                          <table className="table compact inner">
                            <tbody>
                              <tr>
                                <td className="strong">
                                  {this.props.polyglot.t(
                                    `AdoptionRates.contactInfo.web`
                                  )}
                                </td>
                                <td>
                                  <a
                                    href={`http://${institution.contactInfo.website}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {institution.contactInfo.website}
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="strong">
                                  {this.props.polyglot.t(
                                    `AdoptionRates.contactInfo.telephone`
                                  )}
                                </td>
                                <td>{institution.contactInfo.telephone}</td>
                              </tr>
                              <tr>
                                <td className="strong">
                                  {this.props.polyglot.t(
                                    `AdoptionRates.contactInfo.email`
                                  )}
                                </td>
                                <td>{institution.contactInfo.email}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {!this.props.betaAdopters && (
              <span id="beta-adopters">
                <a href="/beta-institutions">
                  {this.props.polyglot.t('AdoptionRates.betaAdoptersLink')}{' '}
                  <span className="fas fa-arrow-right"></span>
                </a>
              </span>
            )}
          </div>
        </div>
        <Footer polyglot={this.props.polyglot} />
      </React.Fragment>
    );
  }
}

export default AdoptionRates;
