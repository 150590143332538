import React from 'react';
import Footer from './Footer';
import ErrorSvg from './ErrorSvg';
import './GeneralError.css';
import './App.css';

const GeneralError = (props) => (
  <div className="GeneralError">
    <div className="error-body">
      <div className="error-inner">
        <div className="error-message">
          <div className="error-header">
            {props.polyglot.t(`GeneralError.oops`)}!
          </div>
          <div>{props.polyglot.t(`GeneralError.somethingWentWrong`)}.</div>
          <div id="tryLater">{props.polyglot.t(`GeneralError.tryLater`)}</div>
          <a href="/">
            <span className="fa fa-arrow-left" />{' '}
            {props.polyglot.t(`GeneralError.goBackHome`)}
          </a>
        </div>
        <div className="error-icon">
          <ErrorSvg />
        </div>
      </div>
    </div>
    <Footer polyglot={props.polyglot} />
  </div>
);

export default GeneralError;
