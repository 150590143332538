import React from 'react';

/* Props:
 * - id
 * - text
 * - level (3, 4 or 5 for h3, h4 or h5 respectively)
 */

const Heading = (props) => {
  let h;
  if (props.level == 3) {
    h = (
      <h3 className="subheader nav-anchor" id={props.id}>
        {props.text}
      </h3>
    );
  } else if (props.level == 4) {
    h = (
      <h4 className="subheader nav-anchor" id={props.id}>
        {props.text}
      </h4>
    );
  } else {
    h = (
      <h5 className="subheader" id={props.id}>
        {props.text}
      </h5>
    );
  }

  return (
    <div className="header-wrapper">
      {h}
      <a href={`#${props.id}`} data-tooltip="Μόνιμος Σύνδεσμος">
        <span className="fas fa-link" />
      </a>
    </div>
  );
};

export { Heading };
