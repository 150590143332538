import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import './Terms.css';

class Terms extends Component {
  state = {
    next: false
  };

  onNext = () => {
    this.setState({
      next: true
    });
  };

  render = () => (
    <div className="Terms">
      <div className="container-content">
        <div className="step-description">
          <h2>{this.props.polyglot.t('Graduate.title2')}</h2>
          <p>{this.props.polyglot.t('Graduate.paragraph2')}</p>
        </div>
        <div className="buttons">
          <Link to="/graduate" className="back-link">
            <span className="fas fa-arrow-left" />{' '}
            {this.props.polyglot.t('Graduate.back')}
          </Link>
          <button onClick={this.onNext} className="ui fancy button">
            {this.props.polyglot.t('Graduate.continue')}{' '}
            <span className="fas fa-arrow-right" />
          </button>
        </div>
        {this.state.next && <Redirect push={false} to="/graduate/3" />}
      </div>
    </div>
  );
}

export default Terms;
