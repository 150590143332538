import React from 'react';
import './News.css';

import EmrexArticleEn from './EmrexArticleEn';
import EmrexArticleEl from './EmrexArticleEl';

const EmrexArticle = (props) => {
  if (props.polyglot.currentLocale === 'en') {
    return (
      <EmrexArticleEn
        polyglot={props.polyglot}
        handleLanguage={props.handleLanguage}
      />
    );
  } else {
    return (
      <EmrexArticleEl
        polyglot={props.polyglot}
        handleLanguage={props.handleLanguage}
      />
    );
  }
};

export default EmrexArticle;
