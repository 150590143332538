import React, { Component } from 'react';
import { CopyToClipboardNative } from '../components/CopyToClipboardNative';
import { scrollTop } from '../lib/Util';
import moment from 'moment';
import './CompletedProcess.css';
import { Spinner } from '../components/Spinner';
import MailTemplate from '../components/MailTemplate';
import { Redirect } from 'react-router-dom';

class CompletedProcess extends Component {
  state = {
    token: '',
    authLink: '',
    expiresAt: -1,
    copied: 'initial',
    contactEmail: '',
    displayName: '',
    clientId: '',
    redirectError: false
  };

  constructor(props) {
    super(props);
    this.linkRef = React.createRef();
  }

  constructEmail = () => {
    return (
      <>
        <br />
        <span>Προς: {this.state.displayName}</span>
        <br />
        <span>Σύνδεσμος Εξουσιοδότησης: {this.state.authLink}</span>
        <br />
        <span>
          Εξουσιοδότηση ενεργή μέχρι: {this.showDate(this.state.expiresAt)}
        </span>
        <br />
        <br />
        <span>
          ------------------------------------------------------------------------------
        </span>
        <br />
        <br />
        <span>[Προσθέστε επιπλέον πληροφορίες μόνο κάτω από τη γραμμή]</span>
        <br />
      </>
    );
  };

  showDate = (timestamp) => {
    const format = 'DD/MM/YYYY HH:mm:ss';
    return moment(new Date(timestamp * 1000)).format(format);
  };

  fetchToken = async () => {
    const res = await fetch(`/api/user/token`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
      },
      method: 'GET',
      credentials: 'include'
    });

    const resJson = await res.json();
    if (resJson.token) {
      this.setState({
        token: resJson.token,
        authLink: `${window.location.protocol}//${window.location.host}/o/${resJson.token}`,
        expiresAt: resJson.expires_at,
        contactEmail: resJson.email,
        displayName: resJson.display_name
      });
    } else {
      this.setState({ redirectError: true });
    }
  };

  copyToken = () => {
    this.setState({ copied: 'true' });
    this.copyTimeout = setTimeout(() => {
      this.setState({ copied: 'false' });
    }, 1800);
  };

  UNSAFE_componentWillMount() {
    this.fetchToken();
  }

  componentDidMount() {
    scrollTop();
  }

  handleAuthLinkClick = (e) => {
    e.target.setSelectionRange(0, e.target.value.length, 'backward');
  };

  showShare = () => {
    if (navigator.canShare) {
      return true;
    } else {
      return false;
    }
  };

  share = () => {
    const shareData = {
      title: 'eDiplomas',
      text: `eDiplomas Σύνδεσμος ενεργός μέχρι τις ${this.showDate(
        this.state.expiresAt
      )}`,
      url: this.state.authLink
    };
    navigator.share(shareData);
  };

  render = () => (
    <div className="CompletedProcess">
      {this.state.redirectError && <Redirect to="/error" />}
      <div className="container-content">
        <div className="step-description">
          <h2>{this.props.polyglot.t('Graduate.title3')}</h2>
          <p>
            {this.props.polyglot.t('Graduate.paragraph3a')}{' '}
            <strong>"{this.state.displayName}"</strong>{' '}
            {this.props.polyglot.t('Graduate.paragraph3b')}
          </p>
        </div>
        <div id="token-area">
          <span className="token-container">
            <input
              type="text"
              onClick={this.handleAuthLinkClick}
              ref={this.linkRef}
              className="token-text"
              readOnly
              value={this.state.authLink}
            />
            <CopyToClipboardNative
              className="token-action"
              text={this.state.authLink}
              onCopy={this.copyToken}
            >
              <span className="far fa-copy" />
            </CopyToClipboardNative>
            <MailTemplate
              className="token-action"
              mailto={this.state.contactEmail ? this.state.contactEmail : ''}
              subject="eDiplomas Authorization"
              content={this.constructEmail()}
            />
            {this.showShare() && (
              <span className="token-action" onClick={this.share}>
                <span
                  style={{ color: 'white' }}
                  className="fas fa-share"
                ></span>
              </span>
            )}
          </span>
          <div
            id="copiedNotification"
            className={`${
              this.state.copied === 'true' ? 'show disappeared' : ''
            }`}
          >
            {this.props.polyglot.t(`Graduate.codeCopied`)}
          </div>
        </div>
        <div id="duration-container">
          <div id="duration-results">
            <span>{this.props.polyglot.t('Graduate.paragraph4')}</span>
            {this.state.expiresAt > -1 && (
              <span id="duration">{this.showDate(this.state.expiresAt)}</span>
            )}
            {this.state.expiresAt === -1 && <Spinner cx={25} cy={50} r={10} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompletedProcess;
