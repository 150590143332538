import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './MenuGraduate.css';
import ExternalRedirect from '../lib/ExternalRedirect';

class MenuGraduate extends Component {
  state = {
    readyToRedirect: false,
    redirectAddress: '',
    externalRedirect: ''
  };

  handleRedirect = (redirectAddress) => {
    this.setState({ readyToRedirect: true });
    setTimeout(() => {
      this.setState({ redirectAddress });
    }, 500);
  };

  handleExternalRedirect = (address) => {
    this.setState({ readyToRedirect: true });
    setTimeout(() => {
      this.setState({ externalRedirect: address });
    }, 500);
  };

  render = () => {
    if (this.state.redirectAddress) {
      return <Redirect push to={this.state.redirectAddress} />;
    }

    if (this.state.externalRedirect) {
      return (
        <ExternalRedirect
          to={this.state.externalRedirect}
          language={this.props.polyglot.currentLocale}
        />
      );
    }

    return (
      <div className={`Menu ${this.state.readyToRedirect ? 'fade-out' : ''}`}>
        <div className="items">
          <div
            className="item selection"
            onClick={() =>
              this.handleExternalRedirect(
                'https://authorization.ediplomas.gr/mydiplomas/data'
              )
            }
          >
            <div className="icon">
              <span className="fas fa-search" />
            </div>

            <div className="content">
              <h4 className="header">
                {this.props.polyglot.t(
                  `Organisation.menuGraduate.viewDiplomas`
                )}
              </h4>
              <div className="description">
                <p>
                  {this.props.polyglot.t(
                    'Organisation.menuGraduate.controlDiplomas'
                  )}
                </p>
              </div>
              <button className="arrow">
                <span className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
          <div
            className="item selection"
            onClick={() => this.handleRedirect('/graduate')}
          >
            <div className="icon">
              <span className="fas fa-award" />
            </div>

            <div className="content">
              <h4 className="header">
                {this.props.polyglot.t(
                  `Organisation.menuGraduate.authorizeDiploma`
                )}
              </h4>
              <div className="description">
                <p>
                  {this.props.polyglot.t(
                    'Organisation.menuGraduate.newAuthorizationInfo'
                  )}
                </p>
              </div>
              <button className="arrow">
                <span className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
          <div
            className="item selection"
            onClick={() =>
              this.handleExternalRedirect(
                'https://authorization.ediplomas.gr/mydiplomas/consents'
              )
            }
          >
            <div className="icon">
              <span className="fas fa-undo" />
            </div>

            <div className="content">
              <h4 className="header">
                {this.props.polyglot.t(
                  `Organisation.menuGraduate.viewAuthorizations`
                )}
              </h4>
              <div className="description">
                <p>
                  {this.props.polyglot.t(
                    'Organisation.menuGraduate.controlAuthorizations'
                  )}
                </p>
              </div>
              <button className="arrow">
                <span className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default MenuGraduate;
