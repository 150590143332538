import { Base64 } from 'js-base64';

const scrollTop = () => {
  const top = document.documentElement.scrollTop || document.body.scrollTop;
  if (top > 0) {
    window.requestAnimationFrame(scrollTop);
    window.scrollTo(0, 0);
  }
};

const getQueryParams = () => {
  const location = window.location.toString();
  const queryStart = location.indexOf('?');
  let queryEnd = location.indexOf('#');
  const params = {};
  if (queryEnd < 0) {
    queryEnd = location.length;
  }
  if (queryStart >= 0) {
    const query = location.substring(queryStart + 1, queryEnd);
    const queryPairs = query.split('&');

    queryPairs.map((pair) => {
      const queryPair = pair.split('=');
      params[queryPair[0]] = queryPair[1];
    });
  }
  return params;
};

const formatDate = (date) => {
  if (date && date.length == 8) {
    return (
      date.substr(6, 2) + ' / ' + date.substr(4, 2) + ' / ' + date.substr(0, 4)
    );
  }
  return '';
};

const base64EncodeUrl = (str) => {
  return str.replace(/\+/g, '-').replace(/\//g, '_');
};

function base64DecodeUrl(str) {
  return str.replace(/-/g, '+').replace(/_/g, '/');
}

const constructSignature = (signature, payload) => {
  const fullSig = `${signature}.${Base64.encode(payload, true)}`;
  return fullSig;
};

const constructSignedAudit = (signedAuditId, auditId, clientId) => {
  const fullSig = `${signedAuditId}.${Buffer.from(auditId).toString(
    'base64'
  )}.${Buffer.from(clientId).toString('base64')}`;
  return base64EncodeUrl(fullSig);
};

export {
  scrollTop,
  getQueryParams,
  formatDate,
  constructSignature,
  constructSignedAudit,
  base64EncodeUrl,
  base64DecodeUrl
};
