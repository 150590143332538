import React from 'react';
import Footer from '../Footer';
import './News.css';

import Helmet from 'react-helmet';

const EBSIVideo = (props) => (
  <div className="NewsPage">
    <div className="content">
      <div className="breadcrumbs">
        <a className="home" href="/">
          <span className="fas fa-home"> </span>{' '}
          {props.polyglot.t(`newsBreadcrumbs.home`)}
        </a>{' '}
        |
        <span className="paths">
          {props.polyglot.t(`newsBreadcrumbs.news`)} >{' '}
          <a href="/news/ebsi-video"> EBSI Multi University Pilot</a>
        </span>
      </div>
      <h1 className="header">EBSI Multi University Pilot</h1>
      <Helmet>
        <title>eDiplomas - EBSI Multi University Pilot</title>
      </Helmet>
      <p>
        EBSI released this video that explains in simple words how the
        Verifiable Credentials standard and the EBSI (European Blockchain
        Services Infrastructure) can help European Citizens to exchange
        educational credentials.
      </p>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/DDkhnK202z4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
    <Footer polyglot={props.polyglot} />
  </div>
);

export default EBSIVideo;
