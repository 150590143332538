import React from 'react';

const CopyToClipboardNative = (props) => {
  const handleCopy = () => {
    if (navigator.clipboard && window.isSecureContext) {
      // Modern solution
      navigator.clipboard.writeText(props.text);
    } else {
      // Modern solution only works for localhost or https
      // Implementing deprecated solution for failsafe
      const textField = document.createElement('textarea');
      textField.innerText = props.text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
    }

    if (props.onCopy) {
      props.onCopy();
    }
  };

  return (
    <span className={props.className} onClick={handleCopy}>
      {props.children}
    </span>
  );
};

export { CopyToClipboardNative };
