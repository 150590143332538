import React, { Component } from 'react';
import { constructSignature, constructSignedAudit } from '../lib/Util';
import './Check.css';
import MenuLink from '../components/MenuLink';
import CompactDiploma from './CompactDiploma';
import CircleLoader from './CircleLoader';
import { generateFilename } from '../lib/Scopes';
import { normalizeString } from '../lib/StringManipulation';
import {
  Form,
  FormGroup,
  TextInput,
  Dropdown,
  DateInput
} from '../components/Form';

// const iscedOptions = [
//   6, 64, 641, 645, 646, 647, 65, 651, 655, 656, 657, 66, 661, 665, 666, 667, 7,
//   74, 741, 746, 747, 748, 75, 751, 756, 757, 758, 76, 761, 766, 767, 768, 8
// ];

class Check extends Component {
  state = {
    ssn: '',
    institution: '',
    institutionCode: '',
    isAllowed: true,
    institutions: [],
    allowedCodes: [],
    department: '',
    departments: [],
    step: 1,
    firstname: '',
    lastname: '',
    day: '',
    month: '',
    year: '',
    permission: false,
    fetched: false,
    resultCount: -1,
    result: null,
    resultFilename: '',
    loading: 0,
    dateError: false,
    level: 6
  };

  componentDidMount = async () => {
    // Fetch institutions
    const res = await fetch('/api/client/institutions', {
      credentials: 'include'
    });
    const jsonres = await res.json();
    this.setState({
      institutions: jsonres.issuers.map((issuer, index) => ({
        key: index,
        value: JSON.stringify({
          code: issuer.Institution_Code,
          name: issuer.Institution
        }),
        label: issuer.Institution
      })),
      allowedCodes: jsonres.allowed
    });
  };

  fetchDepartments = async () => {
    const res = await fetch(
      `/api/client/institutions/${this.state.institution}/departments`,
      {
        credentials: 'include'
      }
    );
    const jsonres = await res.json();
    this.setState({
      departments: jsonres.issuers.map((issuer, index) => ({
        key: index,
        value: issuer.Department,
        label: issuer.Department
      }))
    });
  };

  isAllowedToCheck = (code) => this.state.allowedCodes.includes(code);

  institutionMetadata = () => {
    if (this.isAllowedToCheck(this.state.institutionCode)) {
      this.setState({ isAllowed: true });
      this.fetchDepartments();
    } else {
      this.setState({ isAllowed: false });
    }
  };

  handleSSN = (e) => {
    const { target } = e;
    this.setState({
      ssn: target.value
    });
  };

  setStep = (step) => {
    this.setState({
      step
    });
  };

  handleInstitution = (event) => {
    this.setState(
      {
        institution: JSON.parse(event.target.value).name,
        institutionCode: JSON.parse(event.target.value).code,
        department: '',
        ssn: '',
        level: 6,
        firstname: '',
        lastname: '',
        year: '',
        month: '',
        day: ''
      },
      this.institutionMetadata
    );
  };

  handleDepartment = (event) => {
    this.setState({
      department: event.target.value
    });
  };

  handleFirstname = (e) => {
    const { target } = e;
    this.setState({ firstname: target.value });
  };

  handleLastname = (e) => {
    const { target } = e;
    this.setState({ lastname: target.value });
  };

  handleDay = (day) => {
    this.setState({ day });
  };

  handleMonth = (month) => {
    this.setState({ month });
  };

  handleYear = (year) => {
    this.setState({ year });
  };

  handleDateError = (error) => {
    this.setState({ dateError: error });
  };

  handlePermission = () => {
    this.setState((prevState) => ({
      ...prevState,
      permission: !prevState.permission
    }));
  };

  handleLevel = (e) => {
    const { target } = e;
    const level = target.value;
    this.setState({ level });
  };

  normalizeFirstname = () => {
    const firstname = normalizeString(this.state.firstname)
      .toUpperCase()
      .trim();
    this.setState({
      firstname
    });
  };

  normalizeLastname = () => {
    const lastname = normalizeString(this.state.lastname).toUpperCase().trim();
    this.setState({
      lastname
    });
  };

  search = async () => {
    const data = new FormData();
    data.append('ssn', this.state.ssn);
    data.append('institution', this.state.institution);
    data.append('institutionCode', this.state.institutionCode);
    data.append('department', this.state.department);
    data.append('day', this.state.day);
    data.append('month', this.state.month);
    data.append('year', this.state.year);
    data.append('lastname', this.state.lastname);
    data.append('level', this.state.level);

    const res = await fetch('/api/client/diploma_check/check', {
      method: 'POST',
      body: data,
      credentials: 'include'
    });
    const jsonres = await res.json();
    let resultFilename = '';
    if (jsonres.result) {
      jsonres.result.signature = constructSignature(
        jsonres.result.signature,
        jsonres.result.payload
      );
      jsonres.result.signedAuditId = constructSignedAudit(
        jsonres.result.signedAuditId,
        jsonres.result.auditId,
        jsonres.result.aud
      );
      resultFilename = generateFilename(jsonres.result.diploma);
    } else {
      jsonres.result = {};
    }
    this.setState({
      fetched: true,
      result: jsonres.result,
      resultCount: jsonres.resultCount,
      resultFilename
    });
  };

  levelOptions = [
    {
      value: 6,
      label: this.props.polyglot.t('Organisation.check.bachelor')
    },
    {
      value: 7,
      label: this.props.polyglot.t('Organisation.check.master')
    },
    {
      value: 8,
      label: this.props.polyglot.t('Organisation.check.doctorate')
    }
  ];

  render = () => {
    const step1valid = this.state.institution !== '' && this.state.isAllowed;
    return (
      <>
        <div className="Check">
          <MenuLink polyglot={this.props.polyglot} />
          <div className="description">
            <h3>{this.props.polyglot.t('Organisation.check.inspect')}</h3>
            <p>
              {this.props.polyglot.t('Organisation.check.inspectDescription')}
            </p>
            <p>
              {this.props.polyglot.t('Organisation.check.byFilling')}{' '}
              <strong>
                {this.props.polyglot.t('Organisation.check.institution')}
              </strong>
              , {this.props.polyglot.t('Organisation.check.the')}{' '}
              <strong>{this.props.polyglot.t('Organisation.check.ssn')}</strong>
              , {this.props.polyglot.t('Organisation.check.andOtherYouCanGet')}{' '}
              <strong>
                {this.props.polyglot.t('Organisation.check.signed')}
              </strong>
              ,{' '}
              <strong>
                {this.props.polyglot.t('Organisation.check.digital')}
              </strong>{' '}
              {this.props.polyglot.t('Organisation.check.editionFrom')}{' '}
              <i>eDiplomas</i>.
            </p>
          </div>
          <div className="collapse-steps">
            <div
              id="check-step1"
              className={`step ${this.state.step === 1 ? 'opened' : 'closed'} ${
                this.state.step > 1 ? 'completed' : 'uncompleted'
              }`}
            >
              <div className="header">
                <span
                  className={`icon ${
                    this.state.step === 1 ? 'fas fa-minus' : ''
                  }`}
                />{' '}
                <span className="before-completion">
                  {this.props.polyglot.t(
                    'Organisation.check.institutionSelect'
                  )}
                </span>
                <div className="after-completion">
                  <span>
                    {this.props.polyglot.t('Organisation.check.institution')}:{' '}
                    {this.state.institution}
                  </span>
                  <button
                    onClick={() => this.setStep(1)}
                    type="button"
                    className="ui button edit"
                  >
                    {this.props.polyglot.t('Organisation.check.change')}{' '}
                    <span className="fas fa-pencil-alt" />
                  </button>
                </div>
              </div>
              <div className="step-content">
                <Form className="form">
                  <FormGroup>
                    <Dropdown
                      options={this.state.institutions}
                      onChange={this.handleInstitution}
                      label={this.props.polyglot.t(
                        'Organisation.check.institution'
                      )}
                      placeholder={`${this.props.polyglot.t(
                        'Organisation.check.option'
                      )}...`}
                    />
                  </FormGroup>
                  <button
                    onClick={() => {
                      this.setStep(2);
                    }}
                    disabled={!step1valid}
                    type="button"
                    className="ui fancy button right floated"
                  >
                    {this.props.polyglot.t('Organisation.check.continue')}{' '}
                    <span className="fas fa-arrow-right" />
                  </button>
                  <br />
                  <span
                    className={`error ${this.state.isAllowed ? 'hidden' : ''}`}
                    id="not-authorized"
                  >
                    {this.props.polyglot.t(`Organisation.check.notAuthorized`)}
                  </span>
                </Form>
              </div>
            </div>
            <div
              id="check-step2"
              className={`step ${this.state.step === 2 ? 'opened' : 'closed'} ${
                this.state.step > 2 ? 'completed' : 'uncompleted'
              }`}
            >
              <div className="header">
                <span
                  className={`${this.state.step === 2 ? 'fas fa-minus' : ''}`}
                />{' '}
                <span className="before-completion">
                  {this.props.polyglot.t('Organisation.check.gradInfo')}
                </span>
                <div className="after-completion">
                  <span>
                    {this.props.polyglot.t('Organisation.check.department')}:{' '}
                    {this.state.department}
                  </span>{' '}
                  <br />
                  <span>
                    {this.props.polyglot.t('Organisation.check.level')}:{' '}
                    {this.props.polyglot.t(
                      `educationalLevels.${this.state.level}`
                    )}
                  </span>{' '}
                  <br />
                  <span>
                    {this.props.polyglot.t('Organisation.check.ssn')}:{' '}
                    {this.state.ssn}
                  </span>{' '}
                  <br />
                  <span>
                    {this.props.polyglot.t('Organisation.check.fullname')}:{' '}
                    {this.state.firstname} {this.state.lastname}
                  </span>{' '}
                  <br />
                  <span>
                    {this.props.polyglot.t('Organisation.check.date')}:{' '}
                    {this.state.day}/{this.state.month}/{this.state.year}
                  </span>
                  <button
                    onClick={() => this.setStep(2)}
                    type="button"
                    className="ui button edit"
                  >
                    {this.props.polyglot.t('Organisation.check.change')}{' '}
                    <span className="fas fa-pencil-alt" />
                  </button>
                </div>
              </div>
              <div className="step-content">
                <Form className="form">
                  <FormGroup>
                    <Dropdown
                      options={this.state.departments}
                      onChange={this.handleDepartment}
                      label={this.props.polyglot.t(
                        'Organisation.check.department'
                      )}
                      placeholder={`${this.props.polyglot.t(
                        'Organisation.check.option'
                      )}...`}
                    />
                  </FormGroup>
                  <FormGroup className="level-pick">
                    <Dropdown
                      label={this.props.polyglot.t('Organisation.check.level')}
                      placeholder={`${this.props.polyglot.t(
                        'Organisation.check.option'
                      )}...`}
                      options={this.levelOptions}
                      onChange={this.handleLevel}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextInput
                      id="ssn"
                      label={this.props.polyglot.t('Organisation.check.ssn')}
                      value={this.state.ssn}
                      maxLength={11}
                      onChange={this.handleSSN}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextInput
                      id="firstname"
                      label={this.props.polyglot.t(
                        'Organisation.check.firstname'
                      )}
                      value={this.state.firstname}
                      onChange={this.handleFirstname}
                      onBlur={this.normalizeFirstname}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextInput
                      id="lastname"
                      label={this.props.polyglot.t(
                        'Organisation.check.lastname'
                      )}
                      value={this.state.lastname}
                      onChange={this.handleLastname}
                      onBlur={this.normalizeLastname}
                    />
                  </FormGroup>
                  <FormGroup id="date-wrapper">
                    <DateInput
                      label={this.props.polyglot.t('Organisation.check.date')}
                      tooltip={this.props.polyglot.t(
                        'Organisation.check.dateInfo'
                      )}
                      year={this.state.year}
                      month={this.state.month}
                      day={this.state.day}
                      handleYear={this.handleYear}
                      handleMonth={this.handleMonth}
                      handleDay={this.handleDay}
                      blurYear={this.formatYear}
                      blurMonth={this.formatMonth}
                      blurDay={this.formatDay}
                      error={this.dateError}
                      handleError={this.handleDateError}
                    />
                    <div
                      className={`error ${
                        this.state.dateError ? '' : 'hidden'
                      }`}
                    >
                      {this.props.polyglot.t('Organisation.check.wrongDate')}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="permission-box">
                      <label className="checkbox-label" htmlFor="permission">
                        <input
                          type="checkbox"
                          id="permission"
                          checked={this.state.permission}
                          onChange={this.handlePermission}
                        />
                        <span>
                          {this.props.polyglot.t('Organisation.check.theLaw')}
                        </span>
                      </label>
                    </div>
                  </FormGroup>
                  <button
                    onClick={() => {
                      this.setStep(3);
                      this.search();
                    }}
                    type="button"
                    className="ui fancy button right floated"
                    disabled={
                      !(
                        this.state.institution &&
                        this.state.department &&
                        this.state.ssn &&
                        this.state.firstname &&
                        this.state.lastname &&
                        this.state.day &&
                        this.state.month &&
                        this.state.year &&
                        !this.state.dateError &&
                        this.state.level &&
                        this.state.permission
                      )
                    }
                  >
                    {this.props.polyglot.t('Organisation.check.search')}
                  </button>
                </Form>
              </div>
            </div>
            <div
              id="check-step3"
              className={`step ${this.state.step === 3 ? 'opened' : 'closed'}`}
            >
              <div className="header">
                <span
                  className={`${this.state.step === 3 ? 'fas fa-minus' : ''}`}
                />
                <span>
                  {this.props.polyglot.t('Organisation.check.searchResults')}
                </span>
                {this.state.step === 3 &&
                  this.state.result &&
                  this.state.resultCount === 0 && (
                    <div className="light-success">
                      {this.props.polyglot.t(
                        'Organisation.check.successfulFetch'
                      )}
                    </div>
                  )}
                {this.state.step === 3 &&
                  this.state.fetched &&
                  this.state.resultCount === undefined && (
                    <div className="light-failure">
                      {this.props.polyglot.t(
                        'Organisation.check.unsuccessfulFetch'
                      )}
                    </div>
                  )}
              </div>
              <div className="step-content">
                <CircleLoader
                  loading={
                    this.state.loading !== 0 || this.state.resultCount === -1
                  }
                  verified={this.state.resultCount === 1}
                />
                {this.state.loading === 0 && this.state.resultCount > 0 && (
                  <div className="data_area">
                    <div className="button-area">
                      <form
                        id="downloadPdf"
                        method="POST"
                        action={`/api/client/diploma_pdf/${this.state.resultFilename}`}
                        target="_blank"
                      >
                        <input
                          type="text"
                          name="diploma_data"
                          value={JSON.stringify(this.state.result)}
                        />
                        <input
                          type="text"
                          name="language"
                          value={this.props.polyglot.currentLocale}
                        />
                        <button className="ui fancy button">
                          <span className="fas fa-file-download" />
                          <span className="not-mobile">
                            {' '}
                            {this.props.polyglot.t('Organisation.pdfVersion')}
                          </span>
                        </button>
                      </form>
                      <form
                        id="downloadPrintable"
                        method="POST"
                        action={`/api/client/diploma_printable/${this.state.resultFilename}`}
                        target="_blank"
                      >
                        <input
                          type="text"
                          name="diploma_data"
                          value={JSON.stringify(this.state.result)}
                        />
                        <input
                          type="text"
                          name="language"
                          value={this.props.polyglot.currentLocale}
                        />
                        <button className="ui fancy button">
                          <span className="fas fa-print" />{' '}
                          <span className="not-mobile">
                            {this.props.polyglot.t('Organisation.print')}
                          </span>
                        </button>
                      </form>
                    </div>
                    <CompactDiploma
                      selectedDiploma={this.state.result}
                      polyglot={this.props.polyglot}
                      displaySignature
                    />
                  </div>
                )}
                {this.state.loading === 0 && this.state.resultCount === 0 && (
                  <div className="data_area">
                    <p>
                      {this.props.polyglot.t('Organisation.check.notFound')}
                    </p>
                    <p className="less-visible">
                      <strong>
                        {this.props.polyglot.t('Organisation.check.note')}:
                      </strong>{' '}
                      {this.props.polyglot.t(
                        'Organisation.check.maybeNotSubmittedAt'
                      )}{' '}
                      <em>eDiplomas</em>{' '}
                      {this.props.polyglot.t(
                        'Organisation.check.byInstitution'
                      )}
                      . {this.props.polyglot.t('Organisation.check.info')}{' '}
                      <em>eDiplomas</em>{' '}
                      {this.props.polyglot.t('Organisation.check.canBeFoundAt')}{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/institutions"
                      >
                        www.ediplomas.gr/institutions
                      </a>
                      .
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
}

export default Check;
