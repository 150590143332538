import React from 'react';

import './CollapseDiploma.css';

const CollapseDiploma = (props) => {
  const handleClick = () => {
    props.selectDiploma(props.index);
  };
  return (
    <div
      key={props.index}
      className={`pill ${props.active === props.index ? 'opened' : 'closed'}`}
    >
      <div className="pill-header" onClick={handleClick}>
        {props.headers.map((header) => (
          <div key={`${JSON.stringify(header)}`} className="pill-header-tag">
            {header}
          </div>
        ))}
        {props.subheaders.map((subheader) => (
          <div
            key={`${JSON.stringify(subheader)}`}
            className="pill-header-text"
          >
            {subheader}
          </div>
        ))}
        <span className="pill-header-btn">
          <span
            className={`fas ${
              props.active === props.index ? 'fa-chevron-up' : 'fa-chevron-down'
            }`}
          >
            {' '}
          </span>
        </span>
      </div>
      <div className="pill-content">{props.children}</div>
    </div>
  );
};

CollapseDiploma.Action = ({ children }) => (
  <div className="action-area">{children}</div>
);

CollapseDiploma.DataTable = ({ children }) => (
  <table>
    <tbody>{children}</tbody>
  </table>
);

CollapseDiploma.Pair = ({ field, children }) => (
  <tr>
    <td>{field}</td>
    <td>{children}</td>
  </tr>
);

export default CollapseDiploma;
