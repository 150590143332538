import 'react-app-polyfill/ie11';
import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import Polyglot from 'node-polyglot';
import Landing from './Landing';
import Graduate from './graduate/Graduate';
import Organisation from './organisation/Organisation';
import GeneralError from './GeneralError';
import AdoptionRates from './AdoptionRates';
import JadesReport from './staticpages/JadesReport';
import EBSIReport from './staticpages/EBSIReport';
import EBSIVideo from './staticpages/EBSIVideo';
import BlueprintsArticle from './staticpages/BlueprintsArticle';
import EmrexArticle from './staticpages/EmrexArticle';
import TermsOfUse from './TermsOfUse';
import Documentation from './documentation/Documentation';
import DocumentationHome from './documentation/DocumentationHome';
import DevelopersGuide from './documentation/DevelopersGuide';
import InstitutionsGuide from './documentation/InstitutionsGuide';
import OrganisationsGuide from './documentation/OrganisationsGuide';
import LocaleEl from './i18n/locale-el';
import LocaleEn from './i18n/locale-en';
import { AuthProvider } from './lib/AuthContext';
import { getQueryParams } from './lib/Util';
import Header from './Header';
import LanguageSwitch from './LanguageSwitch';

const POLYGLOT = {
  el: new Polyglot({ phrases: LocaleEl, locale: 'el' }),
  en: new Polyglot({ phrases: LocaleEn, locale: 'en' })
};

class App extends Component {
  state = {
    lang: 'el',
    error: false,
    loading: 0
  };

  handleLanguage = (lang) => {
    if (lang !== 'el' && lang !== 'en') {
      lang = 'en';
    }
    this.setState({ lang });
    sessionStorage.setItem('lang', lang);
  };

  componentDidMount = () => {
    let lang = sessionStorage.getItem('lang');
    const queryParams = getQueryParams();
    lang = 'lang' in queryParams ? queryParams.lang : lang;
    if (lang) {
      this.handleLanguage(lang);
    }
  };

  render = () => (
    <AuthProvider>
      <BrowserRouter>
        <>
          <Header
            polyglot={POLYGLOT[this.state.lang]}
            handleLanguage={this.handleLanguage}
          />
          <LanguageSwitch
            polyglot={POLYGLOT[this.state.lang]}
            handleLanguage={this.handleLanguage}
          />
          <Switch>
            <Route
              path="/v"
              render={() => (
                <Redirect
                  to={`/organisation/verify#${
                    window.location.href.split('#')[1] || ''
                  }`}
                />
              )}
            />
            <Route
              path="/graduate"
              render={() => <Graduate polyglot={POLYGLOT[this.state.lang]} />}
            />
            <Route
              path="/organisation"
              render={() => (
                <Organisation
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLogin={this.handleLogin}
                  handleLogout={this.handleLogout}
                  loading={this.state.loading}
                  loggedIn={this.state.loggedIn}
                  clientName={this.state.clientName}
                  ready={this.state.ready}
                  error={this.state.error}
                />
              )}
            />
            <Route
              path="/o/:token"
              render={(props) => (
                <Redirect to={`/organisation/${props.match.params.token}`} />
              )}
            />
            <Route
              path="/error"
              render={() => (
                <GeneralError polyglot={POLYGLOT[this.state.lang]} />
              )}
            />
            <Route
              path="/institutions"
              render={() => (
                <AdoptionRates
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                />
              )}
            />
            <Route
              path="/beta-institutions"
              render={() => (
                <AdoptionRates
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                  betaAdopters={true}
                />
              )}
            />
            <Route
              path="/terms"
              render={() => (
                <TermsOfUse
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                />
              )}
            />
            <Route
              path="/documentation/organisations"
              render={() => (
                <Documentation
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                  navCategory="organisations"
                  backTopLink
                >
                  <OrganisationsGuide polyglot={POLYGLOT[this.state.lang]} />
                </Documentation>
              )}
            />
            <Route
              path="/documentation/institutions"
              render={() => (
                <Documentation
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                  navCategory="institutions"
                  backTopLink
                >
                  <InstitutionsGuide polyglot={POLYGLOT[this.state.lang]} />
                </Documentation>
              )}
            />
            <Route
              path="/documentation/developers"
              render={() => (
                <Documentation
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                  navCategory="developers"
                  backTopLink
                >
                  <DevelopersGuide polyglot={POLYGLOT[this.state.lang]} />
                </Documentation>
              )}
            />
            <Route
              path="/news/jades"
              render={() => (
                <JadesReport
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                />
              )}
            />
            <Route
              path="/news/ebsi"
              render={() => (
                <EBSIReport
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                />
              )}
            />
            <Route
              path="/news/ebsi-video"
              render={() => (
                <EBSIVideo
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                />
              )}
            />
            <Route
              path="/news/blueprints"
              render={() => (
                <BlueprintsArticle
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                />
              )}
            />
            <Route
              path="/news/emrex"
              render={() => (
                <EmrexArticle
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                />
              )}
            />
            <Route
              path="/documentation"
              render={() => (
                <Documentation
                  polyglot={POLYGLOT[this.state.lang]}
                  handleLanguage={this.handleLanguage}
                  backTopLink={false}
                >
                  <DocumentationHome polyglot={POLYGLOT[this.state.lang]} />
                </Documentation>
              )}
            />
            <Route
              path="/"
              render={() => (
                <Landing
                  polyglot={POLYGLOT[this.state.lang]}
                  loggedIn={this.state.loggedIn}
                  handleLanguage={this.handleLanguage}
                  clientName={this.state.clientName}
                  error={this.state.error}
                />
              )}
            />
          </Switch>
        </>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
