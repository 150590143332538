import React, { Component } from 'react';
import { Message } from '../components/Message';
import './Verify.css';
import MenuLink from '../components/MenuLink';
import CompactDiploma from './CompactDiploma';
import CircleLoader from './CircleLoader';
import Authentication from '../lib/Authentication';
import { scrollTop, base64DecodeUrl } from '../lib/Util';
import { Form, FormGroup, TextInput } from '../components/Form';

class Verify extends Component {
  state = {
    input: '',
    invalidInputFormat: null,
    verified: null,
    loading: 0,
    hashesMatch: null,
    data: null
  };

  componentDidMount = () => scrollTop();

  UNSAFE_componentWillMount = () => {
    let signature = '';
    const signatureParam = window.location.href.split('#')[1];
    if (signatureParam) {
      signature = signatureParam;
      sessionStorage.setItem('signature', signature);
    } else {
      signature = sessionStorage.getItem('signature');
      sessionStorage.removeItem('signature');
    }

    if (signature) {
      this.props.history.push(`/organisation/verify#${signature}`);
      this.setState({ input: signature }, this.verify);
    } else {
      this.setState({ input: '' });
    }
  };

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ input: value });
  };

  verify = async (e = null) => {
    if (e) e.preventDefault();
    this.props.history.push(`/organisation/verify#${this.state.input}`);
    this.setState((prevState) => ({ loading: prevState.loading + 1 }));
    const signedAuditId = this.state.input.split('.')[0];
    const auditId = this.state.input.split('.')[1];
    const clientId = this.state.input.split('.')[2];
    const data = new FormData();
    if (auditId == undefined || clientId == undefined) {
      this.setState((prevState) => ({
        invalidInputFormat: true,
        verified: false,
        loading: prevState.loading - 1
      }));
    } else {
      data.append('signedAuditId', base64DecodeUrl(signedAuditId));
      data.append('auditId', auditId);
      data.append('clientId', clientId);
      try {
        const res = await fetch('/api/client/verify', {
          method: 'POST',
          body: data,
          credentials: 'include'
        });
        const resJson = await res.json();
        if (resJson.error) {
          this.setState({
            verified: resJson.verified,
            invalidInputFormat: false
          });
        } else {
          this.setState({
            invalidInputFormat: false,
            verified: resJson.verified,
            hashesMatch: resJson.match
          });
          this.setData(resJson.diploma);
        }
        this.setState((prevState) => ({ loading: prevState.loading - 1 }));
      } catch (e) {
        this.setState((prevState) => ({ loading: prevState.loading - 1 }));
      }
    }
  };

  setData = (diploma) => {
    if (diploma) {
      this.setState({
        data: {
          diploma: diploma
        }
      });
    }
  };

  formatInput = () => {
    if (this.state.input) {
      const input = this.state.input.replace(/\s+/g, '');
      this.setState({ input });
    }
  };

  render = () => (
    <>
      <Authentication next="verify" />
      <MenuLink polyglot={this.props.polyglot} />
      <div className="Verify">
        {(this.state.verified == null || this.state.loading !== 0) && (
          <>
            <div className="description">
              <h3>{this.props.polyglot.t(`Verifier.signatureVerification`)}</h3>
              <p>{this.props.polyglot.t('Verifier.enterSignature')}</p>
            </div>
            <br />
            <Form className="input_area">
              <FormGroup>
                <TextInput
                  disabled={this.state.loading !== 0}
                  value={this.state.input}
                  onChange={this.handleChange}
                  onBlur={this.formatInput}
                  label={this.props.polyglot.t('Verifier.signature')}
                />
                <button onClick={this.verify} className="ui fancy">
                  {this.props.polyglot.t('Verifier.verify')}
                </button>
              </FormGroup>
            </Form>
          </>
        )}
        <div className="Verifier">
          <div className="result_area">
            {(this.state.verified !== null || this.state.loading !== 0) && (
              <>
                <CircleLoader
                  loading={this.state.loading !== 0}
                  verified={this.state.verified}
                  hashesMatch={this.state.hashesMatch}
                />
                {this.state.invalidInputFormat === true &&
                  this.state.verified === false && (
                    <div className="data_area">
                      <Message
                        error
                        header={this.props.polyglot.t('Verifier.errorHeader')}
                      >
                        {this.props.polyglot.t('Verifier.invalidInput')}
                      </Message>
                    </div>
                  )}
                {this.state.verified === false &&
                  this.state.invalidInputFormat === false && (
                    <div className="data_area">
                      <Message
                        error
                        header={this.props.polyglot.t('Verifier.errorHeader')}
                      >
                        {this.props.polyglot.t('Verifier.invalidSignature')}
                      </Message>
                    </div>
                  )}
                {this.state.verified === true &&
                  this.state.loading === 0 &&
                  this.state.data &&
                  this.state.hashesMatch && (
                    <div className="data_area">
                      <CompactDiploma
                        selectedDiploma={this.state.data}
                        polyglot={this.props.polyglot}
                        displaySignature={false}
                      />
                    </div>
                  )}
                {this.state.verified === true &&
                  this.state.loading === 0 &&
                  this.state.hashesMatch === false && (
                    <div className="data_area">
                      <Message
                        warning
                        header={this.props.polyglot.t(
                          'Verifier.notMatchingHeader'
                        )}
                      >
                        {this.props.polyglot.t('Verifier.notMatchingContent')}
                      </Message>
                    </div>
                  )}
                {this.state.loading === 0 && (
                  <p
                    style={{
                      'font-size': '15px',
                      color: 'rgb(53, 53, 53)',
                      padding: '0 40px'
                    }}
                  >
                    <br />
                    <a href="/organisation/verify">
                      {this.props.polyglot.t('Verifier.newCheck')}
                    </a>
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Verify;
