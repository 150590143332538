import React from 'react';
import CodeExample from '../../components/CodeExample';
import {
  SingleAPIExample,
  MultipleAPIExample,
  NoAPIExample,
  InvalidReqExample,
  InvalidAuthTokenExample
} from '../APIExamples';
import { Heading } from '../Common';
import institutionExample from '../institution_example_en.svg';

const svgPanZoom = require('svg-pan-zoom');

const viewFields = [
  { name: 'CERTIFICATEID', type: 'VARCHAR2(25)' },
  { name: 'TITLEID', type: 'NUMBER(10, 0)' },
  { name: 'GRADEDESCRIPTION', type: 'VARCHAR2(60)' },
  { name: 'GRADEVALUE', type: 'NUMBER(5,3)' },
  { name: 'DATEISSUED', type: 'VARCHAR2(8)' },
  { name: 'VALIDFROM', type: 'VARCHAR2(8)' },
  { name: 'STATUS', type: 'VARCHAR2(20)' },
  { name: 'STATUSDATE', type: 'VARCHAR2(8)' },
  { name: 'SSN', type: 'VARCHAR2(11)' },
  { name: 'SSNCOUNTRY', type: 'VARCHAR2(2)' },
  { name: 'TIN', type: 'VARCHAR2(9)' },
  { name: 'TINCOUNTRY', type: 'VARCHAR2(2)' },
  { name: 'FIRSTNAMEEL', type: 'VARCHAR2(40)' },
  { name: 'FIRSTNAMEEN', type: 'VARCHAR2(40)' },
  { name: 'LASTNAMEEL', type: 'VARCHAR2(45)' },
  { name: 'LASTNAMEEN', type: 'VARCHAR2(45)' },
  { name: 'MIDDLENAMEEL', type: 'VARCHAR2(40)' },
  { name: 'MIDDLEENAMEEN', type: 'VARCHAR2(40)' },
  { name: 'FATHERNAMEEL', type: 'VARCHAR2(20)' },
  { name: 'FATHERNAMEEN', type: 'VARCHAR2(20)' },
  { name: 'MOTHERNAMEEL', type: 'VARCHAR2(20)' },
  { name: 'MOTHERNAMEEN', type: 'VARCHAR2(20)' },
  { name: 'BIRTHDATE', type: 'VARCHAR2(8)' },
  { name: 'BIRTHYEAR', type: 'NUMBER(4,0)' },
  { name: 'GENDER', type: 'NUMBER(1,0)' },
  { name: 'CITIZENSHIP', type: 'VARCHAR2(2)' },
  { name: 'REGISTRATIONID', type: 'NUMBER(13,0)' }
];

export default () => {
  const loadPanZoom = () => {
    svgPanZoom('#institutionExample', {
      controlIconsEnabled: true,
      fit: true
    });
  };

  return (
    <>
      <h1 className="header">Institutions</h1>
      <div className="section">
        <div className="inner section">
          <p>
            In the course of an Institution's integration to the{' '}
            <em>eDiplomas</em> service, the steps below have to be followed:
          </p>
          <ol>
            <li>Identification of the graduates by their SSN</li>
            <li>Documenting the degree templates</li>
            <li>
              Implementation of a DB View or API containing the graduates'
              information
            </li>
          </ol>
          <p>
            The data that have to be provided by the Institution are divided
            into 2 categories:
          </p>
          <ul>
            <li>
              The data regarding the Degree Templates, meaning all the
              information contained in a degree except for the parts which refer
              to its owner's information. These data are created and structured
              in the way described in the following sections, and are stored in
              the eDiplomas platform.
            </li>
            <li>
              The data regarding the degree's owner which are stored and
              provided by the Institution through either an API or DB View.
              (This process is described in more detail in the next sections)
            </li>
          </ul>
          The degree's owner's data are combined with the data of a degree
          template and form a Degree.
        </div>
      </div>
      <div className="section">
        <h2 id="ins_basic_concepts" className="header nav-anchor">
          Basic Concepts
        </h2>
        <div className="inner section">
          <p>
            The integration of an Institution's degrees in <em>eDiplomas</em>,
            includes the registration of information of a specific structure,
            regarding the degrees, through a process described in the next
            section.
          </p>
          <p>
            The data which have to be entered following a specific structure
            belong to the following categories:
          </p>
          <ul>
            <li>
              <strong>Institutions:</strong> The records which consist the{' '}
              <em>Institutions</em> have to contain the current Institution's
              name as well as every older name of this Institution under which
              degrees have been issued. This also includes the names which
              occurred after Institution merges, in which case are all the names
              that had appeared on degrees while these Institutions where
              separate entities.
            </li>
            <li>
              <strong>Maintaining Departments:</strong> The records of the
              Departments which constitute the Institution at the time being.
              They appear only with their current name (a record per
              Department). These departments are <em>in charge</em> for
              inserting and updating the data regarding the degrees. A{' '}
              <strong>Maintaining Department</strong> is associated with a
              record in the <em>Institutions</em> which has the current
              Institution's name.{' '}
            </li>
            <li>
              <strong>Issuing Departments:</strong> The records of the
              Departments which have issued degrees. Every Department name which
              has appeared on a degree, has to be included in this category.
              This includes the current departments of the Institution, the
              occasional older names as well as the names which were
              discontinued (either permanently or merged/joined) but had, at
              some point, issued degrees. They are associated with one of the{' '}
              <strong>Institutions</strong> and one{' '}
              <strong>Maintaining Department</strong> which is currently in
              charge of the degrees originally issued by the{' '}
              <strong>Issuing Department</strong>.
            </li>
            <li>
              <strong>Curricula</strong>: The records of the curricula belonging
              to the Institution. The revisions of an existing Curriculum does
              not constitute a new record if they do not affect its name, its
              level, or the knowledge provided (fields of education). The{' '}
              <strong>Curricula</strong> are associated with the{' '}
              <strong>Maintaining Department</strong> to which they belong.{' '}
            </li>
            <li>
              <strong>Degree Templates</strong>: The records of the different
              templates of degrees which are or have been issued by an
              Institution. They are associated with the{' '}
              <strong>Issuing Department</strong> and the{' '}
              <strong>Institution</strong> which issued them, as well as the
              corresponding <strong>Curriculum</strong>. Lastly, they are
              associated with the <strong>Maintaining Department</strong> which
              is in charge of these degrees at the time being.
            </li>
          </ul>
          <figure>
            <object
              onLoad={loadPanZoom}
              id="institutionExample"
              data={institutionExample}
              type="image/svg+xml"
            />
            <figcaption>
              Structure Example. Drag to move, Scroll up/down to scale.
            </figcaption>
          </figure>
          <p>
            The concepts <em>Institution</em>, <em>Issuing Department</em> and{' '}
            <em>Maintaining Department</em> are related to the{' '}
            <strong>Organisational</strong> structure of the Institution. The
            concepts <em>Curricula</em> and <em>Degree Template</em> are related
            to the <strong>Studies</strong>.
          </p>
          <div className="noteblock">
            <strong>Note:</strong> In <em>eDiplomas</em>, the terms{' '}
            <em>Department</em>, <em>Institution</em>, <em>Curriculum</em> and{' '}
            <em>Degree</em> have the same meaning as in FEK{' '}
            <em>4009/2011, 4076/2012, 4485/2017</em>.
          </div>
        </div>
      </div>
      <div className="section">
        <h2 id="ins_template-creation" className="header nav-anchor">
          Degree Template Creation
        </h2>
        <div className="inner section">
          <p>
            In order for an Institution to register the <i>Degree Templates</i>{' '}
            which it is in charge of issuing, it should proceed to the{' '}
            <a href="https://blueprints.ediplomas.gr">
              Degree Template Administration Service
            </a>{' '}
            application. In order for the Institution staff to gain access to
            this application, a pair of special <i>credentials</i> will be
            needed, which will be received upon communication with{' '}
            <i>eDiplomas</i>. After logging into this platform, the user will be
            able to register the information regarding the degrees the
            Institution they represent is in charge of issuing, by creating{' '}
            <i>Institutions</i>, <i>Departments</i>, <i>Curricula</i> and{' '}
            <i>Degree Templates</i>, in order for it to be able to cover every
            possible degree that will need to be issued.
          </p>
          <p>The steps of submission are the following:</p>
          <ul>
            <li>
              <i>Institutions</i> Submission: Submission of the names which have
              appeared in degrees as Issuer Institutions. Specifically, apart
              from the current Institution name, these need to contain all the
              former names of the Institution as long as the sub-Institutions
              that may have joined to bring it to its current form.
            </li>
            <li>
              <i>Maintaining Departments</i> Submission: Submission of the
              Departments that constitute the Institution in its current form.
              These Departments have to be correlated to the current name of the
              Institution (which had to been submitted in the previous step).
            </li>
            <li>
              <i>Issuing Departments</i> Submission: Submission of the
              Departments (current and older) which have issued degrees in the
              Institution. Departments which no longer exist (f.e. due to a
              merge) have to be added as well and be correlated to the
              department which is currently in charge of its degrees.
            </li>
            <li>
              <i>Curricula</i> Submission: Submission of information regarding
              the Curricula for which a Department is in charge (either current
              or old).
            </li>
            <li>
              <i>Degree Template</i> Submission: Submission of the Degree
              Templates which are either issued today or they have been issued
              in the past by any Department.
            </li>
          </ul>
          <p>
            Afterwards, the Institution will have to implement either a{' '}
            <i>Database view</i>, or an <i>API</i> containing the graduates'
            data as described in the <a href="#ins_data-entry">next section</a>.
          </p>
        </div>
        <div className="inner section">
          <Heading text="Example" id="ins_template-example" level="3" />
          <p>
            After an Institution follows the steps listed above on the{' '}
            <a href="https://blueprints.ediplomas.gr">
              Degree Template Administration
            </a>{' '}
            application, it will now have, in its homepage, a representation of
            the data he submitted as a tree structure.
          </p>
          <p>An example of a tree that can be produced is given below.</p>
          <div className="example tree">
            <ul>
              <li>
                <h2>Institution 1</h2>
                <ul>
                  <li>
                    <h4>Maintaining Departments</h4>
                    <ul>
                      <li>
                        <h4>Maintaining Department 1</h4>
                        <ul>
                          <h4>Curricula of the Maintaining Department</h4>
                          <li>Curriculum 1</li>
                          <li>Curriculum 2</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h4>Issuing Departments</h4>
                    <ul>
                      <li>
                        <h5>Issuing Department 1</h5>
                        <ul>
                          <h4>Degree Templates</h4>
                          <li>Degree Template 1</li>
                          <li>Degree Template 2</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h2>Institution 2</h2>
                <span>...</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section">
        <h2 id="ins_data-entry" className="header nav-anchor">
          Graduate Information Entry
        </h2>
        <div className="inner section">
          <p>
            In order for an Institution to enter the <i>eDiplomas</i> platform
            and provide the electronic version of its issued degrees, it has to
            implement a specific infrastructure, from which <i>eDiplomas</i>{' '}
            will draw the degrees' information. <i>Ediplomas</i> offers 2
            options for this infrastructure:
          </p>
          <ul>
            <li>
              Connection by implementing a <a href="#ins_view">Database View</a>
              : The Institution implements a Database View of a specific schema
              that contains all the information required regarding the degrees.
            </li>
            <li>
              Connection by implementing an <a href="#ins_API">API</a>: The
              Institution implements an API for a Web Service that responds to
              search requests made by <i>eDiplomas</i> in a specific JSON type
              response.
            </li>
          </ul>
          <p>
            The Institutions that will integrate into <i>eDiplomas</i> have to
            choose to develop <strong>exactly one</strong> from the two options
            described above. After the implementation is complete, the
            Institution has to contact <i>eDiplomas</i> in order for the
            required credentials to be given and tests to be performed.
          </p>
        </div>
        <div className="inner section">
          <Heading text="Through View" id="ins_view" level="3" />
          <div className="inner section">
            <p>
              The Database View that is required from the Institution, in order
              for <i>eDiplomas</i> to be able to draw the proper piece of
              information, has to follow a specific{' '}
              <a href="#ins_schema">schema</a> and every{' '}
              <a href="#ins_fieldsview">field</a> has to get particular
              potential values as described below. After the creation of the
              Database View and the degrees' entry in it, the Institution is
              expected to contact <i>eDiplomas</i>, in order for the required
              database credentials to be shared and the needed technical
              settings to be applied.
            </p>
          </div>
          <div className="inner section">
            <Heading text="Schema" id="ins_schema" level="4" />
            <table className="doctable schema">
              <thead>
                <tr>
                  <th>Table Name</th>
                  <th>
                    Data Type <span className="note">(Oracle)</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {viewFields.map((field) => (
                  <tr key={field.name}>
                    <td>
                      <a href={`#ins_${field.name.toLowerCase()}-view`}>
                        {field.name}
                      </a>
                    </td>
                    <td>{field.type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="inner section">
            <Heading text="Fields" id="ins_fieldsview" level="4" />
            <div className="inner section">
              <Heading
                text="CERTIFICATEID"
                id="ins_certificateid-view"
                level="5"
              />
              <span>
                It refers to the unique identifier of the degree that has been
                given to a natural person. It is the corresponding field of the
                "Protocol Number" of a hard-copy degree, thus this field has to
                be unique in an institutional level. This field has to have a
                alphanumeric value and its handling has to reserve all the
                characteristics of unique identifiers (identifier uniqueness,
                identifier non-reassignability, identifier non-revocability,
                identifier persistency). It is desired, that the above
                characteristics are reserved by the information system design on
                every level.
              </span>
            </div>
            <div className="inner section">
              <Heading text="TITLEID" id="ins_titleid-view" level="5" />
              <span>
                It refers to the unique identifier that matches a particular
                degree with its original title, as registered in the Degrees
                system. The combination of the fields <em>certificateID</em>,{' '}
                <em>titleID</em> specifies a degree in a unique way.
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="REGISTRATIONID"
                id="ins_registrationid-view"
                level="5"
              />
              <span>
                It is the Registration Number of the student, that corresponds
                to the degree's owner. This field has to have an alphanumeric
                value and has to reserve all the characteristics of unique
                identifiers (identifier uniqueness, identifier
                non-reassignability, identifier non-revocability, identifier
                persistency). It is desired that the above characteristics are
                reserved by the information system design on every level.
              </span>
            </div>
            <div className="inner section">
              <Heading text="SYSTEMID" id="ins_systemid-view" level="5" />
              <span>
                It is the primary key, of the database in which the Institution
                keeps the graduates' records. This field can potentially be a
                sequence or an opaque identifier, meaning a field that does not
                contain semantic information. Probably, this field is not
                disclosed to end users and is a printer field. This field has to
                have an alphanumeric value and its handling has to reserve all
                the characteristics of the unique identifiers (identifier
                uniqueness, identifier non-reassignability, identifier
                non-revocability, identifier persistency). It is desired than
                the above characteristics are reserved by the information system
                design on every level.
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="GRADEDESCRIPTION"
                id="ins_gradedescription-view"
                level="5"
              />
              <span>
                It contains the description of a particular degree's grade,
                according to the specifications of the Institution or department
                in charge.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Very Good</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="GRADEVALUE" id="ins_gradevalue-view" level="5" />
              <span>
                It contains the grade of a particular degree in a numeric
                representation, normalized to the range 0 to 1.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">0.811</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="DATEISSUED" id="ins_dateissued-view" level="5" />
              <span>
                It contains the date on which the degree was received, meaning
                the graduation ceremony date in which this degree was awarded.
                It is entered by following a basic date format (calendar date,
                basic format) according to the{' '}
                <a
                  href="http://www.iso.org/iso/iso8601)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>{' '}
                specification. The date contains the year, the month and the day
                in the form <span className="field-format">YYYYMMDD</span>.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="VALIDFROM" id="ins_validfrom-view" level="5" />
              <span>
                It contains the date on which the student was declared the owner
                of the particular degree. It is entered by following a basic
                date format (calendar, basic format) according to the{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>{' '}
                specification. The date contains the year, the month and the day
                in the form <span className="field-format">YYYYMMDD</span>.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="STATUS" id="ins_status-view" level="5" />
              <span>
                It contains information regarding the status of the degree. It
                can contain either the special value{' '}
                <span className="field-value">valid</span> , which means that
                the particular degree is still valid, or the special value{' '}
                <span className="field-value">revoked</span> which means that
                the particular degree is no longer valid.
              </span>
            </div>
            <div className="inner section">
              <Heading text="STATUSDATE" id="ins_statusdate-view" level="5" />
              <span>
                It refers to the date since the degree's state is the one that
                is registered in the <em>status</em> field. It basically is the
                date of the last modification made on the degree. It is entered
                by following a basic date format (calendar date, basic format)
                according to the{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>{' '}
                specification. The date contains the year, the month and the day
                in the form <span className="field-format">YYYYMMDD</span>.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="SSN" id="ins_ssn-view" level="5" />
              <span>
                It contains the value of the Social Security Number (SSN) of the
                degree's owner.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">31120012345</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="SSNCOUNTRY" id="ins_ssncountry-view" level="5" />
              <span>
                It is the issuing country of the <em>SSN</em> . It is entered in
                the 2-character encoding of the{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>{' '}
                specification.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="TIN" id="ins_tin-view" level="5" />
              <span>
                It contains the value of the Taxpayer Identification Number
                (TIN).
                <br />
                <span className="field-example">
                  Example: <span className="field-value">123456789</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="TINCOUNTRY" id="ins_tincountry" level="5" />
              <span>
                It is the issuing country of the <em>TIN</em>. It is entered in
                the 2-character encoding of the{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>{' '}
                specification.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="FIRSTNAMEEL" id="ins_firstnameel-view" level="5" />
              <span>
                The degree owner's first name written using the greek alphabet.
                In the case that the first name of the natural person cannot be
                translated into this alphabet, the field will receive the
                special value <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Example: <span className="field-value">Νικόλαος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="FIRSTNAMEEN" id="ins_firstnameen-view" level="5" />
              <span>
                The degree owner's first name written using the latin alphabet.
                It has to be completed for every natural person.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Nikolaos</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="LASTNAMEEL" id="ins_lastnameel-view" level="5" />
              <span>
                The degree owner's last name written using the greek alphabet.
                In the case that the last name of the natural person cannot be
                translated into this alphabet, the field will receive the serial
                value <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Example: <span className="field-value">Παπαδόπουλος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="LASTNAMEEN" id="ins_lastnameen-view" level="5" />
              <span>
                The degree owner's last name written using the latin alphabet.
                It has to be completed for every natural person.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Παπαδόπουλος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="MIDDLENAMEEL"
                id="ins_middlenameel-view"
                level="5"
              />
              <span>
                The degree owner's middle name written using the greek alphabet.
                In the case that the middle name of the natural person cannot be
                translated into this alphabet, or the person does not have a
                middle name, the field will receive the special value{' '}
                <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Example: <span className="field-value">Ιωάννης</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="MIDDLENAMEEN"
                id="ins_middlenameen-view"
                level="5"
              />
              <span>
                The degree owner's middle name written using the latin alphabet.
                In the case that the person does not have a middle name, the
                field will receive the special value{' '}
                <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Example: <span className="field-value">Ioannis</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="FATHERNAMEEL"
                id="ins_fathernameel-view"
                level="5"
              />
              <span>
                The degree owner's father's first name written using the greek
                alphabet.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Αλέξανδρος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="FATHERNAMEEN"
                id="ins_fathernameen-view"
                level="5"
              />
              <span>
                The degree owner's father's first name written using the latin
                alphabet.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Alexandros</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="MOTHERNAMEEL"
                id="ins_mothernameel-view"
                level="5"
              />
              <span>
                The degree owner's mother's first name written using the greek
                alphabet.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Μαρία</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="MOTHERNAMEEN"
                id="ins_mothernameen-view"
                level="5"
              />
              <span>
                The degree owner's mother's first name written using the latin
                alphabet.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Maria</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="BIRTHDATE" id="ins_birthdate-view" level="5" />
              <span>
                The degree owner's date of birth. It is entered by following a
                basic date format (calendar date, basic format) according to the{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>{' '}
                specification. The date contains the year, the month and the day
                in the form <span className="field-format">YYYYMMDD</span>.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">20001231</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="BIRTHYEAR" id="ins_birthyear-view" level="5" />
              <span>
                The degree owner's year of birth in the form{' '}
                <span className="field-format">ΥΥΥΥ</span>.<br />
                <span className="field-example">
                  Example: <span className="field-value">1995</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="GENDER" id="ins_gender-view" level="5" />
              <span>
                It refers to the gender of the member (male, female). The basic
                values of this field follow the{' '}
                <a
                  href="http://en.wikipedia.org/wiki/ISO_5218"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO/IEC 5218:2004
                </a>{' '}
                specification.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">1</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="CITIZENSHIP" id="ins_citizenship-view" level="5" />
              <span>
                The natural person's citizenship. It is entered in the
                2-character encoding of the{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>{' '}
                specification.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="inner section">
          <Heading text="Through API" id="ins_API" level="3" />
          <div className="inner section">
            <p>
              The API that is required to be implemented by the Institution, in
              order for <i>eDiplomas</i> to be able to draw the proper piece of
              information, has to have specific fields and response structure
              which are described below. The way the responding Web Service is
              implemented does not matter to <i>eDiplomas</i> as long as it
              responds according to the way described. The API can be protected
              through whitelisting the IPs belonging to <i>eDiplomas</i> or/and
              through HTTP Basic Authentication. After the API's implementation,
              the Institution has to contact <i>eDiplomas</i> in order for the
              proper settings to be applied.
            </p>
          </div>
          <div className="inner section">
            <Heading text="Fields" id="ins_fieldsapi" level="4" />
            <div className="inner section">
              <Heading
                text="certificateID"
                id="ins_certificateid-api"
                level="5"
              />
              <span>
                It refers to the unique identifier of the degree that has been
                given to a natural person. It is the corresponding field of the
                "Protocol Number" of a hard-copy degree, thus this field has to
                be unique in an institutional level. This field has to have a
                alphanumeric value and its handling has to reserve all the
                characteristics of unique identifiers (identifier uniqueness,
                identifier non-reassignability, identifier non-revocability,
                identifier persistency). It is desired, that the above
                characteristics are reserved by the information system design on
                every level.
              </span>
            </div>
            <div className="inner section">
              <Heading text="titleID" id="ins_titleid-api" level="5" />
              <span>
                It refers to the unique identifier that matches a particular
                degree with its original title, as registered in the Degrees
                system. The combination of the fields <em>certificateID</em>,{' '}
                <em>titleID</em> specifies a degree in a unique way.
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="registrationID"
                id="ins_registrationid-api"
                level="5"
              />
              <span>
                It is the Registration Number of the student, that corresponds
                to the degree's owner. This field has to have an alphanumeric
                value and has to reserve all the characteristics of unique
                identifiers (identifier uniqueness, identifier
                non-reassignability, identifier non-revocability, identifier
                persistency). It is desired that the above characteristics are
                reserved by the information system design on every level.
              </span>
            </div>
            <div className="inner section">
              <Heading text="systemID" id="ins_systemid-api" level="5" />
              <span>
                It is the primary key, of the database in which the Institution
                keeps the graduates' records. This field can potentially be a
                sequence or an opaque identifier, meaning a field that does not
                contain semantic information. Probably, this field is not
                disclosed to end users and is a printer field. This field has to
                have an alphanumeric value and its handling has to reserve all
                the characteristics of the unique identifiers (identifier
                uniqueness, identifier non-reassignability, identifier
                non-revocability, identifier persistency). It is desired than
                the above characteristics are reserved by the information system
                design on every level.
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="gradeDescription"
                id="ins_gradedescription-api"
                level="5"
              />
              <span>
                It contains the description of a particular degree's grade,
                according to the specifications of the Institution or department
                in charge.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Very Good</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="gradeValue" id="ins_gradevalue-api" level="5" />
              <span>
                It contains the grade of a particular degree in a numeric
                representation, normalized to the range 0 to 1.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">0.811</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="dateIssued" id="ins_dateissued-api" level="5" />
              <span>
                It contains the date on which the degree was received, meaning
                the graduation ceremony date in which this degree was awarded.
                It is entered by following a basic date format (calendar date,
                basic format) according to the{' '}
                <a
                  href="http://www.iso.org/iso/iso8601)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>{' '}
                specification. The date contains the year, the month and the day
                in the form <span className="field-format">YYYYMMDD</span>.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="validFrom" id="ins_validfrom-api" level="5" />
              <span>
                It contains the date on which the student was declared the owner
                of the particular degree. It is entered by following a basic
                date format (calendar, basic format) according to the{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>{' '}
                specification. The date contains the year, the month and the day
                in the form <span className="field-format">YYYYMMDD</span>.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="status" id="ins_status-api" level="5" />
              <span>
                It contains information regarding the status of the degree. It
                can contain either the special value{' '}
                <span className="field-value">valid</span> , which means that
                the particular degree is still valid, or the special value{' '}
                <span className="field-value">revoked</span> which means that
                the particular degree is no longer valid.
              </span>
            </div>
            <div className="inner section">
              <Heading text="statusDate" id="ins_statusdate-api" level="5" />
              <span>
                It refers to the date since the degree's state is the one that
                is registered in the <em>status</em> field. It basically is the
                date of the last modification made on the degree. It is entered
                by following a basic date format (calendar date, basic format)
                according to the{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>{' '}
                specification. The date contains the year, the month and the day
                in the form <span className="field-format">YYYYMMDD</span>.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="SSN" id="ins_ssn-api" level="5" />
              <span>
                It contains the value of the Social Security Number (SSN) of the
                degree's owner.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">31120012345</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="SSNCOUNTRY" id="ins_ssncountry-api" level="5" />
              <span>
                It is the issuing country of the <em>SSN</em> . It is entered in
                the 2-character encoding of the{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>{' '}
                specification.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="TIN" id="ins_tin-api" level="5" />
              <span>
                It contains the value of the Taxpayer Identification Number
                (TIN).
                <br />
                <span className="field-example">
                  Example: <span className="field-value">123456789</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="TINCOUNTRY" id="ins_tincountry-api" level="5" />
              <span>
                It is the issuing country of the <em>TIN</em>. It is entered in
                the 2-character encoding of the{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>{' '}
                specification.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="firstNameEl" id="ins_firstnameel-api" level="5" />
              <span>
                The degree owner's first name written using the greek alphabet.
                In the case that the first name of the natural person cannot be
                translated into this alphabet, the field will receive the
                special value <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Example: <span className="field-value">Νικόλαος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="firstNameEn" id="ins_firstnameen-api" level="5" />
              <span>
                The degree owner's first name written using the latin alphabet.
                It has to be completed for every natural person.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Nikolaos</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="lastNameEl" id="ins_lastnameel-api" level="5" />
              <span>
                The degree owner's last name written using the greek alphabet.
                In the case that the last name of the natural person cannot be
                translated into this alphabet, the field will receive the
                special value <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Example: <span className="field-value">Παπαδόπουλος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="lastNameEn" id="ins_lastnameen-api" level="5" />
              <span>
                The degree owner's last name written using the latin alphabet.
                It has to be completed for every natural person.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Παπαδόπουλος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="middleNameEl"
                id="ins_middlenameel-api"
                level="5"
              />
              <span>
                The degree owner's middle name written using the greek alphabet.
                In the case that the middle name of the natural person cannot be
                translated into this alphabet, or the person does not have a
                middle name, the field will receive the special value{' '}
                <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Example: <span className="field-value">Ιωάννης</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="middleNameEn"
                id="ins_middlenameen-api"
                level="5"
              />
              <span>
                The degree owner's middle name written using the latin alphabet.
                In the case that the person does not have a middle name, the
                field will receive the special value{' '}
                <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Example: <span className="field-value">Ioannis</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="fatherNameEl"
                id="ins_fathernameel-api"
                level="5"
              />
              <span>
                The degree owner's father's first name written using the greek
                alphabet.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Αλέξανδρος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="fatherNameEn"
                id="ins_fathernameen-api"
                level="5"
              />
              <span>
                The degree owner's father's first name written using the latin
                alphabet.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Alexandros</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="motherNameEl"
                id="ins_mothernameel-api"
                level="5"
              />
              <span>
                The degree owner's mother's first name written using the greek
                alphabet.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Μαρία</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="motherNameEn"
                id="ins_mothernameen-api"
                level="5"
              />
              <span>
                The degree owner's mother's first name written using the latin
                alphabet.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">Maria</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="birthDate" id="ins_birthdate-api" level="5" />
              <span>
                The degree owner's date of birth. It is entered by following a
                basic date format (calendar date, basic format) according to the{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>{' '}
                specification. The date contains the year, the month and the day
                in the form <span className="field-format">YYYYMMDD</span>.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">20001231</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="birthYear" id="ins_birthyear-api" level="5" />
              <span>
                The degree owner's year of birth in the form{' '}
                <span className="field-format">ΥΥΥΥ</span>.<br />
                <span className="field-example">
                  Example: <span className="field-value">1995</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="gender" id="ins_gender-api" level="5" />
              <span>
                It refers to the gender of the member (male, female). The basic
                values of this field follow the{' '}
                <a
                  href="http://en.wikipedia.org/wiki/ISO_5218"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO/IEC 5218:2004
                </a>{' '}
                specification.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">1</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="citizenship" id="ins_citizenship-api" level="5" />
              <span>
                The natural person's citizenship. It is entered in the
                2-character encoding of the{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>{' '}
                specification.
                <br />
                <span className="field-example">
                  Example: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="error" id="ins_error-api" level="5" />
              <span>
                It contains a short description of the error, in the case one
                occurred during the use of the service or the special value{' '}
                <span className="field-value">null</span> otherwise.
              </span>
            </div>
            <div className="inner section">
              <Heading text="success" id="ins_success-api" level="5" />
              <span>
                It contains the value <span className="field-value">true</span>{' '}
                in the case that no errors have occurred during the use of the
                service or the value <span className="field-value">false</span>{' '}
                otherwise.
              </span>
            </div>
          </div>
          <div className="inner section">
            <Heading text="Examples" id="ins_examplesapi" level="4" />
            <span>
              <div className="inner section">
                <Heading
                  text="Request example with one result"
                  id="ins_one-result-example-api"
                  level="5"
                />
                <p>
                  The service handles multiple search fields at the same time.
                  Therefore, it demands one of the results to have exactly the
                  same values with those defined in the search fields.
                </p>
                <CodeExample
                  header="Request:"
                  data={SingleAPIExample.query}
                  indented={false}
                />
                <CodeExample
                  header="Response:"
                  data={SingleAPIExample.results}
                  indented
                />
              </div>
              <div className="inner section">
                <Heading
                  text="Request example with multiple results"
                  id="ins_multiple-results-example-api"
                  level="5"
                />
                <span>
                  <p>
                    In the case in which the values in the completed request
                    fields match more than one user, the service randomly
                    returns up to 10 of these users contained in a JSON array.
                  </p>
                  <p>
                    The service also supports advanced search according to the
                    [RFC2254] specification. In paragraph 4 of the [RFC2254]
                    specification, a list containing the grammar followed by the
                    symbols that the advanced search supports can be found, In
                    the example below, we search for all the degrees that were
                    received during the year 2002.
                  </p>
                  <CodeExample
                    header="Request:"
                    data={MultipleAPIExample.query}
                    indented={false}
                  />
                  <CodeExample
                    header="Response:"
                    data={MultipleAPIExample.results}
                    indented
                    multipleItems
                  />
                </span>
              </div>
              <div className="inner section">
                <Heading
                  text="Request example with no results"
                  id="ins_no-results-example-api"
                  level="5"
                />
                <CodeExample
                  header="Request:"
                  data={NoAPIExample.query}
                  indented={false}
                />
                <CodeExample
                  header="Response:"
                  data={NoAPIExample.results}
                  indented={false}
                />
              </div>
              <div className="inner section">
                <Heading
                  text="Error Reporting"
                  id="ins_error-report-api"
                  level="5"
                />
                <p>
                  Examples of potential error cases, along with a more detailed
                  description for each error, are presented below.
                </p>
                <CodeExample data={InvalidReqExample.results} />
                <p>
                  Appears in the case in which the JSON contains some syntax
                  error.
                </p>
                <CodeExample data={InvalidAuthTokenExample.results} />
                <p>
                  Appears in the case in which the security key is not value.
                </p>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="section">
        <h2 id="ins_support" className="header nav-anchor">
          Request Support
        </h2>
        <p>
          If the Institution chooses it, the ability, can be given to the owner
          of degrees, to submit a personalized request, in order for their
          degree to become available through eDiplomas, if it has not been
          already included. This request is registered to the corresponding
          Institution - Department for resolving and is accompanied by data that
          make the search for the degree easier for the secretariat staff.
        </p>
        <p>
          For the targeted assignment of these requests to the staff members in
          charge, and for, ultimately, providing better service to the citizens,
          the platform has to have knowledge over which Institution members are
          able to resolve which request categories. The documentation and
          monitoring of the citizens' requests takes place through the eDiplomas
          Support Service, in which access is granted only to the authorized
          Institution staff, by them using their institutional accounts.
        </p>
        <div className="inner section">
          <Heading
            text="Access to eDiplomas Support"
            id="ins_suspport-access"
            level="3"
          />
          <p>
            On a technical level, the authorization for access to the eDiplomas
            Support service, is determined through the Single-Sign-On nodes of
            infrastructure during the user's login to the service phase. Each
            Institution controls their users' access to the eDiplomas Support
            service through the edupersonEntitlement attribute. Specifically,
            the edupersonEntitlement field, has to contain values with the
            following format:{' '}
          </p>
          <div className="example code">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:{'<'}maintainerID>:{'<'}
            Role>:{'<'}ISCEDLevel>
          </div>
          <p>
            <strong>maintainerID</strong>: It regards the account's access to
            citizens' requests, for degrees handled by the departments with this
            id. The format follows this of the National Exams for HEIs admission
            (mineduID). This field is mandatory and has to hold exactly one
            value.
          </p>
          <ul>
            <li>
              For access to citizens' requests that regard degrees of curricula
              which are no longer active, the code of the active department
              which has taken over the maintenance of the older inactive
              curriculum's degrees archive is used. This means that the id of
              the department that originally awarded the title is no longer
              used, and the code of the department that is currently in charge
              of issuing the degrees is used instead.
            </li>
            <li>
              The special value ‘0’ (zero) marks the user's access to the part
              of request regarding the Institution. In this case, the field
              ISCEDLevel has to not have a value. When the value ‘0’ of the
              maintainerID attribute is combined with any value of the
              ISCEDLevel field, the attribute edupersonEntitlement fields for
              this user are ignored.
            </li>
            <li>
              If an account has to have access to requests that regard more than
              one department, this can be achieved by multiple
              edupersonEntitlement attribute values. Nevertheless, each
              edupersonEntitlement value has to hold exactly one value in the
              maintainerID field and there has to be a unique reference to a
              single maintainerID among all the edupersonEntitlement values. If
              more than one values with a reference to a particular maintainerID
              are detected, all the values of the edupersonEntitlement attribute
              for the user are ignored.
            </li>
            <li>
              The registration for a maintainerID provides access to requests
              about degrees of all levels of the department, including requests
              regarding master degrees, as long as the supervising bachelor
              department matches with or is under the maintenance of the
              department with this id.
            </li>
          </ul>
          <br />
          <p>
            <strong>Role</strong>: determines the account's privileges,
            regarding the requests referring to degrees of the department with a
            specific maintainerID. For the completion of the ‘role’ attribute, a
            specific value field is used, as described below. This field is
            mandatory and it has to receive exactly one value.
          </p>
          <table className="doctable">
            <thead>
              <tr>
                <th>Role</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>view</td>
                <td>
                  Allows the account to see the requests which correspond to a
                  specific maintainer ID.
                </td>
              </tr>
              <tr>
                <td>assign</td>
                <td>
                  Contains the views' privileges, as well as the right to assign
                  requests that regard a particular maintainerID, to another
                  account or group.
                </td>
              </tr>
              <tr>
                <td>resolve</td>
                <td>
                  Contains the view's privileges, as well as the right to
                  resolve requests that regard a specific maintaineID.
                </td>
              </tr>
              <tr>
                <td>full</td>
                <td>See all the above privileges.</td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <strong>ISCEDLevel</strong>: Activates the automatic assignment
            option for degrees of this educational level, that regard the
            specific maintainerID, to the user's account (MyTickets). This field
            follows the single character encoding according to the ISCED 2011
            specification, as noted below:
          </p>
          <table className="doctable">
            <thead>
              <tr>
                <th>ISCED Level (2011)</th>
                <th>Education Level</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>6</td>
                <td>Bachelor Studies Degree</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Master Studies Degree</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Doctoral Studies Degree</td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>
              The ISCEDLevel field, does not affect the account's access
              privileges, which are determined exclusively by the maintainerID
              and role fields.
            </li>
            <li>
              When the ISCEDLevel field does not have a value, the request
              assignment to the account does not happen automatically. Any user
              with the role ‘assign’ or ‘full’ in a request, can assign it to
              the specific account which will be able to resolve it if it has
              the role ‘resolve’ or ‘full’.
            </li>
            <li>
              The ISCEDLevel field, can get more than one value, separated with
              “,” (comma) e.g. “6,8”.
            </li>
          </ul>
        </div>
        <div className="inner section">
          <Heading
            text="eduPersonEntitlement Examples"
            id="ins_support-examples"
            level="3"
          />
          <p>
            Example 1: Account which can resolve the XXXXXXX (minedu = 512)
            department's requests. The requests' regarding the bachelor studies
            curriculum, assignment to this account happens automatically.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:resolve:6
          </div>
          <p>
            Example 2: Account which can supervise all the Institution's
            departments' requests.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:0:view:
          </div>
          <p>
            Example 3: Account which can assign and resolve the XXXXXXX
            (mineduID = 512) department's requests. The requests' regarding the
            master and doctoral studies curricula, assignment to this account
            happens automatically.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:full:6,8
          </div>
          <p>
            Example 4: Account which can resolve the XXXXXXX (mineduID = 512)
            and YYYYYYY (mineduID = 423) departments' requests. The requests',
            regarding the XXXXXXX department's master and doctoral studies and
            the YYYYYYY department's doctoral studies curricula, assignment to
            this account happens automatically.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:resolve:6,8
            <br />
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:423:resolve:8
          </div>
          <p>
            Example 5: Account which can supervise the resolving of all the
            Institution's departments' request and resolve requests regarding
            XXXXXXX department degrees The requests, regarding the doctoral
            studies curriculum, assignment to this account happens
            automatically.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:0:view
            <br />
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:full:8
          </div>
          <p>
            Example 6: Account which can resolve the XXXX (mineduID = 512)
            departments' requests. The requests' assignment to this account does{' '}
            <strong>not</strong> happen automatically.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:resolve:
          </div>
        </div>
        <div className="inner section">
          <Heading
            text="Examples of Invalid Values"
            id="ins_support-invalid"
            level="3"
          />
          <div>
            <div className="code example">
              urn:mace:gunet.gr:ediplomas.gr:helpdesk:0:full:6
            </div>
            (maintainerID=0 and filled ISCEDLevel)
            <br />
            <div className="code example">
              urn:mace:gunet.gr:ediplomas.gr:helpdesk:512::6
            </div>
            (empty value in the role field)
            <br />
            <div className="code example">
              urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:resolve:67
            </div>
            (not valid value int the ISCEDLevel field)
            <br />
            <div className="code example">
              urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:full:6
              <br />
              urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:resolve:7
            </div>
            (multiple reference to the same maintainerID)
          </div>
        </div>
      </div>
    </>
  );
};
