import React from 'react';
import { Announcement } from './components/Announcement';

const Settings = {
  announcement: {
    enabled: false,
    // backgroundColor: '#ececec',
    backgroundColor: '#e3f2ff',
    el: {
      header: 'Παρουσίαση eDiplomas - Σενάρια Χρήσης',
      text: 'Διαδραστική παρουσίαση των λειτουργιών της πλατφόρμας μέσω βιντεοσκοπημένων σεναρίων.'
    },
    en: {
      header: 'eDiplomas Presentation - Use Cases</a>',
      text: 'Interactive presentation of various eDiplomas features via prerecorded scenarios.'
    },
    url: 'http://open.gunet.gr/ediplomas-presentation/',
    content: function (polyglot) {
      let text = this[polyglot.currentLocale].text;
      let header = this[polyglot.currentLocale].header;
      if (this.enabled)
        return (
          <Announcement
            backgroundColor={this.backgroundColor}
            header={header}
            text={text}
            url={this.url}
          />
        );
      else return;
    }
  }
};

export default Settings;
