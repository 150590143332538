import React, { Component } from 'react';
import * as queryString from 'query-string';
import ExternalRedirect from '../lib/ExternalRedirect';
import MenuLink from '../components/MenuLink';
import { scrollTop } from '../lib/Util';

class Terms extends Component {
  state = {
    next: false,
    token: '',
    nextUrl: '',
    gsisURI: ''
  };

  handleLogin = async (e) => {
    e.preventDefault();
    let login = '/api/client/login';
    if (this.state.token) {
      login = `/api/client/login/?token=${this.state.token}`;
    } else if (this.state.nextUrl) {
      login = `/api/client/login/?next=${this.state.nextUrl}`;
    }
    const res = await fetch(login, {
      credentials: 'include'
    });
    const resJson = await res.json();
    this.setState({
      gsisURI: resJson.gsisURI,
      next: true
    });
  };

  componentDidMount() {
    scrollTop();
    const query = queryString.parse(this.props.query);
    this.setState({ token: query.token, nextUrl: query.next });
  }

  render = () => (
    <div className="Terms">
      <MenuLink polyglot={this.props.polyglot} />
      <div className="step-description">
        <h2>{this.props.polyglot.t('Organisation.taxis.taxisLogin')}</h2>
        <p>{this.props.polyglot.t('Organisation.taxis.accessGranted')}</p>
        <ul>
          <li>
            {this.props.polyglot.t('Organisation.taxis.registeredEntity')}
          </li>
          <li>{this.props.polyglot.t('Organisation.taxis.taxisUsers')}</li>
        </ul>
        <p>{this.props.polyglot.t('Organisation.taxis.accessComment')}</p>
        <div className="center">
          <form onSubmit={this.handleLogin}>
            <button className="big ui fancy button">
              {this.props.polyglot.t('Organisation.taxis.doLogin')}
            </button>
          </form>
        </div>
      </div>
      {this.state.next && this.state.gsisURI.length > 0 && (
        <ExternalRedirect
          to={this.state.gsisURI}
          // language={this.props.polyglot.currentLocale}
        />
      )}
    </div>
  );
}

export default Terms;
