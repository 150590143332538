import React from 'react';
import { AuthConsumer } from './AuthContext';

const InspectAllowed = (props) => (
  <AuthConsumer>
    {({ canInspect, clients }) => {
      if (canInspect) {
        {
          /* more than one clients that can inspect are connected */
        }
        if (
          clients.filter((client) => client.can_inspect === true).length > 1
        ) {
          return props.multiClient();
        } else {
          return props.onYes();
        }
      }
      return props.onNo();
    }}
  </AuthConsumer>
);

export default InspectAllowed;
