import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Message } from '../components/Message';
import MenuLink from '../components/MenuLink';

class LoginError extends Component {
  render = () => (
    <div className="Terms">
      <MenuLink polyglot={this.props.polyglot} />
      <div className="step-description">
        <Message
          error
          header={this.props.polyglot.t('Organisation.loginError.loginFailed')}
        >
          <p>
            {this.props.polyglot.t(
              'Organisation.loginError.taxisSuccessFulButTin'
            )}{' '}
            <strong>
              {this.props.polyglot.t('Organisation.loginError.notAuthorized')}
            </strong>{' '}
            {this.props.polyglot.t('Organisation.loginError.forEdiplomasUse')}.
          </p>
        </Message>
      </div>
      <Link className="big ui fancy button" to="/">
        {this.props.polyglot.t('Organisation.loginError.backHome')}
      </Link>
    </div>
  );
}

export default LoginError;
