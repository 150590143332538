import React, { Component, createRef } from 'react';
import MenuLink from '../components/MenuLink';
import './TokenEntry.css';
import Authentication from '../lib/Authentication';
import { generateFilename } from '../lib/Scopes';
import {
  scrollTop,
  constructSignature,
  constructSignedAudit
} from '../lib/Util';
import { Form, FormGroup, TextInput } from '../components/Form';
import { Message } from '../components/Message';
import { Spinner } from '../components/Spinner';
import { TokenResult } from '../components/TokenResult';
import moment from 'moment';

class TokenEntry extends Component {
  constructor(props) {
    super(props);
    this.signatureInputRef = createRef();
    this.state = {
      loading: 0,
      results: [],
      token: '',
      invalidToken: false,
      invalidOrExpiredToken: false,
      generalError: false,
      selectedDiploma: null,
      selectedFilename: '',
      copied: false,
      popupOpen: true,
      active: null,
      prefilled: false,
      expirationDate: null
    };
  }
  componentDidMount = async () => {
    scrollTop();
  };

  UNSAFE_componentWillMount = async () => {
    let token = '';
    if (this.props.token) {
      token = this.props.token;
    }

    if (token) {
      this.props.history.push(`/organisation/${token}`);
      this.setState({ token, prefilled: true }, this.search);
    } else {
      this.setState({ token: '' });
    }
  };

  search = async (e = null) => {
    if (e) e.preventDefault();
    this.setState((prevState) => ({ loading: prevState.loading + 1 }));
    const data = new FormData();
    data.append('token', this.state.token);
    let res;
    let results = [];
    try {
      res = await fetch('/api/client/diplomas/', {
        method: 'POST',
        body: data,
        credentials: 'include'
      });
      const resJson = await res.json();
      if (resJson.error) {
        switch (resJson.errorCode) {
          case '1':
            this.setState({
              results,
              invalidOrExpiredToken: true,
              generalError: false
            });
            break;
          default:
            this.setState({
              results,
              invalidOrExpiredToken: false,
              generalError: true
            });
        }
      } else {
        // No error
        results = resJson.data;
        const expirationDate = resJson.expires_at;
        results = results.map((d) => ({ ...d, open: false }));
        this.props.history.push(`/organisation/${this.state.token}`);
        this.setState({ results, expirationDate });
        this.setState({
          invalidToken: false,
          invalidOrExpiredToken: false,
          generalError: false,
          prefilled: true
        });
      }
    } catch (e) {
      this.setState({ results, generalError: true });
    }

    this.setState((prevState) => ({ loading: prevState.loading - 1 }));
  };

  handleChange = (e) => {
    const { target } = e;
    const splitValue = target.value.split('/'); // If user inputs full url, split the token
    this.setState({
      token: splitValue[splitValue.length - 1]
    });
  };

  handleFocusSignature = () => {
    this.signatureInputRef.current && this.signatureInputRef.current.select();
  };
  handleCopySignature = () => {
    this.signatureInputRef.current.select();
    this.setState({ copied: 'true' });
    this.copyTimeout = setTimeout(() => {
      this.setState({ copied: 'false' });
    }, 1800);
  };

  selectDiploma = (index) => {
    console.log(`INDEX: ${index}`);
    if (this.state.active === index) {
      this.setState({
        active: null
      });
      return;
    }
    let selectedDiploma = this.state.results[index];
    const selectedFilename = generateFilename(selectedDiploma.diploma);
    selectedDiploma = {
      ...selectedDiploma,
      signature: constructSignature(
        selectedDiploma.signature,
        selectedDiploma.payload
      ),
      signedAuditId: constructSignedAudit(
        selectedDiploma.signedAuditId,
        selectedDiploma.auditId,
        selectedDiploma.aud
      )
    };
    this.setState({
      selectedDiploma,
      selectedFilename,
      active: index
    });
  };

  render = () => {
    return (
      <div>
        <Authentication token={this.state.token} />
        <MenuLink polyglot={this.props.polyglot} />
        <div className="row TokenEntry">
          <div className="description">
            <h3>
              {this.props.polyglot.t(
                `Organisation.tokenEntry.diplomasByAuthorization`
              )}
            </h3>
          </div>
          {this.state.results.length === 0 && (
            <Form className="Token">
              <p className="label">
                {this.props.polyglot.t('Organisation.tokenEntry.searchHeader')}
              </p>
              <FormGroup>
                <TextInput
                  id="token"
                  loading={this.state.loading !== 0}
                  value={this.state.token}
                  onChange={this.handleChange}
                  label={this.props.polyglot.t('Organisation.tokenEntry.label')}
                />
                <span className="hint">
                  <span className="fas fa-question-circle" />{' '}
                  {this.props.polyglot.t('Organisation.tokenEntry.hint')}
                </span>
                <div className="search-button">
                  <span>
                    <button onClick={this.search} className="ui fancy">
                      {this.props.polyglot.t(
                        'Organisation.tokenEntry.searchButton'
                      )}
                    </button>
                  </span>
                  {this.state.loading !== 0 && this.state.token !== '' && (
                    <Spinner cx={20} cy={20} r={11} />
                  )}
                </div>
              </FormGroup>
            </Form>
          )}
          <div className="errors">
            {this.state.invalidOrExpiredToken && (
              <Message
                warning
                header={this.props.polyglot.t(
                  'Organisation.tokenEntry.errorMessageHeader'
                )}
              >
                <p>
                  {this.props.polyglot.t(
                    'Organisation.tokenEntry.errorMessageInvalidOrExpired'
                  )}
                </p>
              </Message>
            )}
            {this.state.generalError && (
              <Message
                warning
                header={this.props.polyglot.t(
                  'Organisation.tokenEntry.errorMessageHeader'
                )}
              >
                <p>
                  {this.props.polyglot.t(
                    'Organisation.tokenEntry.errorMessageGeneral'
                  )}
                </p>
              </Message>
            )}
          </div>
        </div>
        <div className="row Results">
          {this.state.results.length !== 0 && (
            <>
              <div>
                <p>
                  {this.props.polyglot.t(
                    'Organisation.tokenEntry.downloadUntil'
                  )}
                  :{' '}
                  {moment(new Date(this.state.expirationDate * 1000)).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}{' '}
                  <br />
                  <span
                    style={{
                      'font-size': '15px',
                      color: 'rgb(53, 53, 53)',
                      wordBreak: 'break-all'
                    }}
                  >
                    ({this.props.polyglot.t('Organisation.tokenEntry.codeNote')}
                    : {this.state.token})
                  </span>
                </p>
                {/* <h2>{this.props.polyglot.t('Organisation.degrees')}</h2> */}
                {this.state.results
                  .map((d) => d.diploma)
                  .map((diploma, index) => (
                    <TokenResult
                      key={index}
                      index={index}
                      polyglot={this.props.polyglot}
                      diploma={diploma}
                      selectDiploma={this.selectDiploma}
                      active={this.state.active}
                      selectedDiploma={this.state.selectedDiploma}
                      selectedFilename={this.state.selectedFilename}
                      handleSignature={this.handleSignature}
                      handleFocusSignature={this.handleFocusSignature}
                      handleCopySignature={this.handleCopySignature}
                      signatureInput={this.signatureInput}
                      signatureInputRef={this.signatureInputRef}
                      copied={this.state.copied}
                    />
                  ))}
              </div>
              <p style={{ 'font-size': '15px', color: 'rgb(53, 53, 53)' }}>
                <br />
                <a href="/organisation">
                  {this.props.polyglot.t('Organisation.tokenEntry.newSearch')}
                </a>
              </p>
            </>
          )}
        </div>
      </div>
    );
  };
}

export default TokenEntry;
