import React from 'react';
import Footer from '../Footer';
import './News.css';

import { Helmet } from 'react-helmet';

const EmrexArticleEn = (props) => (
  <div className="NewsPage">
    <div className="content">
      <div className="breadcrumbs">
        <a className="home" href="/">
          <span className="fas fa-home"> </span>{' '}
          {props.polyglot.t(`newsBreadcrumbs.home`)}
        </a>{' '}
        |
        <span className="paths">
          {props.polyglot.t(`newsBreadcrumbs.news`)} >{' '}
          <a href="/news/emrex">eDiplomas joins EMREX</a>
        </span>
      </div>
      <h1 className="header">eDiplomas joins EMREX</h1>
      <h3>Share your diploma with thousands of institutions participating in the EMREX network</h3>
      <Helmet>
        <title>eDiplomas joins EMREX</title>
      </Helmet>
      <p>
        GUnet, as a member of the European <a href='https://emrex.eu/' target='_blank' rel="noreferrer">EMREX</a> completed the interconnection of the  <a href='https://ediplomas.gr/' target='_blank' rel="noreferrer">eDiplomas</a> platform with the EMREX network, thus providing Greek university graduates with access to cross-border degree certification services.
      </p>
      <p>
        The EMREX network involves over 1.600 organisations from 10 European countries. A graduate of a Greek university, starting from a relevant application (e.g.student directory) of any EMREX-affiliated university abroad, can select the Greek EMREX hub and through this hub can easily and reliably make the certificates of qualifications available through the eDiplomas service.
      </p>
      <p>
        In addition to the digital format of the diploma certificate that has been configured for machine reading and verification by applications (EMREX clients), the graduate and the client application will also receive the diploma certificate in PDF format, which will bear the approved digital seal (Qualified PAdES LTA) used by eDiplomas and has the maximum legal validity in relation to all types of digital signatures.  According to the European eIDAS Regulation, this digital seal is explicitly recognized as legally equivalent to the traditional seal in all Member States of the European Union.      </p>
    </div>
    <Footer polyglot={props.polyglot} />
  </div>
);

export default EmrexArticleEn;
