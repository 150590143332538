import React, { Component } from 'react';
import Footer from '../Footer';
import SideNavbar from '../components/SideNavbar';
import SideNavbarContent from '../components/SideNavbarContent';
import { scrollTop } from '../lib/Util';
import './Documentation.css';

class Documentation extends Component {
  state = {
    navOpen: false,
    openNavCategory: this.props.navSection,
    firstVisible: ''
  };

  handleLanguage = (lang) => {
    this.props.handleLanguage(lang);
  };

  componentDidMount = () => {
    scrollTop();
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  };

  componentWillUnmount = () =>
    window.removeEventListener('resize', this.handleScroll);

  handleScroll = () => {
    const anchors = [...document.querySelectorAll('.nav-anchor')];
    if (!anchors.length) {
      this.setState({ firstVisible: '' });
    }
    const firstVisible = anchors.find((anchor) => {
      const rect = anchor.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        return anchor;
      }
      return '';
    });
    if (
      firstVisible &&
      firstVisible.id &&
      firstVisible.id !== this.state.firstVisible
    ) {
      this.setState({ firstVisible: firstVisible.id });
    }
  };

  render = () => (
    <React.Fragment>
      <div className="Documentation">
        <SideNavbar
          polyglot={this.props.polyglot}
          header={this.props.polyglot.t(`Documentation.navbar.contents`)}
          content={SideNavbarContent}
          openCategory={this.props.navCategory}
          active={this.state.firstVisible}
        />
        <div className="content">
          <div className="main">
            {this.props.children}
            <div
              className={`back-to-top ${
                !this.props.backTopLink ? 'hidden' : ''
              }`}
            >
              <a href="#">
                [{this.props.polyglot.t(`Documentation.backToTop`)}]
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer polyglot={this.props.polyglot} />
    </React.Fragment>
  );
}

export default Documentation;
