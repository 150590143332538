import React from 'react';
import './News.css';

import EBSIVideoEn from './EBSIVideoEn';
import EBSIVideoEl from './EBSIVideoEl';

const EBSIVideo = (props) => {
  if (props.polyglot.currentLocale === 'en') {
    return (
      <EBSIVideoEn
        polyglot={props.polyglot}
        handleLanguage={props.handleLanguage}
      />
    );
  } else {
    return (
      <EBSIVideoEl
        polyglot={props.polyglot}
        handleLanguage={props.handleLanguage}
      />
    );
  }
};

export default EBSIVideo;
