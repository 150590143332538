import moment from 'moment';

const fieldGroups = [
  {
    name: 'Issuer',
    icon: 'university',
    fields: ['issuerInstitution', 'issuerUnit']
  },
  {
    name: 'Template',
    icon: 'graduation-cap',
    fields: ['level', 'title', 'notes']
  },
  {
    name: 'Identifiers',
    icon: 'user-graduate',
    fields: ['lastNameEl', 'firstNameEl', 'birthDate']
  },
  {
    name: 'Grade',
    icon: 'award',
    fields: ['gradeDescription', 'gradeValue']
  },
  {
    name: 'Dates',
    icon: 'calendar-alt',
    fields: ['dateIssued', 'validFrom']
  }
];

const dateFields = ['birthDate', 'dateIssued', 'validFrom'];
const gradeFields = ['gradeValue'];

/**
 * Checks if field belongs in any special categories (e.g dateFields)
 * and returns the value in the appropriate format
 * @param {string} field - Field name
 * @param {string} value - Value of that field
 */
const formatField = (field, value) => {
  if (!value) {
    return '-';
  }
  if (dateFields.includes(field)) {
    const year = value.substr(0, 4);
    const month = value.substr(4, 2);
    const day = value.substr(6, 2);
    return `${day}/${month}/${year}`;
  }
  if (gradeFields.includes(field)) {
    return (value * 10).toFixed(2);
  }
  return value;
};

const formatDiploma = (diploma) =>
  fieldGroups.map((group) => ({
    ...group,
    items: group.fields
      .filter((field) => Object.keys(diploma).includes(field))
      .map((field) => ({
        name: field,
        value: formatField(field, diploma[field])
      }))
  }));

const utcDatetime = (timestamp) =>
  timestamp
    ? `${moment.utc(timestamp, 'X').format('DD/MM/YYYY HH:mm:ss')} (UTC)`
    : '-';

const generateFilename = (diploma) => {
  let lastname = diploma.lastNameEl;
  let firstname = diploma.firstNameEl;
  let filename = `eDiplomas_QR_${lastname}_${firstname}${
    diploma.validFrom ? `_${diploma.validFrom}` : ''
  }.pdf`;
  let noSlashFilename = filename.replace(/\//g, '');
  return encodeURIComponent(noSlashFilename);
};

export { formatDiploma, dateFields, utcDatetime, generateFilename };
