import React from 'react';
import CodeExample from '../../components/CodeExample';
import {
  SingleAPIExample,
  MultipleAPIExample,
  NoAPIExample,
  InvalidReqExample,
  InvalidAuthTokenExample
} from '../APIExamples';
import { Heading } from '../Common';
import institutionExample from '../institution_example.svg';
import diplomaExample from '../diploma.svg';

const svgPanZoom = require('svg-pan-zoom');

const viewFields = [
  { name: 'CERTIFICATEID', type: 'VARCHAR' },
  { name: 'TITLEID', type: 'VARCHAR' },
  { name: 'GRADEDESCRIPTION', type: 'VARCHAR' },
  { name: 'GRADEVALUE', type: 'FLOAT' },
  { name: 'DATEISSUED', type: 'VARCHAR' },
  { name: 'VALIDFROM', type: 'VARCHAR' },
  { name: 'STATUS', type: 'VARCHAR' },
  { name: 'STATUSDATE', type: 'VARCHAR' },
  { name: 'SSN', type: 'VARCHAR' },
  { name: 'SSNCOUNTRY', type: 'VARCHAR' },
  { name: 'TIN', type: 'VARCHAR' },
  { name: 'TINCOUNTRY', type: 'VARCHAR' },
  { name: 'FIRSTNAMEEL', type: 'VARCHAR' },
  { name: 'FIRSTNAMEEN', type: 'VARCHAR' },
  { name: 'LASTNAMEEL', type: 'VARCHAR' },
  { name: 'LASTNAMEEN', type: 'VARCHAR' },
  { name: 'MIDDLENAMEEL', type: 'VARCHAR' },
  { name: 'MIDDLEENAMEEN', type: 'VARCHAR' },
  { name: 'FATHERNAMEEL', type: 'VARCHAR' },
  { name: 'FATHERNAMEEN', type: 'VARCHAR' },
  { name: 'MOTHERNAMEEL', type: 'VARCHAR' },
  { name: 'MOTHERNAMEEN', type: 'VARCHAR' },
  { name: 'BIRTHDATE', type: 'VARCHAR' },
  { name: 'BIRTHYEAR', type: 'NUMBER(4,0)' },
  { name: 'GENDER', type: 'NUMBER(1,0)' },
  { name: 'CITIZENSHIP', type: 'VARCHAR' },
  { name: 'REGISTRATIONID', type: 'VARCHAR' }
];

export default () => {
  const loadPanZoom = () => {
    svgPanZoom('#institutionExample', {
      controlIconsEnabled: true,
      fit: true
    });
  };

  const loadDiplomaSample = () => {
    svgPanZoom('#diplomaExample', {
      controlIconsEnabled: true,
      fit: true
    });
  };

  return (
    <>
      <h1 className="header">Ιδρύματα</h1>
      <div className="section">
        <div className="inner section">
          <p>
            Στο πλαίσιο της ένταξης ενός Ιδρύματος στην υπηρεσία{' '}
            <em>eDiplomas</em>, πρέπει να ολοκληρωθούν τα ακόλουθα βήματα:
          </p>
          <ol>
            <li>Ταυτοποίηση πτυχιούχων με τον ΑΜΚΑ τους</li>
            <li>Καταγραφή των Πρότυπων Τίτλων Σπουδών</li>
            <li>Υλοποίηση DB View ή API με τα στοιχεία των πτυχιούχων</li>
          </ol>
          <p>
            Τα δεδομένα που πρέπει να παρέχει το ίδρυμα χωρίζονται σε 2
            κατηγορίες:
          </p>
          <ul>
            <li>
              Τα δεδομένα που αφορούν τα Πρότυπα Τίτλων Σπουδών, δηλαδή ότι
              πληροφορία υπάρχει σε έναν Τίτλο Σπουδών με εξαίρεση τα στοιχεία
              που αφορούν τον κατόχου του. Τα δεδομένα αυτά δημιουργούνται και
              δομούνται με τρόπο που περιγράφεται στις επόμενες ενότητες και
              αποθηκεύονται στην πλατφόρμα του eDiplomas.
            </li>
            <li>
              Τα δεδομένα που αφορούν τον κάτοχο του Τίτλου Σπουδών τα οποία
              αποθηκεύονται και διατίθενται είτε μέσω API είτε μέσω DB View από
              το ίδρυμα. (Η διαδικασία περιγράφεται αναλυτικά στις επόμενες
              ενότητες)
            </li>
          </ul>
          Τα δεδομένα ενός κατόχου Τίτλου Σπουδών συνδυάζονται με τα δεδομένα
          ενός Προτύπου και αποτελούν έναν Τίτλο Σπουδών.
        </div>
      </div>
      <div className="section">
        <h2 id="ins_basic_concepts" className="header nav-anchor">
          Βασικές έννοιες
        </h2>
        <div className="inner section">
          <p>
            Η ένταξη των Τίτλων Σπουδών ενός Ιδρύματος στο <em>eDiplomas</em>,
            περιλαμβάνει την εισαγωγή συγκεκριμένων δεδομένων που σχετίζονται με
            αυτούς με διαδικασία που περιγράφεται στην επόμενη ενότητα.
          </p>
          <p>
            Τα δεδομένα που θα πρέπει να εισαχθούν ανήκουν στις εξής κατηγορίες:
          </p>
          <ul>
            <li>
              <strong>Ιδρύματα:</strong> Οι εγγραφές που απαρτίζουν τα{' '}
              <em>Ιδρύματα</em> θα πρέπει να περιλαμβάνουν τη σημερινή αλλά και
              κάθε παλιότερη ονομασία του Ιδρύματος με την οποία έχει εκδώσει
              τίτλους σπουδών. Αυτό πρέπει να περιλαμβάνει και τα ονόματα που
              προέκυψαν μετά από συγχωνεύσεις Ιδρυμάτων, όπου πρέπει να υπάρχουν
              όλες οι ονομασίες που εμφανίστηκαν σε τίτλους σπουδών όσο
              αποτελούσαν ξεχωριστά Ιδρύματα.
            </li>
            <li>
              <strong>Αρμόδια Τμήματα:</strong> Οι εγγραφές των Τμημάτων που
              απαρτίζουν το Ίδρυμα σήμερα. Εμφανίζονται με το σημερινό και μόνο
              όνομα τους (μια εγγραφή ανά τμήμα). Τα τμήματα αυτά είναι{' '}
              <em>αρμόδια</em> για να εισάγουν και να επικαιροποιούν τα δεδομένα
              που σχετίζονται με τους Τίτλους Σπουδών τους. Ένα{' '}
              <strong>Αρμόδιο Τμήμα</strong> συσχετίζεται με την εγγραφή στα{' '}
              <em>Ιδρύματα</em> που έχει τη σημερινή ονομασία του Ιδρύματος.
            </li>
            <li>
              <strong>Τμήματα Έκδοσης:</strong> Οι εγγραφές των Τμημάτων που
              έχουν εκδώσει τίτλους σπουδών. Κάθε ονομασία Τμήματος που
              εμφανίστηκε σε τίτλο σπουδών του Ιδρύματος πρέπει να υπάρχει σε
              αυτή την κατηγορία. Αυτό περιλαμβάνει τα σημερινά Τμήματα ενός
              Ιδρύματος, τα παλαιότερα ενδεχομένως ονόματα τους καθώς και
              Τμήματα που καταργήθηκαν (είτε οριστικά είτε
              συγχωνεύτηκαν/ενώθηκαν) αλλά εξέδωσαν πτυχία. Τα{' '}
              <strong>Τμήματα Έκδοσης</strong> μαζί με τα{' '}
              <strong>Ιδρύματα</strong> θα πρέπει να περιλαμβάνουν το σύνολο των
              ονομασιών που έχουν εμφανιστεί ως εκδότες στους τίτλους σπουδών
              του Ιδρύματος. Συσχετίζονται με ένα από τα{' '}
              <strong>Ιδρύματα</strong> και με ένα{' '}
              <strong>Αρμόδιο Τμήμα</strong> που είναι υπεύθυνο σήμερα για τους
              τίτλους σπουδών που εξέδωσε το <strong>Τμήμα Έκδοσης</strong>.
            </li>
            <li>
              <strong>Προγράμματα Σπουδών:</strong> Οι εγγραφές των Προγραμμάτων
              Σπουδών που ανήκουν στο Ίδρυμα. Οι αναθεωρήσεις ενός υπάρχοντος
              Προγράμματος Σπουδών δεν αποτελούν νέα εγγραφή αν δεν επηρεάζουν
              το όνομα του, το επίπεδο του, ή τις γνώσεις που προσφέρει (fields
              of education). Τα <strong>Προγράμματα Σπουδών</strong>{' '}
              συσχετίζονται με το <strong>Αρμόδιο Τμήμα</strong> στο οποίο
              ανήκουν.
            </li>
            <li>
              <strong>Πρότυπα Τίτλου Σπουδών:</strong> Οι εγγραφές των
              διαφορετικών προτύπων Τίτλων Σπουδών που έχει εκδώσει ή εκδίδει
              ένα Ίδρυμα. Συσχετίζονται με το <strong>Τμήμα Έκδοσης</strong> και
              το <strong>Ίδρυμα</strong> που τα εξέδωσε, καθώς και με το{' '}
              <strong>Πρόγραμμα Σπουδών</strong> στο οποίο αντιστοιχούν. Τέλος
              συσχετίζονται με το <strong>Αρμόδιο Τμήμα</strong> που είναι
              υπεύθυνο για τους τίτλους αυτούς σήμερα.
            </li>
          </ul>
          <figure>
            <object
              onLoad={loadDiplomaSample}
              id="institutionExample"
              data={institutionExample}
              type="image/svg+xml"
            />
            <figcaption>
              Παράδειγμα δομής. Σύρσιμο για μετακίνηση, Scroll up/down για
              μεγέθυνση/σμίκρυνση.
            </figcaption>
          </figure>
          <figure className="figure-mobile">
            <img
              id="institutionExampleMobile"
              src={institutionExample}
              alt="institution-example"
            />
            <figcaption>
              Παράδειγμα δομής. Σύρσιμο για μετακίνηση, Scroll up/down για
              μεγέθυνση/σμίκρυνση.
            </figcaption>
          </figure>
          <p>
            Οι έννοιες <em>Ίδρυμα</em>, <em>Τμήμα Έκδοσης</em> και{' '}
            <em>Αρμόδιο Τμήμα</em> σχετίζονται με την{' '}
            <strong>Οργανωτική</strong> διάρθρωση του Ιδρύματος. Οι έννοιες{' '}
            <em>Πρόγραμμα Σπουδών</em> και <em>Πρότυπο Τίτλου Σπουδών</em>{' '}
            σχετίζονται με τις <strong>Σπουδές</strong>.
          </p>
          <div className="noteblock">
            <strong>Σημείωση:</strong> Οι όροι <em>Τμήμα</em>, <em>Ίδρυμα</em>,{' '}
            <em>Πρόγραμμα Σπουδών</em> και <em>Τίτλος Σπουδών</em> στο{' '}
            <em>eDiplomas</em> έχουν την σημασία που έχουν και στα Φ.Ε.Κ{' '}
            <em>4009/2011, 4076/2012, 4485/2017</em>.
          </div>
        </div>
      </div>
      <div className="section">
        <h2 id="ins_template-creation" className="header nav-anchor">
          Δημιουργία προτύπων τίτλων σπουδών
        </h2>
        <div className="inner section">
          <p>
            Για να εισάγει ένα Ίδρυμα τα <em>Πρότυπα Τίτλων Σπουδών</em> για τα
            οποία παρέχει βεβαιώσεις, θα πρέπει να μεταβεί στην εφαρμογή{' '}
            <a href="https://blueprints.ediplomas.gr">
              Διαχείρισης Προτύπων Τίτλων Σπουδών
            </a>
            . Για να αποκτήσει πρόσβαση στην πλατφόρμα θα χρειαστεί ειδικά{' '}
            <em>credentials</em> που θα λάβει μετά από επικοινωνία με το{' '}
            <em>eDiplomas</em>. Ο χρήστης της πλατφόρμας, μπορεί να καταχωρήσει
            τα στοιχεία των <em>Πρότυπων Τίτλων Σπουδών</em> του Ιδρύματος,
            δημιουργώντας <em>Ιδρύματα</em>, <em>Τμήματα</em>,{' '}
            <em>Προγράμματα Σπουδών</em> και <em>Πρότυπα Τίτλων Σπουδών</em>,
            ώστε να καλύψει κάθε περίπτωση τίτλου, τον οποίο το Ίδρυμα σχεδιάζει
            να διαθέσει μέσω του eDiplomas.
          </p>
          <p>Τα βήματα συμπλήρωσης είναι τα εξής:</p>
          <ul>
            <li>
              Εισαγωγή <i>Ιδρυμάτων</i>: Εισαγωγή των ονομάτων που έχουν
              εμφανιστεί σε τίτλους σπουδών ως Ίδρυμα έκδοσης. Δηλαδή εκτός από
              την σημερινή ονομασία του Ιδρύματος πρέπει να εισαχθούν τα
              παλαιότερα ονόματα του Ιδρύματος καθώς και επιμέρους τμήματα που
              συγχωνεύτηκαν ώστε να υπάρχει η σημερινή του μορφή.
            </li>
            <li>
              Εισαγωγή <i>Αρμοδίων Τμημάτων</i>: Εισαγωγή των Τμημάτων που
              απαρτίζουν το Ίδρυμα σήμερα. Τα Τμήματα αυτά πρέπει να
              συσχετιστούν με την σημερινή ονομασία του Ιδρύματος (Θα πρέπει να
              έχει εισαχθεί στο προηγούμενο βήμα).
            </li>
            <li>
              Εισαγωγή <i>Τμημάτων Έκδοσης</i>: Εισαγωγή των Τμημάτων (τωρινών
              και παλαιότερων) που έχουν εκδώσει τίτλους σπουδών στο Ίδρυμα. Θα
              πρέπει να συμπληρωθούν και Τμήματα που δεν υπάρχουν πλέον (π.χ
              λόγω συγχώνευσης) και να συσχετιστούν με το τμήμα που είναι
              υπεύθυνο για τους τίτλους σπουδών σήμερα.
            </li>
            <li>
              Εισαγωγή <i>Προγραμμάτων Σπουδών</i>: Εισαγωγή των πληροφοριών
              σχετικά με τα Προγράμματα Σπουδών για τα οποία είναι υπεύθυνο ένα
              Τμήμα (είτε σημερινά είτε παλαιότερα).
            </li>
            <li>
              Εισαγωγή <i>Προτύπων Τίτλων Σπουδών</i>: Εισαγωγή των Προτύπων
              Τίτλων Σπουδών που είτε εκδίδονται σήμερα είτε έχουν εκδοθεί
              παλαιότερα από το κάθε Τμήμα.
            </li>
          </ul>
          <figure>
            <object
              onLoad={loadPanZoom}
              id="diplomaExample"
              data={diplomaExample}
              type="image/svg+xml"
            />
            <figcaption>
              Παράδειγμα εγγράφου μεταπτυχιακού διπλώματος.
            </figcaption>
          </figure>
          <figure className="figure-mobile">
            <img
              id="diplomaExampleMobile"
              src={diplomaExample}
              alt="diploma-example"
            />
            <figcaption>
              Παράδειγμα εγγράφου μεταπτυχιακού διπλώματος.
            </figcaption>
          </figure>
          <p>
            Στη συνέχεια το Ίδρυμα θα πρέπει να υλοποιήσει είτε ένα{' '}
            <i>Database view</i>, είτε ένα <i>API</i> με τα δεδομένα των
            πτυχιούχων όπως περιγράφεται στην επόμενη ενότητα.
          </p>
        </div>
        <div className="inner section">
          <Heading
            text="Ενδεικτικό Παράδειγμα"
            id="ins_template-example"
            level="3"
          />
          <p>
            Αφότου ένα ίδρυμα ακολουθήσει τα παραπάνω βήματα στην εφαρμογή{' '}
            <a href="https://blueprints.ediplomas.gr">
              Διαχείρισης Προτύπων Τίτλων Σπουδών
            </a>{' '}
            θα έχει, πλέον, στην αρχική σελίδα της εφαρμογής μια αναπαράσταση
            των στοιχείων που εισήγαγε, σε δεντρική μορφή.
          </p>
          <p>Ένα παράδειγμα του δέντρου που θα παραχθεί δίνεται παρακάτω.</p>
          <div className="example tree">
            <ul>
              <li>
                <h2>Ίδρυμα 1</h2>
                <ul>
                  <li>
                    <h4>Αρμόδια Τμήματα</h4>
                    <ul>
                      <li>
                        <h4>Αρμόδιο Τμήμα 1</h4>
                        <ul>
                          <h4>Προγράμματα Σπουδών Αρμόδιου Τμήματος</h4>
                          <li>Πρόγραμμα Σπουδών 1</li>
                          <li>Πρόγραμμα Σπουδών 2</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h4>Τμήματα Έκδοσης</h4>
                    <ul>
                      <li>
                        <h5>Τμήμα Έκδοσης 1</h5>
                        <ul>
                          <h4>Πρότυπα Τίτλων Σπουδών</h4>
                          <li>Πρότυπο Τίτλου Σπουδών 1</li>
                          <li>Πρότυπα Τίτλου Σπουδών 2</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h2>Ίδρυμα 2</h2>
                <span>...</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section">
        <h2 id="ins_data-entry" className="header nav-anchor">
          Εισαγωγή δεδομένων πτυχιούχων
        </h2>
        <div className="inner section">
          <p>
            Για να ενταχθεί ένα Ίδρυμα στην πλατφόρμα του <i>eDiplomas</i> και
            να διαθέσει ηλεκτρονικά τους τίτλων σπουδών που έχει εκδώσει, θα
            πρέπει να υλοποιήσει συγκεκριμένη υποδομή από την οποία το{' '}
            <i>eDiplomas</i> θα αντλεί τις πληροφορίες αυτών. Το{' '}
            <i>eDiplomas</i> προσφέρει 2 επιλογές για την υποδομή αυτή:
          </p>
          <ul>
            <li>
              Διασύνδεση μέσω υλοποίησης <a href="#ins_view">Database View</a>:
              Το Ίδρυμα υλοποιεί ένα Database View συγκεκριμένου σχήματος που
              περιέχει όλη την πληροφορία που απαιτείται γύρω από τους τίτλους
              σπουδών.
            </li>
            <li>
              Διασύνδεση μέσω υλοποίησης <a href="#ins_API">API</a>: Το Ίδρυμα
              υλοποιεί ένα API για ένα Web Service που απαντάει στα αιτήματα
              αναζήτησης του <i>eDiplomas</i> με συγκεκριμένη μορφή απάντησης
              τύπου json
            </li>
          </ul>
          <p>
            Τα Ιδρύματα που θα ενταχθούν στο <i>eDiplomas</i> επιλέγουν{' '}
            <strong>ακριβώς έναν</strong> από τους παραπάνω τρόπους για να
            υλοποιήσουν την συγκεκριμένη υποδομή. Μετά την υλοποίηση το Ίδρυμα
            πρέπει να επικοινωνήσει με το <i>eDiplomas</i> ώστε να δοθούν τα
            απαραίτητα credentials και να γίνουν οι σχετικές δοκιμές.
          </p>
        </div>
        <div className="inner section">
          <Heading text="Μέσω View" id="ins_view" level="3" />
          <div className="inner section">
            <p>
              Το Database View που απαιτείται από το Ίδρυμα ώστε το{' '}
              <i>eDiplomas</i> να μπορεί να αντλήσει την απαραίτητη πληροφορία
              έχει συγκεκριμένο <a href="#ins_schema">σχήμα</a> και κάθε{' '}
              <a href="#ins_fieldsview">πεδίο</a> πρέπει να λαμβάνει
              συγκεκριμένες τιμές όπως περιγράφεται και στη συνέχεια. Μετά την
              δημιουργία του Database View και την εισαγωγή τίτλων σπουδών σε
              αυτό, το Ίδρυμα καλείται να επικοινωνήσει με το <i>eDiplomas</i>{' '}
              ώστε να δοθούν τα credentials της βάσης αυτής και να γίνουν οι
              απαραίτητες τεχνικές ρυθμίσεις.
            </p>
          </div>
          <div className="inner section">
            <Heading text="Σχήμα" id="ins_schema" level="4" />
            <table className="doctable schema">
              <thead>
                <tr>
                  <th>Όνομα table</th>
                  <th>
                    Τύπος Δεδομένων <span className="note">(Oracle)</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {viewFields.map((field) => (
                  <tr key={field.name}>
                    <td>
                      <a href={`#ins_${field.name.toLowerCase()}-view`}>
                        {field.name}
                      </a>
                    </td>
                    <td>{field.type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="inner section">
            <Heading text="Πεδία" id="ins_fieldsview" level="4" />
            <div className="inner section">
              <Heading
                text="CERTIFICATEID"
                id="ins_certificateid-view"
                level="5"
              />
              <span>
                Αφορά το μοναδικό αναγνωριστικό του πτυχίου που έχει αποδοθεί σε
                ένα φυσικό πρόσωπο. Είναι το αντίστοιχο στοιχείο, του “Αριθμού
                Πρωτοκόλλου” ενός έντυπου πτυχίου, θα πρέπει ωστόσο αυτό να
                είναι μοναδικό σε ιδρυματικό επίπεδο. Το πεδίο θα πρέπει να
                περιέχει αλφαριθμητική τιμή και η διαχείρισή του πρέπει να
                εξασφαλίζει όλα τα χαρακτηριστικά μοναδικών ταυτοποιητικών
                στοιχείων (identifier uniqueness, identifier
                non-reassignability, identifier non-revocability, identifier
                persistency). Επιθυμητό είναι τα παραπάνω στοιχεία να
                εξασφαλίζονται εγγενώς από τον σχεδιασμό του πληροφοριακού
                συστήματος σε όλα τα επίπεδα.
              </span>
            </div>
            <div className="inner section">
              <Heading text="TITLEID" id="ins_titleid-view" level="5" />
              <span>
                Αφορά στο μοναδικό αναγνωριστικό που συνδέσει το συγκεκριμένο
                πτυχίο, με τον πρότυπο τίτλο του πτυχίου, όπως αυτός έχει
                καταγραφεί στο σύστημα “Τίτλοι Πτυχίων”. Ο συνδυασμός των
                στοιχείων <em>certificateID</em>, <em>titleID</em> προσδιορίζει
                μοναδικά ένα πτυχίο.
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="REGISTRATIONID"
                id="ins_registrationid-view"
                level="5"
              />
              <span>
                Είναι ο Αριθμός Μητρώου Φοιτητή, που αντιστοιχεί στο κάτοχο του
                πτυχίου. Το πεδίο θα πρέπει να περιέχει αλφαριθμητική τιμή και η
                διαχείρισή του πρέπει να εξασφαλίζει όλα τα χαρακτηριστικά
                μοναδικών ταυτοποιητικών στοιχείων (identifier uniqueness,
                identifier non-reassignability, identifier non-revocability,
                identifier persistency). Επιθυμητό είναι τα παραπάνω στοιχεία να
                εξασφαλίζονται εγγενώς από τον σχεδιασμό του πληροφοριακού
                συστήματος σε όλα τα επίπεδα.
              </span>
            </div>
            <div className="inner section">
              <Heading text="SYSTEMID" id="ins_systemid-view" level="5" />
              <span>
                Είναι το πρωτεύον κλειδί, της βάσης που το ίδρυμα διατηρεί τις
                εγγραφές των πτυχιούχων. Το πεδίο αυτό ενδεχόμενα να είναι ένας
                σειριακός αριθμός (sequence), ή ένα αδόμητο αλφαριθμητικό
                (opaque identifier), δηλαδή ένα πεδίο που δεν περιλαμβάνει
                σημασιολογικές πληροφορίες (semantic information). Πιθανότατα
                δεν γνωστοποιείται σε τελικούς χρήστες και αποτελεί στοιχείο
                κάποιου εκτυπωτικού. Το πεδίο θα πρέπει να περιέχει
                αλφαριθμητική τιμή και η διαχείρισή του πρέπει να εξασφαλίζει
                όλα τα χαρακτηριστικά μοναδικών ταυτοποιητικών στοιχείων
                (identifier uniqueness, identifier non-reassignability,
                identifier non-revocability, identifier persistency). Επιθυμητό
                είναι τα παραπάνω στοιχεία να εξασφαλίζονται εγγενώς από τον
                σχεδιασμό του πληροφοριακού συστήματος σε όλα τα επίπεδα.
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="GRADEDESCRIPTION"
                id="ins_gradedescription-view"
                level="5"
              />
              <span>
                Περιέχει την περιγραφή της βαθμολογίας για το συγκεκριμένο
                πτυχίο, σύμφωνα με τις προδιαγραφές του αρμόδιου ιδρύματος ή
                τμήματος.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Λίαν Καλώς</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="GRADEVALUE" id="ins_gradevalue-view" level="5" />
              <span>
                Περιέχει την βαθμολογία για το συγκεκριμένο πτυχίο σε αριθμητική
                αναπαράσταση, κανονικοποιημένο στο εύρος 0 έως 1.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">0.811</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="DATEISSUED" id="ins_dateissued-view" level="5" />
              <span>
                Περιέχει την ημερομηνία λήψης του πτυχίου, δηλαδή την ημερομηνία
                της τελετής ορκωμοσίας κατά την οποία απονεμήθηκε το
                συγκεκριμένο πτυχίο. Σημειώνεται ακολουθώντας βασική μορφοποίηση
                ημερομηνίας (calendar date, basic format) σύμφωνα με το πρότυπο{' '}
                <a
                  href="http://www.iso.org/iso/iso8601)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>
                . Η ημερομηνία περιλαμβάνει το έτος, το μήνα και την ημέρα με τη
                μορφή <span className="field-format">YYYYMMDD</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="VALIDFROM" id="ins_validfrom-view" level="5" />
              <span>
                Περιέχει την ημερομηνία ανακήρυξης του κατόχου ως πτυχιούχος για
                το συγκεκριμένο δίπλωμα. Σημειώνεται ακολουθώντας βασική
                μορφοποίηση ημερομηνίας (calendar date, basic format) σύμφωνα με
                το πρότυπο{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>
                . Η ημερομηνία περιλαμβάνει το έτος, το μήνα και την ημέρα με τη
                μορφή <span className="field-format">YYYYMMDD</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="STATUS" id="ins_status-view" level="5" />
              <span>
                Περιέχει πληροφορίες σχετικά με την κατάσταση του πτυχίου.
                Μπορεί να περιέχει είτε την ειδική τιμή{' '}
                <span className="field-value">valid</span> , που σημαίνει ότι το
                συγκεκριμένο πτυχίο είναι κανονικά σε ισχύ, είτε την ειδική τιμή{' '}
                <span className="field-value">revoked</span> που σημαίνει ότι το
                συγκεκριμένο πτυχίο δεν είναι πλέον έγκυρο.
              </span>
            </div>
            <div className="inner section">
              <Heading text="STATUSDATE" id="ins_statusdate-view" level="5" />
              <span>
                Αφορά την ημερομηνία από την οποία βρίσκεται σε ισχύ η κατάσταση
                που αναφέρεται από το πεδίο <em>status</em>. Αποτελεί ουσιαστικά
                την τελευταία ημερομηνία μεταβολής. Σημειώνεται ακολουθώντας
                βασική μορφοποίηση ημερομηνίας (calendar date, basic format)
                σύμφωνα με το πρότυπο{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>
                . Η ημερομηνία περιλαμβάνει το έτος, το μήνα και την ημέρα
                ακολουθώντας τη μορφή{' '}
                <span className="field-format">YYYYMMDD</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="SSN" id="ins_ssn-view" level="5" />
              <span>
                Περιέχει την τιμή του Αριθμού Μητρώου Κοινωνικής Ασφάλισης
                (ΑΜΚΑ) του κατόχου του πτυχίου.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">31120012345</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="SSNCOUNTRY" id="ins_ssncountry-view" level="5" />
              <span>
                Είναι η χώρα έκδοσης του <em>SSN</em> . Σημειώνεται με την
                2-γράμματη κωδικοποίηση του{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>
                .<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="TIN" id="ins_tin-view" level="5" />
              <span>
                Περιέχει την τιμή του Αριθμού Φορολογικού Μητρώου (ΑΦΜ) του
                κατόχου του πτυχίου.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">123456789</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="TINCOUNTRY" id="ins_tincountry" level="5" />
              <span>
                Είναι η χώρα έκδοσης του <em>TIN</em> . Σημειώνεται με την
                2-γράμματη κωδικοποίηση του{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>
                .<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="FIRSTNAMEEL" id="ins_firstnameel-view" level="5" />
              <span>
                Το όνομα του του κατόχου του πτυχίου με απόδοση σε ελληνικούς
                χαρακτήρες. Σε περίπτωση που το όνομα του φυσικού προσώπου δεν
                αποδίδεται με ελληνικούς χαρακτήρες, το πεδίο θα λαμβάνει την
                ειδική τιμή <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Νικόλαος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="FIRSTNAMEEN" id="ins_firstnameen-view" level="5" />
              <span>
                Το όνομα του κατόχου του πτυχίου με απόδοση σε λατινικούς
                χαρακτήρες. Θα πρέπει να αποδίδεται για όλα τα φυσικά πρόσωπα.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Nikolaos</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="LASTNAMEEL" id="ins_lastnameel-view" level="5" />
              <span>
                Το επώνυμο του κατόχου του πτυχίου με απόδοση σε ελληνικούς
                χαρακτήρες. Σε περίπτωση που το επώνυμο του φυσικού προσώπου δεν
                αποδίδεται με ελληνικούς χαρακτήρες, το πεδίο θα λαμβάνει την
                ειδική τιμή <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Παπαδόπουλος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="LASTNAMEEN" id="ins_lastnameen-view" level="5" />
              <span>
                Το επώνυμο του κατόχου του πτυχίου με απόδοση σε λατινικούς
                χαρακτήρες.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Papadopoulos</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="MIDDLENAMEEL"
                id="ins_middlenameel-view"
                level="5"
              />
              <span>
                Το μεσαίο όνομα του κατόχου του πτυχίου με απόδοση σε ελληνικούς
                χαρακτήρες. Σε περίπτωση που το μεσαίο όνομα του φυσικού
                προσώπου δεν αποδίδεται με ελληνικούς χαρακτήρες, ή σε περίπτωση
                που το φυσικό πρόσωπο δεν έχει μεσαίο όνομα, το πεδίο θα
                λαμβάνει την ειδική τιμή{' '}
                <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Ιωάννης</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="MIDDLENAMEEN"
                id="ins_middlenameen-view"
                level="5"
              />
              <span>
                Το μεσαίο όνομα του κατόχου του πτυχίου με απόδοση σε λατινικούς
                χαρακτήρες.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Ioannis</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="FATHERNAMEEL"
                id="ins_fathernameel-view"
                level="5"
              />
              <span>
                Το όνομα πατέρα του κατόχου του πτυχίου με απόδοση σε ελληνικούς
                χαρακτήρες.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Αλέξανδρος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="FATHERNAMEEN"
                id="ins_fathernameen-view"
                level="5"
              />
              <span>
                Το όνομα πατέρα του κατόχου του πτυχίου με απόδοση σε λατινικούς
                χαρακτήρες.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Alexandros</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="MOTHERNAMEEL"
                id="ins_mothernameel-view"
                level="5"
              />
              <span>
                Το όνομα της μητέρας του κατόχου του πτυχίου με απόδοση σε
                ελληνικούς χαρακτήρες.
                <br />
                <span className="field-example">
                  Παραδειγμα: <span className="field-value">Μαρία</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="MOTHERNAMEEN"
                id="ins_mothernameen-view"
                level="5"
              />
              <span>
                Το όνομα της μητέρας του κατόχου του πτυχίου με απόδοση σε
                λατινικούς χαρακτήρες.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Maria</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="BIRTHDATE" id="ins_birthdate-view" level="5" />
              <span>
                Η ημερομηνία γέννησης του κατόχου του πτυχίου. Σημειώνεται
                ακολουθώντας βασική μορφοποίηση ημερομηνίας (calendar date,
                basic format) σύμφωνα με το πρότυπο{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>
                . Η ημερομηνία περιλαμβάνει το έτος, το μήνα και την ημέρα με τη
                μορφή <span className="field-format">YYYYMMDD</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">20001231</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="BIRTHYEAR" id="ins_birthyear-view" level="5" />
              <span>
                Το έτος γέννησης του κατόχου του πτυχίου με την μορφή{' '}
                <span className="field-format">ΥΥΥΥ</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">1995</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="GENDER" id="ins_gender-view" level="5" />
              <span>
                Αφορά το φύλο του μέλους (άντρας,γυναίκα). Οι βασικές τιμές
                αυτού ακολουθούν το πρότυπο{' '}
                <a
                  href="http://en.wikipedia.org/wiki/ISO_5218"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO/IEC 5218:2004
                </a>
                .<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">1</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="CITIZENSHIP" id="ins_citizenship-view" level="5" />
              <span>
                Η ιθαγένεια/υπηκοότητα του φυσικού προσώπου. Σημειώνεται με την
                2-γράμματη κωδικοποίηση του{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>
                .<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="inner section">
          <Heading text="Μέσω API" id="ins_API" level="3" />
          <div className="inner section">
            <p>
              Το API που πρέπει να υλοποιηθεί από το Ίδρυμα ώστε το{' '}
              <i>eDiplomas</i> να μπορεί να αντλεί πληροφορίες τίτλων σπουδών
              έχει συγκεκριμένα πεδία και μορφή απαντήσεων που περιγράφονται
              παρακάτω. Το πως υλοποιείται το ίδιο το WebService που απαντάει
              δεν έχει σημασία για το <i>eDiplomas</i> εφόσον απαντά με τον
              τρόπο που περιγράφεται. Το API μπορεί να προστατεύεται μέσω
              whitelisting σε IPs που ανήκουν στο <i>eDiplomas</i> ή/και μέσω
              HTTP Basic Authentication. Μετά την υλοποίηση του API το Ίδρυμα
              πρέπει να επικοινωνήσει με το <i>eDiplomas</i> για να γίνουν οι
              απαραίτητες ρυθμίσεις.{' '}
            </p>
            <p>
              Η απάντηση του API θα πρέπει να είναι JSON και να ακολουθεί την
              παρακάτω μορφή.
            </p>
            <p>Περίπτωση χωρίς σφάλμα:</p>
            <CodeExample data="[ {πεδία_πτυχίου_1}, {πεδία_πτυχιου_2}, ...]" />
            <p>Περίπτωση χωρίς σφάλμα και χωρίς τίτλους σπουδών:</p>
            <CodeExample data="[]" />
            <p>Περίπτωση με σφάλμα:</p>
            <CodeExample data={'{"error": "περιγραφή_σφάλματος"}'} />
          </div>
          <div className="inner section">
            <Heading text="Πεδία" id="ins_fieldsapi" level="4" />
            <p>Κάθε JSON αντικείμενο πτυχίου περιέχει συγκεκριμένα πεδία.</p>
            <div className="inner section">
              <Heading
                text="certificateID"
                id="ins_certificateid-api"
                level="5"
              />
              <span>
                Αφορά το μοναδικό αναγνωριστικό του πτυχίου που έχει αποδοθεί σε
                ένα φυσικό πρόσωπο. Είναι το αντίστοιχο στοιχείο, του “Αριθμού
                Πρωτοκόλλου” ενός έντυπου πτυχίου, θα πρέπει ωστόσο αυτό να
                είναι μοναδικό σε ιδρυματικό επίπεδο. Το πεδίο θα πρέπει να
                περιέχει αλφαριθμητική τιμή και η διαχείρισή του πρέπει να
                εξασφαλίζει όλα τα χαρακτηριστικά μοναδικών ταυτοποιητικών
                στοιχείων (identifier uniqueness, identifier
                non-reassignability, identifier non-revocability, identifier
                persistency). Επιθυμητό είναι τα παραπάνω στοιχεία να
                εξασφαλίζονται εγγενώς από τον σχεδιασμό του πληροφοριακού
                συστήματος σε όλα τα επίπεδα.
              </span>
            </div>
            <div className="inner section">
              <Heading text="titleID" id="ins_titleid-api" level="5" />
              <span>
                Αφορά το μοναδικό αναγνωριστικό που συνδέσει το συγκεκριμένο
                πτυχίο, με τον πρότυπο τίτλο του πτυχίου, όπως αυτός έχει
                καταγραφεί στο σύστημα “Τίτλοι Πτυχίων”. Ο συνδυασμός των
                στοιχείων <em>certificateID</em>, <em>titleID</em> προσδιορίζει
                μοναδικά ένα πτυχίο.
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="registrationID"
                id="ins_registrationid-api"
                level="5"
              />
              <span>
                Είναι ο Αριθμός Μητρώου Φοιτητή, που αντιστοιχεί στο κάτοχο του
                πτυχίου. Το πεδίο θα πρέπει να περιέχει αλφαριθμητική τιμή και η
                διαχείρισή του πρέπει να εξασφαλίζει όλα τα χαρακτηριστικά
                μοναδικών ταυτοποιητικών στοιχείων (identifier uniqueness,
                identifier non-reassignability, identifier non-revocability,
                identifier persistency). Επιθυμητό είναι τα παραπάνω στοιχεία να
                εξασφαλίζονται εγγενώς από τον σχεδιασμό του πληροφοριακού
                συστήματος σε όλα τα επίπεδα.
              </span>
            </div>
            <div className="inner section">
              <Heading text="systemID" id="ins_systemid-api" level="5" />
              <span>
                Είναι το πρωτεύον κλειδί, της βάσης που το ίδρυμα διατηρεί τις
                εγγραφές των πτυχιούχων. Το πεδίο αυτό ενδεχόμενα να είναι ένας
                σειριακός αριθμός (sequence), ή ένα αδόμητο αλφαριθμητικό
                (opaque identifier), δηλαδή ένα πεδίο που δεν περιλαμβάνει
                σημασιολογικές πληροφορίες (semantic information). Πιθανότατα
                δεν γνωστοποιείται σε τελικούς χρήστες και αποτελεί στοιχείο
                κάποιου εκτυπωτικού. Το πεδίο θα πρέπει να περιέχει
                αλφαριθμητική τιμή και η διαχείρισή του πρέπει να εξασφαλίζει
                όλα τα χαρακτηριστικά μοναδικών ταυτοποιητικών στοιχείων
                (identifier uniqueness, identifier non-reassignability,
                identifier non-revocability, identifier persistency). Επιθυμητό
                είναι τα παραπάνω στοιχεία να εξασφαλίζονται εγγενώς από τον
                σχεδιασμό του πληροφοριακού συστήματος σε όλα τα επίπεδα.
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="gradeDescription"
                id="ins_gradedescription-api"
                level="5"
              />
              <span>
                Περιέχει την περιγραφή της βαθμολογίας για το συγκεκριμένο
                πτυχίο, σύμφωνα με τις προδιαγραφές του αρμόδιου ιδρύματος ή
                τμήματος.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Λίαν Καλώς</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="gradeValue" id="ins_gradevalue-api" level="5" />
              <span>
                Περιέχει την βαθμολογία για το συγκεκριμένο πτυχίο σε αριθμητική
                αναπαράσταση, κανονικοποιημένο στο εύρος 0 έως 1.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">0.811</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="dateIssued" id="ins_dateissued-api" level="5" />
              <span>
                Περιέχει την ημερομηνία λήψης του πτυχίου, δηλαδή την ημερομηνία
                της τελετής ορκωμοσίας κατά την οποία απονεμήθηκε το
                συγκεκριμένο πτυχίο. Σημειώνεται ακολουθώντας βασική μορφοποίηση
                ημερομηνίας (calendar date, basic format) σύμφωνα με το πρότυπο{' '}
                <a
                  href="http://www.iso.org/iso/iso8601)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>
                . Η ημερομηνία περιλαμβάνει το έτος, το μήνα και την ημέρα με τη
                μορφή <span className="field-format">YYYYMMDD</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="validFrom" id="ins_validfrom-api" level="5" />
              <span>
                Περιέχει την ημερομηνία ανακήρυξης του κατόχου ως πτυχιούχος για
                το συγκεκριμένο δίπλωμα. Σημειώνεται ακολουθώντας βασική
                μορφοποίηση ημερομηνίας (calendar date, basic format) σύμφωνα με
                το πρότυπο{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>
                . Η ημερομηνία περιλαμβάνει το έτος, το μήνα και την ημέρα με τη
                μορφή <span className="field-format">YYYYMMDD</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="status" id="ins_status-api" level="5" />
              <span>
                Περιέχει πληροφορίες σχετικά με την κατάσταση του πτυχίου.
                Μπορεί να περιέχει είτε την ειδική τιμή{' '}
                <span className="field-value">valid</span> , που σημαίνει ότι το
                συγκεκριμένο πτυχίο είναι κανονικά σε ισχύ, είτε την ειδική τιμή{' '}
                <span className="field-value">revoked</span> που σημαίνει ότι το
                συγκεκριμένο πτυχίο δεν είναι πλέον έγκυρο.
              </span>
            </div>
            <div className="inner section">
              <Heading text="statusDate" id="ins_statusdate-api" level="5" />
              <span>
                Αφορά την ημερομηνία από την οποία βρίσκεται σε ισχύ η κατάσταση
                που αναφέρεται από το πεδίο <em>status</em>. Αποτελεί ουσιαστικά
                την τελευταία ημερομηνία μεταβολής. Σημειώνεται ακολουθώντας
                βασική μορφοποίηση ημερομηνίας (calendar date, basic format)
                σύμφωνα με το πρότυπο{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>
                . Η ημερομηνία περιλαμβάνει το έτος, το μήνα και την ημέρα
                ακολουθώντας τη μορφή{' '}
                <span className="field-format">YYYYMMDD</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">19950924</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="SSN" id="ins_ssn-api" level="5" />
              <span>
                Περιέχει την τιμή του Αριθμού Μητρώου Κοινωνικής Ασφάλισης
                (ΑΜΚΑ) του κατόχου του πτυχίου.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">31120012345</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="SSNCOUNTRY" id="ins_ssncountry-api" level="5" />
              <span>
                Είναι η χώρα έκδοσης του <em>SSN</em> . Σημειώνεται με την
                2-γράμματη κωδικοποίηση του{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>
                .<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="TIN" id="ins_tin-api" level="5" />
              <span>
                Περιέχει την τιμή του Αριθμού Φορολογικού Μητρώου (ΑΦΜ) του
                κατόχου του πτυχίου.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">123456789</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="TINCOUNTRY" id="ins_tincountry-api" level="5" />
              <span>
                Είναι η χώρα έκδοσης του <em>TIN</em> . Σημειώνεται με την
                2-γράμματη κωδικοποίηση του{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>
                .<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="firstNameEl" id="ins_firstnameel-api" level="5" />
              <span>
                Το όνομα του του κατόχου του πτυχίου με απόδοση σε ελληνικούς
                χαρακτήρες. Σε περίπτωση που το όνομα του φυσικού προσώπου δεν
                αποδίδεται με ελληνικούς χαρακτήρες, το πεδίο θα λαμβάνει την
                ειδική τιμή <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Νικόλαος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="firstNameEn" id="ins_firstnameen-api" level="5" />
              <span>
                Το όνομα του κατόχου του πτυχίου με απόδοση σε λατινικούς
                χαρακτήρες. Θα πρέπει να αποδίδεται για όλα τα φυσικά πρόσωπα.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Nikolaos</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="lastNameEl" id="ins_lastnameel-api" level="5" />
              <span>
                Το επώνυμο του κατόχου του πτυχίου με απόδοση σε ελληνικούς
                χαρακτήρες. Σε περίπτωση που το επώνυμο του φυσικού προσώπου δεν
                αποδίδεται με ελληνικούς χαρακτήρες, το πεδίο θα λαμβάνει την
                ειδική τιμή <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Παπαδόπουλος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="lastNameEn" id="ins_lastnameen-api" level="5" />
              <span>
                Το επώνυμο του κατόχου του πτυχίου με απόδοση σε λατινικούς
                χαρακτήρες.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Παπαδόπουλος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="middleNameEl"
                id="ins_middlenameel-api"
                level="5"
              />
              <span>
                Το μεσαίο όνομα του κατόχου του πτυχίου με απόδοση σε ελληνικούς
                χαρακτήρες. Σε περίπτωση που το μεσαίο όνομα του φυσικού
                προσώπου δεν αποδίδεται με ελληνικούς χαρακτήρες, ή σε περίπτωση
                που το φυσικό πρόσωπο δεν έχει μεσαίο όνομα, το πεδίο θα
                λαμβάνει την ειδική τιμή{' '}
                <span className="field-value">null</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Ιωάννης</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="middleNameEn"
                id="ins_middlenameen-api"
                level="5"
              />
              <span>
                Το μεσαίο όνομα του κατόχου του πτυχίου με απόδοση σε λατινικούς
                χαρακτήρες. Σε περίπτωση που το φυσικό πρόσωπο δεν έχει μεσαίο
                όνομα, το πεδίο θα λαμβάνει την ειδική τιμή{' '}
                <span className="field-value">null</span> .<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Ioannis</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="fatherNameEl"
                id="ins_fathernameel-api"
                level="5"
              />
              <span>
                Το όνομα πατέρα του κατόχου του πτυχίου με απόδοση σε ελληνικούς
                χαρακτήρες.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Αλέξανδρος</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="fatherNameEn"
                id="ins_fathernameen-api"
                level="5"
              />
              <span>
                Το όνομα πατέρα του κατόχου του πτυχίου με απόδοση σε λατινικούς
                χαρακτήρες.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Alexandros</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="motherNameEl"
                id="ins_mothernameel-api"
                level="5"
              />
              <span>
                Το όνομα της μητέρας του κατόχου του πτυχίου με απόδοση σε
                ελληνικούς χαρακτήρες.
                <br />
                <span className="field-example">
                  Παραδειγμα: <span className="field-value">Μαρία</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading
                text="motherNameEn"
                id="ins_mothernameen-api"
                level="5"
              />
              <span>
                Το όνομα της μητέρας του κατόχου του πτυχίου με απόδοση σε
                λατινικούς χαρακτήρες.
                <br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">Maria</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="birthDate" id="ins_birthdate-api" level="5" />
              <span>
                Η ημερομηνία γέννησης του κατόχου του πτυχίου. Σημειώνεται
                ακολουθώντας βασική μορφοποίηση ημερομηνίας (calendar date,
                basic format) σύμφωνα με το πρότυπο{' '}
                <a
                  href="http://www.iso.org/iso/iso8601"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 8601:2004
                </a>
                . Η ημερομηνία περιλαμβάνει το έτος, το μήνα και την ημέρα με τη
                μορφή <span className="field-format">YYYYMMDD</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">20001231</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="birthYear" id="ins_birthyear-api" level="5" />
              <span>
                Το έτος γέννησης του κατόχου του πτυχίου με την μορφή{' '}
                <span className="field-format">ΥΥΥΥ</span>.<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">1995</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="gender" id="ins_gender-api" level="5" />
              <span>
                Αφορά το φύλο του μέλους (άντρας,γυναίκα). Οι βασικές τιμές
                αυτού ακολουθούν το πρότυπο{' '}
                <a
                  href="http://en.wikipedia.org/wiki/ISO_5218"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO/IEC 5218:2004
                </a>
                .<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">1</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="citizenship" id="ins_citizenship-api" level="5" />
              <span>
                Η ιθαγένεια/υπηκοότητα του φυσικού προσώπου. Σημειώνεται με την
                2-γράμματη κωδικοποίηση του{' '}
                <a
                  href="https://www.iso.org/obp/ui/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ISO 3166
                </a>
                .<br />
                <span className="field-example">
                  Παράδειγμα: <span className="field-value">GR</span>
                </span>
              </span>
            </div>
            <div className="inner section">
              <Heading text="error" id="ins_error-api" level="5" />
              <span>
                Περιέχει στην τιμή του μία σύντομη περιγραφή του σφάλματος σε
                περίπτωση που κάποιο σφάλμα προέκυψε κατά την χρήση της
                υπηρεσίας ή την ειδική τιμή{' '}
                <span className="field-value">null</span> διαφορετικά.
              </span>
            </div>
            <div className="inner section">
              <Heading text="success" id="ins_success-api" level="5" />
              <span>
                Περιέχει την τιμή του <span className="field-value">true</span>{' '}
                σε περίπτωση που δεν έχει υπάρξει σφάλμα κατά την χρήση της
                υπηρεσίας ή την τιμή <span className="field-value">false</span>{' '}
                διαφορετικά.
              </span>
            </div>
          </div>
          <div className="inner section">
            <Heading
              text="Ενδεικτικά Παραδείγματα"
              id="ins_examplesapi"
              level="4"
            />
            <span>
              <div className="inner section">
                <Heading
                  text="Παράδειγμα κλήσης με ένα αποτέλεσμα"
                  id="ins_one-result-example-api"
                  level="5"
                />
                <CodeExample
                  header="Αίτημα:"
                  data={SingleAPIExample.query}
                  indented={false}
                />
                <CodeExample
                  header="Απάντηση:"
                  data={SingleAPIExample.results}
                  indented
                />
              </div>
              <div className="inner section">
                <Heading
                  text="Παράδειγμα κλήσης με πολλαπλά αποτελέσματα"
                  id="ins_multiple-results-example-api"
                  level="5"
                />
                <span>
                  <p>
                    Σε περίπτωση που οι τιμές στα συμπληρωμένα πεδία του
                    αιτήματος αντιστοιχούν σε περισσότερους από έναν χρήστες, η
                    υπηρεσία επιστρέφει τυχαία μέχρι και 10 από αυτούς τους
                    χρήστες σε ένα JSON array.
                  </p>
                  <p>
                    Η υπηρεσία υποστηρίζει επίσης σύνθετη αναζήτηση με βάση το
                    πρότυπο [RFC2254]. Στην παράγραφο 4 του [RFC2254] υπάρχει
                    λίστα με την γραμματική που ακολουθούν τα σύμβολα που η
                    σύνθετη αναζήτηση υποστηρίζει. Στο παρακάτω παράδειγμα
                    αναζητούμε όλα τα διπλώματα που έχουν ληφθεί κατά το έτος
                    2002.
                  </p>
                  <CodeExample
                    header="Αίτημα:"
                    data={MultipleAPIExample.query}
                    indented={false}
                  />
                  <CodeExample
                    header="Απάντηση:"
                    data={MultipleAPIExample.results}
                    indented
                    multipleItems
                  />
                </span>
              </div>
              <div className="inner section">
                <Heading
                  text="Παράδειγμα κλήσης με κανένα αποτέλεσμα"
                  id="ins_no-results-example-api"
                  level="5"
                />
                <CodeExample
                  header="Αίτημα:"
                  data={NoAPIExample.query}
                  indented={false}
                />
                <CodeExample
                  header="Απάντηση:"
                  data={NoAPIExample.results}
                  indented={false}
                />
              </div>
              <div className="inner section">
                <Heading
                  text="Αναφορά Σφαλμάτων"
                  id="ins_error-report-api"
                  level="5"
                />
                <p>
                  Παρακάτω υπάρχουν παραδείγματα πιθανών περιπτώσεων σφάλματος,
                  μαζί με μια αναλυτικότερη περιγραφή για κάθε σφάλμα.
                </p>
                <CodeExample data={InvalidReqExample.results} />
                <p>
                  Εμφανίζεται σε περίπτωση που το JSON έχει κάποιο συντακτικό
                  λάθος.
                </p>
                <CodeExample data={InvalidAuthTokenExample.results} />
                <p>
                  Εμφανίζεται σε περίπτωση που το κλειδί ασφαλείας δεν είναι
                  σωστό.
                </p>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="section">
        <h2 id="ins_support" className="header nav-anchor">
          Εξυπηρέτηση Αιτημάτων
        </h2>
        <p>
          Εφόσον το ίδρυμα το επιλέξει, παρέχεται η δυνατότητα στον κάτοχο ενός
          τίτλου του ιδρύματος να υποβάλλει εξατομικευμένο αίτημα προκειμένου ο
          τίτλος του να γίνει διαθέσιμος μέσω του eDiplomas, αν δεν έχει ήδη
          ενταχθεί. Το αίτημα αυτό καταχωρείται προς εξυπηρέτηση στο αντίστοιχο
          Ίδρυμα - Τμήμα και πλαισιώνεται από στοιχεία που διευκολύνουν την
          αναζήτηση του τίτλου από το προσωπικό των Γραμματειών.
        </p>
        <p>
          Για τη στοχευμένη ανάθεση των αιτημάτων αυτών στο αρμόδιο προσωπικό
          και τελικά για την καλύτερη εξυπηρέτηση των πολιτών, η πλατφόρμα
          πρέπει να έχει γνώση ποια μέλη του ιδρύματος μπορούν να εξυπηρετήσουν
          ποιες κατηγορίες αιτημάτων. Η καταγραφή και παρακολούθηση των
          αιτημάτων των πολιτών γίνεται μέσω της υπηρεσίας eDiplomas Support,
          στην οποία πρόσβαση έχει μόνο το εξουσιοδοτημένο προσωπικό του
          ιδρύματος, χρησιμοποιώντας τον ιδρυματικό λογαριασμό τους.
        </p>
        <div className="inner section">
          <Heading
            text="Πρόσβαση eDiplomas Support"
            id="ins_suspport-access"
            level="3"
          />
          <p>
            Σε τεχνικό επίπεδο η εξουσιοδότηση πρόσβασης στην υπηρεσία eDiplomas
            Support καθορίζεται μέσω των ιδρυματικών υποδομών Single-Sign-On
            κατά την φάση εισόδου του χρήστη στην υπηρεσία. Κάθε ίδρυμα ελέγχει
            την πρόσβαση των χρηστών του στην υπηρεσία eDiplomas Support μέσω
            του attribute edupersonEntitlement. Συγκεκριμένα, το πεδίο
            edupersonEntitlement, πρέπει να έχει τιμές της μορφής:
          </p>
          <div className="example code">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:{'<'}maintainerID{'>'}:{'<'}
            Role{'>'}:{'<'}ISCEDLevel{'>'}
          </div>
          <p>
            <strong>maintainerID</strong>: Αφορά στην πρόσβαση του λογαριασμού
            σε αιτήματα πολιτών, για τίτλους που διαχειρίζεται το τμήμα με αυτόν
            τον κωδικό. Η κωδικοποίηση ακολουθεί αυτή των Πανελλήνιων Εξετάσεων
            Εισαγωγής στα ΑΕΙ (mineduID). Το πεδίο είναι υποχρεωτικό και πρέπει
            να έχει ακριβώς μια τιμή.
          </p>
          <ul>
            <li>
              Για την πρόσβαση σε αιτήματα πολιτών που αφορούν τίτλους
              προγραμμάτων σπουδών που δεν είναι πλέον ενεργοί, χρησιμοποιείται
              ο κωδικός του ενεργού τμήματος που έχει αναλάβει τη διατήρηση του
              αρχείου τίτλων του παλαιότερου μη ενεργού προγράμματος σπουδών.
              Δηλαδή δεν χρησιμοποιείται ο κωδικός του τμήματος που απένειμε τον
              τίτλο, αλλά ο κωδικός του τμήματος που εκδίδει τώρα τις βεβαιώσεις
              για τον τίτλο.
            </li>
            <li>
              Η ειδική τιμή ‘0’ (μηδέν) σηματοδοτεί την πρόσβαση του χρήστη στο
              σύνολο των αιτημάτων που αφορούν το ίδρυμα. Στην περίπτωση αυτή το
              πεδίο ISCEDLevel (βλ παρακάτω) δεν θα πρέπει να έχει τιμή. Όταν η
              τιμή ‘0’ του πεδίου maintainerID συνδυαστεί με οποιαδήποτε τιμή
              του ISCEDLevel, τότε το σύνολο των τιμών του attribute
              edupersonEntitlement για τον χρήστη αγνοούνται.
            </li>
            <li>
              Αν ένας λογαριασμός πρέπει να έχει πρόσβαση σε αιτήματα για
              τίτλους που αφορούν περισσότερα από ένα τμήματα, αυτό μπορεί να
              γίνει με πολλαπλές τιμές του attribute edupersonEntitlement.
              Ωστόσο κάθε τιμή του edupersonEntitlement πρέπει να φέρει ακριβώς
              μία τιμή στο πεδίο maintainerID και μεταξύ όλων των τιμών του
              edupersonEntitlement πρέπει να υπάρχει μοναδική αναφορά στο ίδιο
              maintainerID. Αν ανιχνευτούν περισσότερες από μια τιμές με αναφορά
              στο ίδιο maintainerID, τότε το σύνολο των τιμών του attribute
              edupersonEntitlement για τον χρήστη αγνοούνται.
            </li>
            <li>
              Η εγγραφή για ένα maintainerID παρέχει πρόσβαση στα αιτήματα για
              τίτλους όλων των επιπέδων σπουδών του τμήματος,
              συμπεριλαμβανομένου και των αιτημάτων που αφορούν μεταπτυχιακούς
              τίτλους, εφόσον το επισπεύδον προπτυχιακό τμήμα ταυτίζεται ή είναι
              υπό την διαχείριση του τμήματος, με αυτό τον κωδικό.
            </li>
          </ul>
          <br />
          <p>
            <strong>Role</strong>: καθορίζει τα δικαιώματα του λογαριασμού, όσον
            αφορά στα αιτήματα για τίτλους του τμήματος με κωδικό maintainerID.
            Για τη συμπλήρωση του ‘role’ χρησιμοποιείται συγκεκριμένο πεδίο
            τιμών όπως σημειώνεται ακολούθως. Το πεδίο είναι υποχρεωτικό και
            πρέπει να έχει ακριβώς μια τιμή.
          </p>
          <table className="doctable">
            <thead>
              <tr>
                <th>Role</th>
                <th>Περιγραφή</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>view</td>
                <td>
                  Δίνει τη δυνατότητα στο λογαριασμό να δει τα αιτήματα που
                  αφορούν το συγκεκριμένο maintainerID.
                </td>
              </tr>
              <tr>
                <td>assign</td>
                <td>
                  Περιλαμβάνει τα δικαιώματα του view, και επιπλέον το δικαίωμα
                  να αναθέτει αιτήματα που αφορούν το συγκεκριμένο maintainerID
                  σε άλλο λογαριασμό ή ομάδα.
                </td>
              </tr>
              <tr>
                <td>resolve</td>
                <td>
                  Περιλαμβάνει τα δικαιώματα του view, και επιπλέον το δικαίωμα
                  να εξυπηρετεί αιτήματα που αφορούν το συγκεκριμένο
                  maintainerID.
                </td>
              </tr>
              <tr>
                <td>full</td>
                <td>Δίνει όλα τα παραπάνω δικαιώματα.</td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <strong>ISCEDLevel</strong>: Ενεργοποιεί την επιλογή αυτόματης
            ανάθεσης των αιτημάτων για τίτλους αυτού του επίπεδου σπουδών, που
            αφορούν το συγκεκριμένο maintainerID, στο λογαριασμό του χρήστη
            (MyTickets). Το πεδίο ακολουθεί τη μονοψήφια κωδικοποίηση του
            επιπέδου σπουδών κατά το πρότυπο ISCED 2011, όπως σημειώνεται
            ακολούθως:
          </p>
          <table className="doctable">
            <thead>
              <tr>
                <th>ISCED Level (2011)</th>
                <th>Επίπεδο Τίτλου Σπουδών</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>6</td>
                <td>Τίτλοι Προπτυχιακών Προγραμμάτων Σπουδών</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Τίτλοι Μεταπτυχιακών Προγραμμάτων Σπουδών</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Διδακτορικοί Τίτλοι</td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>
              Το πεδίο ISCEDLevel, δεν επηρεάζει τα δικαιώματα πρόσβασης του
              λογαριασμού, τα οποία καθορίζονται αποκλειστικά από τα πεδία
              maintainerID και role.
            </li>
            <li>
              Όταν το πεδίο ISCEDLevel δεν έχει τιμή, τότε δεν γίνεται αυτόματη
              ανάθεση αιτημάτων στο λογαριασμό. Οποιοσδήποτε χρήστης με ρόλο
              ‘assign’ ή ‘full’ σε ένα αίτημα μπορεί να το αναθέσει στο
              συγκεκριμένο λογαριασμό και αυτός να το εξυπηρετήσει αν έχει το
              ρόλο ‘resolve’ ή ‘full’.
            </li>
            <li>
              Το πεδίο ISCEDLevel μπορεί να λάβει περισσότερες από μια τιμές,
              χωρισμένες με “,” (κόμμα) π,χ “6,8”.
            </li>
          </ul>
        </div>
        <div className="inner section">
          <Heading
            text="Παραδείγματα eduPersonEntitlement"
            id="ins_support-examples"
            level="3"
          />
          <p>
            Παράδειγμα 1: Λογαριασμός ο οποίος μπορεί να εξυπηρετήσει αιτήματα
            του τμήματος ΧΧΧΧΧΧΧ (mineduID = 512). Σε αυτόν, γίνεται αυτόματα
            ανάθεση των αιτημάτων που αφορούν το προπτυχιακό πρόγραμμα σπουδών.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:resolve:6
          </div>
          <p>
            Παράδειγμα 2: Λογαριασμός ο οποίος μπορεί να επιβλέπει αιτήματα όλων
            των τμημάτων του ιδρύματος.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:0:view:
          </div>
          <p>
            Παράδειγμα 3: Λογαριασμός ο οποίος μπορεί να αναθέτει και να
            εξυπηρετεί αιτήματα του τμήματος XXXXXXX (mineduID = 512). Σε αυτόν,
            γίνεται αυτόματα ανάθεση των αιτημάτων που αφορούν το προπτυχιακό
            πρόγραμμα σπουδών και τα διδακτορικά.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:full:6,8
          </div>
          <p>
            Παράδειγμα 4: Λογαριασμός ο οποίος μπορεί να εξυπηρετεί αιτήματα του
            τμήματος XXXXXXX (mineduID = 512) και του τμήματος ΥΥΥΥΥΥ (mineduID
            = 423). Σε αυτόν, γίνεται αυτόματα ανάθεση των αιτημάτων που αφορούν
            το προπτυχιακό πρόγραμμα σπουδών και τα διδακτορικά του τμήματος
            ΧΧΧΧΧΧ και των αιτημάτων που αφορούν τα διδακτορικά του τμήματος
            ΥΥΥΥΥΥ.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:resolve:6,8
            <br />
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:423:resolve:8
          </div>
          <p>
            Παράδειγμα 5: Λογαριασμός ο οποίος μπορεί να επιβλέπει την
            εξυπηρέτηση των αιτημάτων όλων των τμημάτων του ιδρύματος και να
            εξυπηρετεί αιτήματα τίτλων που αφορούν το τμήμα ΧΧΧΧΧ
            (mineduID=512). Σε αυτόν, γίνεται αυτόματα ανάθεση των αιτημάτων που
            αφορούν τα διδακτορικά του τμήματος ΧΧΧΧΧΧ.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:0:view
            <br />
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:full:8
          </div>
          <p>
            Παράδειγμα 6: Λογαριασμός, ο οποίος μπορεί να εξυπηρετεί αιτήματα
            που αφορούν το τμήμα ΧΧΧΧ (mineduID=512). Δεν γίνεται αυτόματη
            ανάθεση αιτημάτων στο λογαριασμό.
          </p>
          <div className="code example">
            urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:resolve:
          </div>
        </div>
        <div className="inner section">
          <Heading
            text="Παραδείγματα μη έγκυρων τιμών"
            id="ins_support-invalid"
            level="3"
          />
          <div>
            <div className="code example">
              urn:mace:gunet.gr:ediplomas.gr:helpdesk:0:full:6
            </div>
            (maintainerID=0 και συμπληρωμένο ISCEDLevel)
            <br />
            <div className="code example">
              urn:mace:gunet.gr:ediplomas.gr:helpdesk:512::6
            </div>
            (κενή τιμή στο πεδίο role)
            <br />
            <div className="code example">
              urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:resolve:67
            </div>
            (μη έγκυρη τιμή στο πεδίο ISCEDLevel)
            <br />
            <div className="code example">
              urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:full:6
              <br />
              urn:mace:gunet.gr:ediplomas.gr:helpdesk:512:resolve:7
            </div>
            (πολλαπλή αναφορά στο ίδιο maintainerID)
          </div>
        </div>
      </div>
    </>
  );
};
