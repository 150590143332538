import React from 'react';
import OrganisationInfoEn from './en/OrganisationInfoEn';
import OrganisationInfoEl from './el/OrganisationInfoEl';

const OrganisationInfo = (props) => {
  if (props.polyglot.currentLocale === 'el') {
    return <OrganisationInfoEl idPrefix={props.idPrefix} />;
  }
  return <OrganisationInfoEn idPrefix={props.idPrefix} />;
};
export default OrganisationInfo;
