export default {
  Graduate: {
    graduate: 'Κάτοχος Τίτλου Σπουδών',
    step1: {
      title: 'Βήμα 1',
      text: 'Επιλογή Φορέα'
    },
    step2: {
      title: 'Βήμα 2',
      text: 'Επιλογή Τίτλων'
    },
    step3: {
      title: 'Βήμα 3',
      text: 'Αποστολή Συνδέσμου'
    },
    // Step 1
    title1: 'Ποιος θέλετε να έχει πρόσβαση;',
    paragraph1a:
      'Επιλέξτε σε ποιο φορέα θα δώσετε πρόσβαση στους τίτλους σπουδών σας. Μπορείτε να επιλέξετε το "Κάθε ταυτοποιημένος χρήστης" για να δώσετε πρόσβαση σε οποιονδήποτε, με TAXISnet λογαριασμό, στείλετε το σύνδεσμο.',
    paragraph1b: 'Δείτε στο',
    paragraph1c: 'ιστορικό εξουσιοδοτήσεων',
    paragraph1d:
      'αν υπάρχει ήδη ενεργή εξουσιοδότηση για το φορέα που μπορεί να χρησιμοποιηθεί.',
    placeholder1: 'Επιλογή Φορέα...',
    organization: 'Επιλεγμένος φορέας:',
    back: 'Πίσω',
    continue: 'Συνέχεια',
    // Step2
    title2: 'Σε ποια στοιχεία θέλετε να έχει πρόσβαση;',
    paragraph2:
      'Συνδεθείτε με τον TAXISnet λογαριασμό σας για να δείτε και να επιλέξετε τα στοιχεία των τίτλων σας για τα οποία θα δώσετε εξουσιοδότηση πρόσβασης.',
    bullet1: 'Μετάβαση και σύνδεση στο TAXISnet.',
    bullet2: 'Επιλογή στοιχείων που θα γνωστοποιηθούν.',
    // Step 3
    title3: 'Στείλτε τον σύνδεσμο πρόσβασης',
    paragraph3a:
      'Παρακάτω δίνεται ο σύνδεσμος που θα πρέπει να στείλετε στον φορέα',
    paragraph3b: 'για να κατεβάσει τα στοιχεία των τίτλων σπουδών σας.',
    forgotToLogOutTaxis: 'Ξεχάσατε να αποσυνδεθείτε από το TAXISnet;',
    wantToLogOutTaxis: 'Για να αποσυνδεθείτε ακολουθήστε τον παρακάτω σύνδεσμο',
    taxisLogout: 'Αποσύνδεση από το TAXISnet',
    warning1:
      'Για την ασφάλειά σας αποσυνδεθείτε και από το TAXISnet αν έχετε ολοκληρώσει τις εργασίες σας σε αυτό το σταθμό εργασίας.',
    warning2:
      'Ενδέχεται να παραμείνετε συνδεδεμένοι σε άλλες εφαρμογές που χρησιμοποίησαν τη λειτουργία Εισόδου μέσω TAXISnet πριν κάνετε αποσύνδεση.',
    token: 'Κωδικός',
    QRCode: 'Κωδικός QR',
    show: 'Εμφάνιση',
    hide: 'Απόκρυψη',
    duration: 'Διάρκεια',
    paragraph4: 'Ο σύνδεσμος θα παραμείνει ενεργός μέχρι:',
    codeCopied: 'Ο σύνδεσμος αντιγράφηκε'
  },
  Landing: {
    category1: 'Είμαι κάτοχος τίτλου σπουδών',
    paragraph1:
      'Θέλω να δω ή να εξουσιοδοτήσω την πρόσβαση σε πληροφορίες των τίτλων σπουδών μου',
    category2: 'Είμαι εκπρόσωπος φορέα',
    paragraph2: 'Θέλω να αποκτήσω πρόσβαση σε στοιχεία τίτλων σπουδών',
    continue: 'Συνέχεια',
    showMore: 'Θέλω να μάθω περισσότερα',
    backToTop: 'Επιστροφή στην κορυφή',
    ImGrad: 'Eίμαι κάτοχος τίτλου σπουδών',
    ImOrg: 'Eίμαι Φορέας',
    continueAs: 'Συνέχεια ως',
    logout: 'Αποσύνδεση',
    contents: {
      title: 'Περιεχόμενα',
      bullet1: 'Περιγραφή',
      bullet2: 'Κάτοχοι τίτλων σπουδών',
      bullet3: 'Φορείς'
    },
    Cards: {
      title1: 'Ασφάλεια',
      paragraph1:
        'Με τη χρήση τεχνολογιών ψηφιακής υπογραφής και κρυπτογράφησης, βεβαιώνεται η αυθεντικότητα των στοιχείων του τίτλου και παρέχεται πρόσβαση μόνο σε εξουσιοδοτημένους φορείς.',
      title2: 'Ευκολία',
      paragraph2:
        'Με λίγα μόνο κλικ και με τη χρήση των κωδικών του TAXISnet, ο χρήστης εξουσιοδοτεί κάποιον τρίτο να αντλήσει δεδομένα για τους τίτλους σπουδών που κατέχει.',
      title3: 'Διαλειτουργικότητα',
      paragraph3:
        'Φορείς με δικές τους εφαρμογές υποβολής στοιχείων υποψηφίων μπορούν να συνδεθούν στο eDiplomas με χρήση καλά ορισμένων διεθνών προτύπων.'
    },
    title1: 'Περιγραφή',
    paragraph3:
      'Μέσω του eDiplomas ο πολίτης κάτοχος τίτλου σπουδών από ελληνικό ΑΕΙ μπορεί, χρησιμοποιώντας το λογαριασμό του στο TAXISnet, να παραχωρήσει την άδεια σε ένα φορέα (δημόσιο οργανισμό ή εταιρεία) να λάβει πληροφορίες για τους τίτλους των σπουδών του.  Η παραχώρηση των στοιχείων τίτλου σε τρίτους γίνεται πάντα ',
    paragraph3extra:
      'μετά από τη ρητή συγκατάθεση του πολίτη και αυτή αφορά κάθε φορά συγκεκριμένο αποδέκτη και σύνολο δεδομένων',
    paragraph4:
      'Η πλατφόρμα χρησιμοποιεί τεχνολογίες που εξασφαλίζουν τόσο την προστασία των προσωπικών στοιχείων του πολίτη, όσο και την αυθεντικότητα των στοιχείων των τίτλων σπουδών. Κάνοντας την διαδικασία υποβολής και βεβαίωσης του τίτλου σπουδών απλή και γρήγορη για τον κάτοχο αλλά και για τον φορέα, επιχειρείται ταυτόχρονα και η εξάλειψη του φαινομένου των πλαστών και παραποιημένων τίτλων.',
    paragraph5:
      'Το eDiplomas έχει υλοποιηθεί από τα ΑΕΙ της χώρας και το Ακαδημαϊκό Διαδίκτυο (GUnet). Εξελίσσεται και βελτιώνεται συνεχώς ενσωματώνοντας νέες λειτουργίες, αξιοποιώντας την τεχνογνωσία και τις ψηφιακές υποδομές των ιδρυμάτων της χώρας.',
    title2: 'Κάτοχοι τίτλων σπουδών',
    paragraph6:
      'Αποφύγετε την διαδικασία έκδοσης αντιγράφων τίτλων σπουδών και αποστολής - κατάθεσής τους σε τρίτους (π.χ. μελλοντικοί εργοδότες). Κάντε login, χρησιμοποιώντας τους κωδικούς σας στο TAXISnet και επιλέξτε εύκολα και γρήγορα ποιοι μπορούν να έχουν πρόσβαση και σε ποια στοιχεία των τίτλων σπουδών σας.',
    title3: 'Φορείς',
    paragraph7a: 'Συνδεθείτε στο',
    paragraph7b:
      'χρησιμοποιώντας το λογαριασμό TAXISnet του φορέα, προσκαλέστε μέσω email τα μέλη του φορέα που θα έχουν πρόσβαση στις υπηρεσίες eDiplomas για φορείς και αποκτήστε εύκολα και γρήγορα πρόσβαση σε αξιόπιστα στοιχεία τίτλων σπουδών.',
    presentations: 'Μάθετε περισσότερα για το eDiplomas',
    eDiplomas: 'eDiplomas',
    presentationFilename: 'Παρουσίαση',
    demoFilename: 'Demo',
    documentationName: 'Τεκμηρίωση',
    priviledge2019: 'PRIViLEDGE Project Blog 11/2019',
    priviledge2020: 'PRIViLEDGE Project Blog 11/2020',
    emrex: 'Το eDiplomas στο δίκτυο EMREX',
    videoEBSI: 'EBSI Multi University Pilot 04/03/2022',
    blueprintsInfo: 'eDiplomas Blueprints 10/01/2022',
    jadesInfo: 'JAdES Plugtest 11/2021 - 01/2022',
    articleEBSI:
      'EBSI: Cypriot Citizen’s journey as a Greek University graduate 12/2021',
    articlesTitle: 'Άρθρα & Ειδήσεις',
    presentationsTitle: 'Παρουσιάσεις',
    presentationGunet: 'GUnet Technical Meeting 6/2019',
    presentationEUNIS: 'EUNIS 2020 Virtual Helsinki 6/2020',
    presentationΗμερίδα: 'Ημερίδα eDiplomas Χανιά Κρήτη (Greek) 11/2020',
    presentationUoi:
      'GUnet Technical Meeting - Παρουσίαση Πανεπιστημίου Ιωαννίνων 06/2022',
    interactivePresentation: 'Διαδραστική Παρουσίαση eDiplomas 4/2022',
    blueprintsNewsHeader:
      'Διασύνδεση στην πλατφόρμα eDiplomas Blueprints και καταχώριση πρότυπων τίτλων ΑΕΙ (10/01/2022 - 28/02/2022)',
    blueprintsNews1: 'Όλα τα ΑΕΙ καλούνται να καταχωρίσουν στην υπηρεσία',
    blueprintsNews2:
      'τους πρότυπους τίτλους των προπτυχιακών προγραμμάτων σπουδών που έχουν ανακηρύξει πτυχιούχους από 1/1/2010.',
    news: 'Νέα'
  },
  Organisation: {
    organisation: 'Φορέας',
    taxis: {
      taxisLogin: 'Διασύνδεση με TAXISnet',
      accessGranted:
        'Η πρόσβαση στις υπηρεσίες eDiplomas για φορείς παρέχεται:',
      registeredEntity:
        'στους ορισμένους εκπροσώπους του φορέα ανά υπηρεσία (αφορά μόνο εγγεγραμμένους φορείς)',
      taxisUsers:
        'σε κάθε πρόσωπο, αν ο κάτοχος του τίτλου έχει επιλέξει τον ειδικό τύπο εξουσιοδότησης σε "Κάθε ταυτοποιημένο χρήστη"',
      accessComment:
        'Η πρόσβαση γίνεται μέσω του λογαριασμού TAXISnet του εκπροσώπου/φυσικού προσώπου.',
      doLogin: 'Σύνδεση'
    },
    tokenEntry: {
      diplomasByAuthorization: 'Τίτλοι Σπουδών μέσω Εξουσιοδότησης',
      searchHeader:
        'Αποκτήστε πρόσβαση με χρήση του κωδικού που σας έχει σταλεί.',
      hint: 'Απαιτείται η έκδοση του κωδικού από τον κάτοχο του Τίτλου.',
      paragraph1point1:
        'Για να λάβετε τις πληροφορίες που σχετίζονται με τον τίτλο σπουδών ενός πολίτη, πρέπει πρώτα ο πολίτης να εγκρίνει την πρόσβαση στα στοιχεία του. Στη συνέχεια, ο πολίτης θα λάβει έναν ',
      paragraph1point2: 'κωδικό',
      paragraph1point3: ', τον οποίο πρέπει να εισάγετε στην παρακάτω φόρμα.',
      label: 'Κωδικός',
      downloadUntil:
        'Μπορείτε να κατεβάσετε τις βεβαιώσεις των παρακάτω τίτλων μέχρι',
      codeNote: 'Κωδ. Εξουσιοδότησης',
      searchButton: 'Αναζήτηση',
      errorMessageHeader: 'Ωχ!',
      errorMessageInvalidOrExpired:
        'Ο κωδικός που δώσατε είναι λανθασμένος, έχει λήξει ή δεν έχετε εξουσιοδότηση πρόσβασης από το φορέα για τον οποίο έχει εκδοθεί.',
      errorMessageGeneral: 'Κάτι πήγε στραβά. Δοκιμάστε ξανά αργότερα.',
      signatureCopied: 'Το αναγνωριστικό επαλήθευσης αντιγράφηκε',
      newSearch: 'Αναζήτηση με νέο κωδικό'
    },
    logout: {
      logout: 'Αποσύνδεση',
      haveLoggedOut: 'Έχετε αποσυνδεθεί από το eDiplomas.',
      wantToLogOutTaxis:
        'Αν επιθυμείτε να αποσυνδεθείτε από το TAXISnet ακολουθείστε τον παρακάτω σύνδεσμο',
      taxisLogout: 'Αποσύνδεση από TAXISnet',
      warning1:
        'Για την ασφάλειά σας αποσυνδεθείτε και από το TAXISnet αν έχετε ολοκληρώσει τις εργασίες σας σε αυτό το σταθμό εργασίας.',
      warning2:
        'Ενδέχεται να παραμείνετε συνδεδεμένοι σε άλλες εφαρμογές που χρησιμοποίησαν τη λειτουργία Εισόδου μέσω TAXISnet πριν κάνετε αποσύνδεση.',
      backHome: 'Επιστροφή στην αρχική'
    },
    taxisLogout: {
      logout: 'Αποσύνδεση από TAXISnet',
      haveLoggedOut: 'Έχετε αποσυνδεθεί από το TAXISnet με επιτυχία.',
      goBackHome: 'Επιστροφή στην Αρχική'
    },
    menu: {
      diplomasByAuthorization: 'Τίτλοι Σπουδών μέσω Εξουσιοδότησης',
      getAccessToDegreesInfo:
        'Αποκτήστε πρόσβαση στις πληροφορίες των τίτλων σπουδών για τους οποίους έχετε λάβει εξουσιοδότηση μέσω του',
      signatureVerification: 'Έλεγχος Εγκυρότητας Βεβαίωσης',
      verifySignatureValidityDiplomasIssuedBy:
        'Ελέγξτε την εγκυρότητα των αναγνωριστικών επαλήθευσης των τίτλων σπουδών που έχουν εκδοθεί από το',
      ediplomas: 'eDiplomas',
      diplomaCheck: 'Αυτεπάγγελτος Έλεγχος Τίτλων Σπουδών',
      verifyDegreeAuthenticity:
        'Επιβεβαιώστε την εγκυρότητα αντιγράφων τίτλων σπουδών που έχουν κατατεθεί στο φορέα και δεν έχουν εκδοθεί από το',
      onlyPublicSector: '(μόνο φορείς δημοσίου)'
    },
    menuGraduate: {
      authorizeDiploma: 'Εξουσιοδότηση Τίτλου Σπουδών',
      newAuthorizationInfo:
        'Εξουσιοδοτήστε την πρόσβαση σε πληροφορίες που αφορούν τον τίτλο σπουδών σας.',
      viewDiplomas: 'Τίτλοι Σπουδών',
      controlDiplomas:
        'Αναζητήστε και κατεβάστε τη βεβαίωση του τίτλου σπουδών σας.',
      viewAuthorizations: 'Ιστορικό Εξουσιοδοτήσεων',
      controlAuthorizations:
        'Δείτε τις εξουσιοδοτήσεις που έχετε δώσει και το ιστορικό προσπέλασης των στοιχείων των τίτλων σας.'
    },
    check: {
      inspect: 'Αυτεπάγγελτος έλεγχος τίτλων σπουδών',
      inspectDescription:
        'Με την χρήση της υπηρεσίας αυτεπάγγελτου ελέγχου τίτλου σπουδών, μπορείτε να επιβεβαιώσετε την εγκυρότητα αντιγράφων τίτλων σπουδών που έχετε στη διάθεση σας, εφόσον το εκάστοτε ίδρυμα σας το επιτρέπει.',
      oops: 'Σφάλμα',
      moreThanOneClients:
        'Είστε ήδη συνδεδεμένος στην εφαρμογή μέσω ενός φορέα',
      moreClientsAdvice:
        'Δοκιμάστε να αποσυνδεθείτε από αυτόν για να συνεχίσετε',
      notAuthorizedToUse:
        'Φαίνεται πως δεν έχετε τα απαραίτητα δικαιώματα πρόσβασης στην υπηρεσία',
      backHome: 'Επιστροφή στην αρχική',
      inspectShort: 'Αυτεπάγγελτου Ελέγχου',
      byFilling: 'Συμπληρώνοντας το',
      institution: 'Ίδρυμα',
      ssn: 'ΑΜΚΑ',
      the: 'τον',
      andOtherYouCanGet:
        'καθώς και διάφορα στοιχεία επιβεβαίωσης που σχετίζονται με τον τίτλο σπουδών, μπορείτε να το αναζητήσετε στην πλατφόρμα και να λάβετε την',
      signed: 'υπογεγραμμένη',
      digital: 'ψηφιακή',
      editionFrom: 'έκδοση του από το',
      change: 'Αλλαγή',
      institutionSelect: 'Επιλογή Ιδρύματος',
      department: 'Τμήμα',
      level: 'Επίπεδο',
      fullname: 'Ονοματεπώνυμο',
      date: 'Ημερομηνία',
      firstname: 'Όνομα',
      lastname: 'Επώνυμο',
      wrongDate: 'Λανθασμένη ημερομηνία',
      theLaw:
        'Έχω λάβει γνώση ότι κάθε πράξη αναζήτησης υπόκειται σε νομικό έλεγχο σύμφωνα με τον Γενικό Κανονισμό για την Προστασία Δεδομένων (ΕΕ) 2016/679. Εν προκειμένω πρέπει κάθε αναζήτηση να τεκμηριώνεται από στοιχεία τίτλου ο οποίος έχει κατατεθεί στο φορέα.',
      search: 'Αναζήτηση',
      continue: 'Συνέχεια',
      searchResults: 'Αποτελέσματα αναζήτησης',
      successfulFetch: 'Το Ίδρυμα απάντησε επιτυχώς',
      unsuccessfulFetch: 'Αδυναμία επικοινωνίας με το Ίδρυμα',
      notFound: 'Δεν βρέθηκε πτυχίο με τα στοιχεία που καταχωρήσατε.',
      note: 'Σημείωση',
      maybeNotSubmittedAt:
        'Ενδέχεται το πτυχίο που αναζητήσατε να μην έχει καταχωρηθεί ακόμα στο',
      byInstitution: 'από το αντίστοιχο ίδρυμα',
      info: 'Πληροφορίες για τα ιδρύματα και τους διαθέσιμους τίτλους στο',
      canBeFoundAt: 'βρίσκονται στον σύνδεσμο',
      gradInfo: 'Στοιχεία πτυχίου/πτυχιούχου',
      dateInfo: 'Ημερομηνία έκδοσης ή έναρξης ισχύος τίτλου σπουδών',
      notAuthorized:
        'Δεν έχετε δικαίωμα αυτεπάγγελτου ελέγχου για το επιλεγμένο Ίδρυμα',
      bachelor: 'Προπτυχιακό',
      master: 'Μεταπτυχιακό',
      doctorate: 'Διδακτορικό',
      option: 'Επιλογή'
    },
    loginError: {
      loginFailed: 'Η σύνδεση απέτυχε!',
      taxisSuccessFulButTin:
        'Η σύνδεση στο TAXIS ήταν επιτυχής, αλλά το ΑΦΜ σας',
      notAuthorized: 'δεν είναι εξουσιοδοτημένο',
      forEdiplomasUse: 'για χρήση της εφαρμογής eDiplomas',
      backHome: 'Επιστροφή στην αρχική'
    },
    degree: 'Τίτλος σπουδών',
    degrees: 'Τίτλοι σπουδών',
    print: 'Εκτύπωση',
    pdfVersion: 'Λήψη PDF',
    close: 'Κλείσιμο',
    copy: 'Αντιγραφή',
    Qr: 'Κωδικός QR',
    Text: 'Κείμενο',
    navigateToMenu: 'Μετάβαση στο μενού',
    dateFollowsFormat: 'Η ημερομηνία έχει την μορφή ΗΗ:ΜΜ:ΕΕΕΕ'
  },
  AdoptionRates: {
    participatingInstitutions: 'Συμμετέχοντα Ιδρύματα',
    participatingInstitution: 'Συμμετέχον ΄Ιδρυμα',
    registeredDiplomas: 'Καταχωρισμένοι Τίτλοι',
    comments: 'Σχόλια',
    contact: 'Επικοινωνία',
    requestServing: 'Εξυπηρέτηση Αιτημάτων',
    signedPdf: 'Παροχή Υπ/μένου PDF',
    yes: 'Ναι',
    no: 'Όχι',
    diplomas: {
      undergraduate: 'Προπτυχιακά',
      postgraduate: 'Μεταπτυχιακά',
      doctorate: 'Διδακτορικά'
    },
    contactInfo: {
      web: 'Ιστός',
      telephone: 'Τηλέφωνο',
      email: 'E-mail'
    },
    betaAdopters: 'Διαδικασία Προένταξης',
    betaAdoptersLink: 'Ιδρύματα σε διαδικασία προένταξης'
  },
  GeneralError: {
    oops: '',
    somethingWentWrong: 'Κάτι πήγε στραβά',
    tryLater: 'Δοκιμάστε ξανά αργότερα.',
    goBackHome: 'Επιστροφή στην Αρχική',
    tryAgain: 'Προσπαθήστε ξανά'
  },
  diplomaFields: {
    gradeValue: 'Βαθμός (Τιμή)',
    gradeDescription: 'Βαθμός (Περιγραφή)',
    date: 'Ημερομηνίες',
    issuerInstitution: 'Ίδρυμα έκδοσης',
    maintainerInstitution: 'Υπεύθυνο Ίδρυμα',
    issuerUnit: 'Τμήμα έκδοσης',
    level: 'Επίπεδο τίτλου σπουδών',
    title: 'Όνομα τίτλου σπουδών',
    validFrom: 'Έναρξης ισχύος τίτλου',
    dateIssued: 'Έκδοσης τίτλου',
    ownerInfo: 'Στοιχεία Κατόχου',
    firstNameEl: 'Όνομα',
    middleNameEl: 'Μεσαίο Όνομα',
    lastNameEl: 'Επώνυμο',
    parentNames: 'Ονόματα Γονέων',
    birthDate: 'Ημερομηνία γέννησης',
    fatherNameEl: 'Πατρώνυμο',
    motherNameEl: 'Μητρώνυμο',
    signature: 'Αναγνωριστικό επαλήθευσης',
    signedAt: 'Χρονοσήμανση',
    signedFor: 'Προς',
    notes: 'Σημειώσεις Τίτλου'
  },
  educationalLevels: {
    6: '6 - Προπτυχιακές σπουδές ή ισοδύναμο',
    64: '64 - Academic',
    641: '641 - Insufficient for level completion',
    645: '645 - Προπτυχιακές σπουδές (3–4 έτη)',
    646: '646 - Εκτεταμένες προπτυχιακές σπουδές (πάνω από 4 έτη)',
    647: '647 - Δεύτερο ή συμπληρωματικό πτυχίο (μετά από προπτυχιακές ή ισοδύναμες σπουδές)',
    65: '65 - Professional',
    651: '651 - Insufficient for level completion',
    655: '655 - Προπτυχιακές σπουδές (3–4 έτη)',
    656: '656 - Εκτεταμένες προπτυχιακές σπουδές (πάνω από 4 έτη)',
    657: '657 - Δεύτερο ή συμπληρωματικό πτυχίο (μετά από προπτυχιακές ή ισοδύναμες σπουδές)',
    66: '66 - Orientation unspecified',
    661: '661 - Insufficient for level completion',
    665: '665 - Προπτυχιακές σπουδές (3–4 έτη)',
    666: '666 - Εκτεταμένες Προπτυχιακές Σπουδές (πάνω από 4 έτη)',
    667: '667 - Δεύτερο ή συμπληρωματικό πτυχίο (μετά από προπτυχιακές ή ισοδύναμες σπουδές)',
    7: '7 - Μεταπτυχιακές σπουδές ή ισοδύναμο',
    74: '74 - Academic',
    741: '741 - Insufficient for level completion',
    746: '746 - Προπτυχιακές σπουδές με ενσωματωμένο τίτλο μεταπτυχιακού (τουλάχιστον 5 έτη)',
    747: '747 - Δεύτερο ή συμπληρωματικό πτυχίο (μετά από προπτυχιακές ή ισοδύναμες σπουδές)',
    748: "748 - Second or further degree (following a master's or equivalent programme)",
    75: '75 - Professional',
    751: '751 - Insufficient for level completion',
    756: '756 - Προπτυχιακές σπουδές με ενσωματωμένο τίτλο μεταπτυχιακού (τουλάχιστον 5 έτη)',
    757: '757 - Δεύτερο ή συμπληρωματικό πτυχίο (μετά από προπτυχιακές ή ισοδύναμες σπουδές)',
    758: "758 - Second or further degree (following a master's or equivalent programme)",
    76: '76 - Orientation unspecified',
    761: '761 - Insufficient for level completion',
    766: '766 - Προπτυχιακές σπουδές με ενσωματωμένο τίτλο μεταπτυχιακού (τουλάχιστον 5 έτη)',
    767: '767 - Δεύτερο ή συμπληρωματικό πτυχίο (μετά από προπτυχιακές ή ισοδύναμες σπουδές)',
    768: "768 - Second or further degree (following a master's or equivalent programme) ",
    8: 'Doctoral or equivalent'
  },
  Verifier: {
    signatureVerification: 'Έλεγχος Εγκυρότητας Βεβαίωσης',
    verify: 'Έλεγχος',
    invalidInput: 'Η μορφή του αναγνωριστικού επαλήθευσης δεν είναι έγκυρη.',
    invalidSignature:
      'Αδυναμία ελέγχου του αναγνωριστικό επαλήθευσης. Δεν έχει εκδοθεί από το eDiplomas ή δεν έχει εκδοθεί για το φορέα σας.',
    errorHeader: 'Αδυναμία επιβεβαίωσης αναγνωριστικού επαλήθευσης',
    enterSignature:
      'Εισάγετε το αναγνωριστικό επαλήθευσης του τίτλου σπουδών για έλεγχο της εγκυρότητας του',
    signature: 'Αναγνωριστικό επαλήθευσης τίτλου σπουδών',
    notMatchingHeader: 'Έγκυρο αναγνωριστικό επαλήθευσης',
    notMatchingContent: 'Το περιεχόμενο του πτυχίου έχει αλλάξει',
    newCheck: 'Έλεγχος άλλου αναγνωριστικού επαλήθευσης'
  },
  TermsOfUse: {
    termsOfUse: 'Όροι Χρήσης',
    termsParagraph1:
      'Η GUnet λαμβάνει όλα τα απαραίτητα μέτρα για να εξασφαλίσει τη σωστή λειτουργία της υπηρεσίας. Η GUnet και τα συνδεόμενα ΑΕΙ δεν ευθύνονται για οποιαδήποτε ζημία (άμεση ή έμμεση, θετική ή αποθετική) που τυχόν θα υποστεί ο χρήστης της υπηρεσίας από τη χρήση ή την αδυναμία χρήσης αυτής, από καθυστερήσεις κατά τη χρήση αυτής, από λάθη ή παραλείψεις σε αυτή, από διακοπές της σύνδεσης, βλάβες του δικτύου ηλεκτροδότησης ή για άλλους λόγους ',
    termsParagraph2:
      'Η είσοδος στην υπηρεσία γίνεται με χρήση των προσωπικών κωδικών TAXISnet του κάθε χρήστη. Ο χρήστης που επιθυμεί να χρησιμοποιήσει την εν λόγω υπηρεσία, είναι υπεύθυνος για κάθε υποβολή στοιχείων ή εξουσιοδότηση που πραγματοποιεί.',
    termsParagraph3:
      'Ο χρήστης που επιθυμεί να χρησιμοποιήσει την εν λόγω υπηρεσία, οφείλει να παρέχει αληθείς, ακριβείς, έγκυρες και πλήρεις πληροφορίες κατά τη χρήση της.',
    termsParagraph4:
      'Οι πληροφορίες που αφορούν στοιχεία των τίτλων σπουδών που παρουσιάζονται στην υπηρεσία παρέχονται με απευθείας σύνδεση στα σχετικά πληροφοριακά συστήματα των ΑΕΙ. Η GUnet λαμβάνει όλα τα απαραίτητα μέτρα για να εξασφαλίσει την ακρίβεια των πληροφοριών που δημοσιεύονται στην υπηρεσία, ωστόσο η  GUnet είναι υπεύθυνη μόνο για την αναδημοσίευση των στοιχείων και σε καμία περίπτωση δεν ευθύνεται για το περιεχόμενό τους, ούτε είναι αρμόδια για την παροχή οποιουδήποτε είδους διευκρινίσεων ή επεξηγήσεων επί των πληροφοριών αυτών. Αρμόδια για αυτά είναι τα συνδεμένα ΑΕΙ που παρέχουν τις πρωτογενείς πληροφορίες.',
    termsParagraph5:
      'Όλα τα στοιχεία που παρέχει ο χρήστης της υπηρεσίας δεν χρησιμοποιούνται για άλλο σκοπό πέραν αυτού που περιγράφει η συγκεκριμένη υπηρεσία. Η ακρίβεια των στοιχείων που υποβάλλει ο χρήστης είναι ευθύνη του ιδίου και οποιαδήποτε ζημία (άμεση ή έμμεση, θετική ή αποθετική) προέρχεται από υποβολή ανακριβών στοιχείων βαρύνει τον ίδιο το χρήστη και σε καμία περίπτωση τη GUnet ή τα συνδεόμενα ΑΕΙ.',
    termsParagraph6:
      'Η υπηρεσία δεν αποθηκεύει στοιχεία των τίτλων σπουδών του χρήστη ή προσωπικά στοιχεία ή κωδικούς πρόσβασης του χρήστη αλλά μόνο τον ΑΜΚΑ που αποτελεί βασικό στοιχείο για την υλοποίηση της λειτουργικότητας της υπηρεσίας. Το στοιχείο αυτό αποθηκεύεται για προσωρινό διάστημα, είναι κρυπτογραφημένο, και είναι στην ευχέρεια του χρήστη να ζητήσει την διαγραφή του, εφόσον προχωρήσει σε ανάκληση όλων των εξουσιοδοτήσεων προς φορείς.',
    termsParagraph7Part1:
      'Η υπηρεσία χρησιμοποιεί μόνο session cookies για την πρόσβαση του χρήστη μέσω των κωδικών TAXISnet στην υπηρεσία. Τα cookies είναι μικρά αρχεία κειμένου που αποθηκεύονται στο σκληρό δίσκο κάθε χρήστη και περιλαμβάνουν μη προσωπικά στοιχεία αναγνώρισης των χρηστών του διαδικτυακού τόπου και δεν λαμβάνουν γνώση οποιουδήποτε εγγράφου ή αρχείου του υπολογιστή του. Τα cookies αυτά διαγράφονται αυτόματα μετά την έξοδο από την υπηρεσία. Ο χρήστης της υπηρεσίας μπορεί να',
    termsParagraph7Part2: 'ρυθμίσει',
    termsParagraph7Part3:
      'το πρόγραμμα του για πλοήγηση στο Διαδίκτυο κατά τέτοιο τρόπο ώστε είτε να τον προειδοποιεί για τη χρήση των "cookies" σε συγκεκριμένες υπηρεσίες είτε να μην επιτρέπει την αποδοχή της χρήσης "cookies" σε κάθε περίπτωση. Σημειώνεται ότι σε περίπτωση που ο χρήστης δεν επιθυμεί τη χρήση "cookies" για την αναγνώρισή του δεν θα είναι δυνατή η σύνδεση του στην υπηρεσία μέσω των κωδικών του στο TAXISnet.'
  },
  Documentation: {
    documentation: 'Τεκμηρίωση',
    developers: 'Προγραμματιστές',
    connectionThirdParties: 'Διασύνδεση εφαρμογών τρίτων με το eDiplomas',
    institutions: 'Ιδρύματα',
    institutionIntegration: 'Ένταξη Ιδρυμάτων στο eDiplomas',
    descriptionParagraph1:
      'Το eDiplomas αφορά σε μια νέα δικτυακή πλατφόρμα βεβαίωσης κτήσης τίτλου σπουδών ΑΕΙ. Μέσω αυτής, ο πολίτης κάτοχος τίτλου σπουδών από ελληνικό ΑΕΙ μπορεί, χρησιμοποιώντας τον λογαριασμό του στο TAXISnet, να παραχωρήσει την άδεια σε ένα φορέα (δημόσιο οργανισμό ή εταιρεία) να λάβει πληροφορίες για τους τίτλους των σπουδών του. Η πλατφόρμα χρησιμοποιεί τεχνολογίες που εξασφαλίζουν τόσο την προστασία των προσωπικών στοιχείων του πολίτη, όσο και την αυθεντικότητα των στοιχείων των τίτλων σπουδών. Κάνοντας την διαδικασία υποβολής και βεβαίωσης του τίτλου σπουδών απλή και γρήγορη για τον κάτοχο αλλά και για τον φορέα, επιχειρείται ταυτόχρονα και η εξάλειψη του φαινομένου των πλαστών και παραποιημένων τίτλων.',
    descriptionParagraph2:
      'Το eDiplomas αποτελείται από επιμέρους πληροφοριακά συστήματα, καθένα από τα οποία επιτελεί ένα συγκεκριμένο έργο και παρέχει την αντίστοιχη διεπαφή διασύνδεσης προς τρίτους.',
    organisations: 'Φορείς',
    organisationRegistration: 'Εγγραφή φορέων στις υπηρεσίες του eDiplomas.gr',
    backToTop: 'Επιστροφή στην κορυφή',
    navbar: {
      contents: 'Περιεχόμενα',
      documentation: 'Αρχική',
      developers: 'Προγραμματιστές',
      institutions: 'Ιδρύματα',
      organisations: 'Φορείς',
      oauthIntegrationGuide: 'Διασύνδεση τρίτων υπηρεσιών (OAuth2)',
      description: 'Περιγραφή',
      quickStart: 'Quick Start',
      orgClients: 'Υπηρεσίες eDiplomas για Φορείς',
      scopes: 'Scopes',
      clientRegistration: 'Εγγραφή πελάτη',
      oauth2: 'OAuth2',
      accessToDegree: 'Πρόσβαση σε τίτλο σπουδών',
      customClientAuthFlowDesc: 'Εξουσιοδότηση για custom clients',
      authProcess: 'Διαδικασία εξουσιοδότησης',
      tokenRenewal: 'Ανανέωση token',
      degreeTemplateCreation: 'Δημιουργία προτύπων τίτλων σπουδών',
      generally: 'Γενικά',
      indicativeExample: 'Ενδεικτικό Παράδειγμα',
      gradDataInsert: 'Εισαγωγή δεδομένων πτυχιούχων',
      throughView: 'Μέσω View',
      schema: 'Σχήμα',
      fields: 'Πεδία',
      throughAPI: 'Μέσω API',
      indicativeExamples: 'Ενδεικτικά Παραδείγματα',
      requestService: 'Εξυπηρέτηση Αιτημάτων',
      ediplomasSupportAccess: 'Πρόσβαση eDiplomas Support',
      eduPersonEntitlementExamples: 'Παραδείγματα eduPersonEntitlement',
      notValidValuesExamples: 'Παραδείγματα μη έγκυρων τιμών',
      introduction: 'Εισαγωγή',
      basicConcepts: 'Βασικές Έννοιες',
      orgExOfficio: 'Εγγραφή Φορέα για χρήση Αυτεπάγγελτου Ελέγχου',
      orgClientNoApp: 'Εγγραφή Φορέα για χρήση του ediplomas.gr',
      orgClientWithApp: 'Εγγραφή Φορέα για σύνδεση με API',
      orgStepsEdiplomas:
        'Εγγραφή Φορέα στο eDiplomas Register για χρήση του ediplomas.gr',
      orgSupport: 'Επικοινωνία & Υποστήριξη',
      step1:
        'Βήμα 1. Αίτηση αρχικής καταχώρισης του νομικού προσώπου στο eDiplomas',
      step2: 'Βήμα 2. Σύνδεση του Διαχειριστή Φορέα στο eDiplomas Register',
      step3: 'Βήμα 3. Αποδοχή πρόσκλησης από τους Εκπροσώπους Φορέα',
      step4: 'Βήμα 4. Επιβεβαίωση της σύνδεσης του Φορέα'
    }
  },
  newsBreadcrumbs: {
    home: 'Αρχική Σελίδα',
    news: 'Νέα'
  },
  Footer: {
    services: 'Υπηρεσίες',
    info: 'Πληροφορίες',
    contact: 'Επικοινωνία',
    web: 'Ιστός',
    email: 'E-mail για Φορείς & ΑΕΙ',
    participatingInstitutions: 'Συμμετέχοντα Ιδρύματα',
    signatureVerification: 'Έλεγχος Εγκυρότητας Βεβαίωσης',
    diplomaCheck: 'Αυτεπάγγελτος Έλεγχος Τίτλων Σπουδών',
    termsOfUse: 'Όροι Χρήσης',
    revocation: 'Ανάκληση Εξουσιοδοτήσεων',
    templateHandlingApp: 'Εφαρμογή Διαχείρισης Προτύπων Τίτλων Σπουδών',
    ediplomasRegister: 'Εγγραφή Φορέων στο eDiplomas',
    developersGuide: 'Τεκμηρίωση',
    supportRequests: 'Υποστήριξη Αιτημάτων'
  }
};
