import React from 'react';
import Footer from '../Footer';
import './News.css';

import { Helmet } from 'react-helmet';

const BlueprintsArticleEl = (props) => (
  <div className="NewsPage">
    <div className="content">
      <div className="breadcrumbs">
        <a className="home" href="/">
          <span className="fas fa-home"> </span>{' '}
          {props.polyglot.t(`newsBreadcrumbs.home`)}
        </a>{' '}
        |
        <span className="paths">
          {props.polyglot.t(`newsBreadcrumbs.news`)} >{' '}
          <a href="/news/jades"> eDiplomas Blueprints Ιανουάριος 2022</a>
        </span>
      </div>
      <h1 className="header">eDiplomas Blueprints Ιανουάριος 2022</h1>
      <Helmet>
        <title>eDiplomas - eDiplomas Blueprints Ιανουάριος 2022</title>
      </Helmet>
      <p>
        Το{' '}
        <a
          href="https://blueprints.ediplomas.gr"
          rel="noreferrer"
          target="_blank"
        >
          eDiplomas Blueprints
        </a>{' '}
        καλείται να απαντήσει για πρώτη φορά στο ερώτημα ποιοι είναι οι
        διαφορετικοί τύποι τίτλων που έχουν εκδώσει τα Ελληνικά ΑΕΙ και για το
        σκοπό αυτό έχει εισαγάγει την έννοια του πρότυπου τίτλου. Μέσω αυτού
        επίσης επιχειρείται η αναβάθμιση των προτύπων τίτλων σε διακριτή
        αυτοτελή οντότητα και η καθοδήγηση του σχεδιασμού των πληροφοριακών
        συστημάτων των ΑΕΙ και των διαδικασιών τους στη βάση αυτή.
      </p>
      <p>
        Ο πρότυπος τίτλος σπουδών είναι η σταθερή μήτρα κάθε τίτλου που
        περιλαμβάνει όλα τα στοιχεία πλην αυτών που προσδιορίζουν τον πτυχιούχο,
        το βαθμό και την ημερομηνία ανακήρυξης. Περιλαμβάνει ωστόσο το πρόγραμμα
        σπουδών που οδηγεί στην απονομή του τίτλου, τον ή τους φορείς που
        υλοποιούν το εν λόγω πρόγραμμα σπουδών και το ειδικό θέμα του τίτλου. Τα
        στοιχεία αυτά καθιστούν τον πρότυπο τίτλο δομικό στοιχείο της ψηφιακής
        εκδοχής του πλήρους τίτλου, καθώς παρέχει σημαντικές πληροφορίες για την
        αναγνώριση του τίτλου από κάθε ενδιαφερόμενο.
      </p>
      <p>
        Ακόμα περισσότερο το eDiplomas Blueprints επεκτείνει την έγχαρτη εκδοχή
        του προτύπου τίτλου με κωδικούς ταυτοποίησης των επιμέρους οντοτήτων
        στις οποίες αναφέρεται η μήτρα και εισάγει ένα μοναδικό αναγνωριστικό
        κωδικό (blueprintID). Αυτό μας οδηγεί στην ανάπτυξη ενός εθνικού μητρώου
        προτύπων τίτλων των ΑΕΙ που μπορεί να παρέχει δομημένη μηχανικά
        αναγνώσιμη πληροφορία. Το μητρώο των προτύπων τίτλων προπτυχιακών και
        μεταπτυχιακών σπουδών των Ελληνικών ΑΕΙ αναμένεται να ξεπεράσει τις 2500
        εγγραφές και τα στοιχεία αυτού θα είναι ανοικτά για αναζήτηση,
        διασταύρωση και μελέτη.
      </p>
      <p>
        Με πρόσκληση του ΓΓ Ανώτατης Εκπαίδευσης του ΥΠΑΙΘ, ξεκίνησε τον
        Ιανουάριο του 2022 η λειτουργία της υπηρεσίας eDiplomas Blueprints με
        πρώτο στόχο την καταχώριση των προτύπων τίτλων σπουδών των προπτυχιακών
        προγραμμάτων σπουδών που έχουν ανακηρύξει πτυχιούχους από το 2010 και
        μετά.
      </p>
      <p>
        Το eDiplomas Blueprints είναι ένας σημαντικός ενδιάμεσος σταθμός στην
        πορεία ψηφιοποίησης των τίτλων των ΑΕΙ, που θα επιταχύνει τη διαδικασία
        έκδοσης ψηφιακών βεβαιώσεων κτήσης τίτλου και θα ενισχύσει την
        αξιοπιστία της προσφερόμενης υπηρεσίας.
      </p>
    </div>
    <Footer polyglot={props.polyglot} />
  </div>
);

export default BlueprintsArticleEl;
