import React, { Component } from 'react';
import { scrollTop } from './Util';
import { Spinner } from '../components/Spinner';

class ExternalRedirect extends Component {
  componentDidMount = () => {
    scrollTop();
    let push = true;
    let { to } = this.props;
    if (this.props.push !== undefined) {
      push = this.props.push;
    }
    if (this.props.language) {
      to += `?lang=${this.props.language}`;
    }
    if (push) {
      window.location.assign(to);
    } else {
      window.location.replace(to);
    }
  };

  render = () => (
    <div className="ExternalRedirect">
      <div id="spinner">
        <Spinner cx={50} cy={50} r={45} />
      </div>
    </div>
  );
}

export default ExternalRedirect;
