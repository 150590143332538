import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: block;
  border-radius: 5px;
  padding-top: 1px;
  padding-bottom: 5px;
  padding-left: 20px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding-right: 20px;
  background-color: ${(props) => props.backgroundColor ?? 'rgb(236, 236, 236)'};
  ${(props) =>
    props.warning &&
    css`
      background-color: #fffaf3;
      border-color: #c9ba9b;
    `}
  ${(props) =>
    props.error &&
    css`
      background-color: #fdf6f6;
      border-color: #e0b4b4;
    `}
`;

const Header = styled.h4`
  color: #003476;
`;

const Text = styled.p``;
export const Message = (props) => {
  return (
    <Wrapper
      backgroundColor={props.backgroundColor}
      error={props.error}
      warning={props.warning}
    >
      <Header>
        {props.url ? (
          <a href={props.url} target="_blank" rel="noreferrer">
            {props.icon && (
              <span className={`fas fa-${props.icon} no-transition`} />
            )}{' '}
            {props.header}
          </a>
        ) : (
          <p>{props.header}</p>
        )}
      </Header>
      <Text>{props.children}</Text>
    </Wrapper>
  );
};
