import React from 'react';
import Footer from '../Footer';
import './News.css';
import { Helmet } from 'react-helmet';

const JadesReport = (props) => (
  <div className="NewsPage">
    <div className="content">
      <div className="breadcrumbs">
        <a className="home" href="/">
          <span className="fas fa-home"> </span>{' '}
          {props.polyglot.t(`newsBreadcrumbs.home`)}
        </a>{' '}
        |
        <span className="paths">
          {props.polyglot.t(`newsBreadcrumbs.news`)} >{' '}
          <a href="/news/jades"> ETSI JAdES Plugtests Nov ‘21 - Jan ‘22</a>
        </span>
      </div>
      <h1 className="header">ETSI JAdES Plugtests Nov ‘21 - Jan ‘22</h1>
      <Helmet>
        <title>eDiplomas - ETSI JAdES Plugtests Nov '21 - Jan '22</title>
      </Helmet>
      <p>
        eDiplomas successfully completed the JAdES (JSON Advanced Electronic
        Signatures) plugtests of the ETSI (European Telecommunications Standards
        Institute) where pioneers from all around the world both constructed
        their own and verified other participants’ JAdES signatures.
      </p>
      <p>
        JAdES specification (ETSI TS 119 182-1) is the new member of the AdES
        formats family and is a highly anticipated standard in the digital
        signature world. JAdES is expected to complement the other eSignature
        profiles in the upcoming eIDAS2 Regulation and EU legislation.
      </p>
      <p>
        GUnet’s eDiplomas team worked towards ensuring the validity of its
        implementation and successfully demonstrated eDiplomas conformance to
        the JAdES levels, up to B-LT. This was achieved by implementing
        timestamp signatures and ensuring the long-term availability of the
        digital certificates. The final level that utilizes counter signatures
        and archive timestamps to ensure the long-term integrity of the data
        (B-LTA) is a work in progress.
      </p>
      <p>
        GUnet would like to thank the ETSI plugtest team for their cooperation
        and valuable contribution in the final outcome and participants across
        the world for their valuable feedback.
      </p>
      <h1 className="header"> The JAdES details </h1>
      <p>
        Transportation of a person’s private data in a secure and
        privacy-respecting manner is becoming a complex problem of the modern
        digital world. Most of a person’s sensitive and important information is
        stored on digital records; from e-banking accounts to social security
        and employment information. The ability to safely transfer part of this
        data to third-parties is crucial and cannot be ignored by any modern
        solution. The <strong>eDiplomas</strong> platform is no exception to
        this, since it handles sensitive information that must be protected from
        being forged or manipulated, and its authenticity should be verifiable
        at any time.
      </p>
      <p>
        Digital signatures were conceived for providing integrity of contents,{' '}
        <strong>origin authentication</strong> and{' '}
        <strong>non-repudiation</strong> of origin to data objects transported
        by networks from their origin to their destination. Digital signatures
        are based on asymmetric (or public key) cryptography.
      </p>
      <p>
        <strong>JSON Web Signature</strong> (JWS), described in RFC 7515, is an
        IETF-proposed standard for signing data. The data-to-be-signed can be of
        any type, without it necessarily being wrapped inside a JSON payload.
      </p>
      <p>
        The JWS specification is part of a family of specifications called JOSE.
        JOSE also includes JSON Web Tokens, JSON Web Encryptions and more.
      </p>
      <h1 className="header">JAdES </h1>
      <p>
        ETSI TS 119 182-1 defines <strong>JAdES</strong>, which extends the JSON
        Web Signatures specified in IETF RFC 7515. JAdES can be used for any
        transaction between an individual and a company, between two companies
        or between an individual and a governmental body. Its technical
        specification draft{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.etsi.org/deliver/etsi_ts/119100_119199/11918201/01.01.01_60/ts_11918201v010101p.pdf"
        >
          ETSI TS 119 182-1
        </a>{' '}
        defines:
      </p>
      <ul>
        <li>
          an additional set of JSON header parameters, incorporated in the JOSE
          Header
        </li>
        <li>
          specific mechanisms for incorporating the JSON components in JSON Web
          Signatures to build JAdES signatures
        </li>
        <li>
          multiple levels of JAdES baseline signatures addressing incremental
          requirements to maintain the long term validity of the signatures.
        </li>
      </ul>
      <p>
        There are four levels of JAdES baseline signatures, intended to
        facilitate interoperability and to encompass the life cycle of JAdES
        signature, namely:
      </p>
      <p>
        a) <strong>B-B</strong> level provides requirements for the
        incorporation of signed header parameters and some unsigned components
        within the etsiU unsigned header parameter when the signature is
        generated.
      </p>
      <p>
        b) <strong>B-T</strong> level provides requirements for the generation
        and inclusion, for an existing signature, of a trusted token proving
        that the signature itself actually existed at a certain date and time.
      </p>
      <p>
        c) <strong>B-LT</strong> level provides requirements for the
        incorporation of all the material required for validating the signature
        in the signature document. This level aims to tackle the long-term
        availability of the validation material.
      </p>
      <p>
        d) <strong>B-LTA</strong> level provides requirements for the
        incorporation of electronic time-stamps that allow validation of the
        signature long time after its generation. This level aims to tackle the
        long-term availability and <strong>integrity</strong> of the validation
        material.
      </p>
      <p>
        The aforementioned levels provide the basic features necessary for
        addressing complex business and governmental scenarios, where there is a
        clear need for interoperability of digital signatures used in electronic
        documents.
      </p>

      <h1 className="header">References</h1>
      <ul>
        <li>
          <a
            href="https://doi.org/10.1016/j.csi.2020.103434"
            rel="noreferrer"
            target="_blank"
          >
            Juan-Carlos Cruellas Ibarz, Bringing JSON signatures to ETSI AdES
            framework: Meet JAdES signatures,Computer Standards & Interfaces,
            Volume 71, 2020, 103434, ISSN 0920-5489
          </a>
        </li>
        <li>
          <a
            href="https://datatracker.ietf.org/doc/html/rfc7515"
            rel="noreferrer"
            target="_blank"
          >
            RFC 7515 JSON Web Signature (JWS)
          </a>
        </li>
        <li>
          <a
            href="https://www.etsi.org/deliver/etsi_ts/119100_119199/11918201/01.01.01_60/ts_11918201v010101p.pdf"
            rel="noreferrer"
            target="_blank"
          >
            ETSI TS 119 182-1{' '}
          </a>
        </li>
        <li>
          ETSI: <a href="https://www.etsi.org/">web</a>,{' '}
          <a href="https://www.facebook.com/etsi.standards">facebook</a>,{' '}
          <a href="https://twitter.com/ETSI_STANDARDS">twitter</a>
        </li>
      </ul>
    </div>
    <Footer polyglot={props.polyglot} />
  </div>
);

export default JadesReport;
