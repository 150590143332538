import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import eDiplomasLogo from './icons/eDiplomasLogo.svg';
import mineduLogo from './icons/minedu.svg';
import govGr from './icons/govGr.svg';
import ClientName from './lib/ClientName';
import LoggedIn from './lib/LoggedIn';
import LanguageSwitch from './LanguageSwitch';

const Typewriter = (props) => {
  const [text, setText] = useState('');
  const [index, setIndex] = useState(1);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex(index + 1);
      setText(props.text.substr(0, index));
      clearInterval(timer);
    }, 50);
  }, [text]);

  return <span className="tag">{text}</span>;
};

const Header = (props) => {
  const [userMenu, setUserMenu] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const navRef = useRef(null);
  const openButtonRef = useRef(null);

  const toggleUserMenu = () => {
    setUserMenu(!userMenu);
  };
  const closeNavBar = () => {
    setUserMenu(false);
  };

  const closeInfo = () => {
    setShowInfo(false);
  };

  const handleClickOutside = (event) => {
    if (
      navRef &&
      openButtonRef &&
      navRef.current &&
      openButtonRef.current &&
      !navRef.current.contains(event.target) &&
      !openButtonRef.current.contains(event.target)
    ) {
      setUserMenu(false);
    }
  };

  useEffect(() => {
    closeNavBar();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="Header">
        {showInfo && (
          <div
            style={{
              background: 'rgb(255, 255, 255, .98)',
              color: 'rgb(0, 0, 0, .8)',
              position: 'absolute',
              top: '120px',
              margin: '0 auto',
              padding: '10px 40px',
              display: 'block',
              zIndex: '100000',
              fontSize: '15px',
              'font-weight': 'bold'
            }}
          >
            <span className="fas fa-info-circle"></span> Σας ενημερώνουμε ότι,
            κατόπιν της ενέργειας του Υπ. Ψηφιακής Διακυβέρνησης να διακόψει
            απροειδοποίητα τη σύνδεση στην υπηρεσία TAXISnet, δεν είναι προσωρινά
            δυνατή η πρόσβαση στην υπηρεσία eDiplomas με κωδικούς TAXISnet. Από
            πλευράς μας γίνονται ενέργειες για την επίλυση του προβλήματος.
            <div
              style={{
                display: 'block',
                textAlign: 'right',
                marginTop: '0',
                marginRight: '20px'
              }}
            >
              <button onClick={closeInfo} className="ui button fancy tiny">
                {props.polyglot.t('Organisation.close')}
              </button>
            </div>
          </div>
        )}
        {props.developer ? (
          <h1 id="developer-header">
            <Link to="/">
              <img id="img-logo" src={eDiplomasLogo} />

              <Typewriter text="eDiplomas/" />
            </Link>
            <img id="img-govgr" src={govGr} />
          </h1>
        ) : (
          <>
            <div className="header-content">
              <Link to="/">
                <span className="logo-area">
                  <span id="eDiplomasLogo">
                    <img className="img-logo" src={eDiplomasLogo} />
                    <span className="logo-text">eDiplomas</span>
                  </span>
                  <span className="logo-split" />
                  <img className="img-logo" id="img-minedu" src={mineduLogo} />
                </span>
              </Link>
              <img id="img-govgr" src={govGr} />
            </div>
            <LoggedIn>
              <div className="user-menu full">
                <span id="clientName">
                  <span className="fas fa-user" />{' '}
                  <span>
                    <ClientName />
                  </span>
                </span>
                <span>
                  <Link to="/organisation/redirect/logout">
                    <span className="fas fa-sign-out-alt" />
                    {props.polyglot.t(`Organisation.logout.logout`)}
                  </Link>
                </span>
              </div>
              <div className="user-menu mobile">
                <span
                  ref={openButtonRef}
                  className="menu-button"
                  onClick={toggleUserMenu}
                >
                  <span className="fas fa-bars" />
                </span>
              </div>
            </LoggedIn>
          </>
        )}
        {userMenu && (
          <div id="mobile-menu">
            <ul ref={navRef}>
              <li>
                <span>
                  <span className="fas fa-user" /> <ClientName />
                </span>
              </li>
              <li className="liLogout">
                <Link to="/organisation/redirect/logout">
                  {props.polyglot.t(`Organisation.logout.logout`)}
                </Link>
              </li>
              <li>
                <LanguageSwitch
                  polyglot={props.polyglot}
                  handleLanguage={props.handleLanguage}
                  inMobileMenu={true}
                />
              </li>
            </ul>
          </div>
        )}
        <div className="header-border" />
      </div>
    </div>
  );
};
export default Header;
