const SideNavbarContent = [
  {
    id: 'documentation',
    header: 'documentation',
    isLink: true,
    link: '/documentation',
    categories: []
  },
  {
    id: 'developers',
    header: 'developers',
    isLink: false,
    link: '',
    categories: [
      {
        header: 'oauthIntegrationGuide',
        link: '/documentation/developers#dev_connection-oauth2-guide',
        sections: [
          {
            header: 'quickStart',
            link: '/documentation/developers#dev_quick-start',
            subsections: [
              { name: 'oauth2', link: '/documentation/developers#dev_oauth2' },
              {
                name: 'accessToDegree',
                link: '/documentation/developers#dev_diploma-access'
              }
            ]
          },
          {
            header: 'customClientAuthFlowDesc',
            link: '/documentation/developers#dev_authorization-flow',
            subsections: [
              {
                name: 'authProcess',
                link: '/documentation/developers#dev_authorization-process'
              },
              {
                name: 'tokenRenewal',
                link: '/documentation/developers#dev_token-renewal'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 'institutions',
    header: 'institutions',
    isLink: false,
    link: '',
    categories: [
      {
        header: 'basicConcepts',
        link: '/documentation/institutions#ins_basic_concepts',
        sections: [{ header: '', link: '', subsections: [] }]
      },
      {
        header: 'degreeTemplateCreation',
        link: '/documentation/institutions#ins_template-creation',
        sections: [
          {
            header: 'indicativeExample',
            link: '/documentation/institutions#ins_template-example',
            subsections: []
          }
        ]
      },
      {
        header: 'gradDataInsert',
        link: '/documentation/institutions#ins_data-entry',
        sections: [
          {
            header: 'throughView',
            link: '/documentation/institutions#ins_view',
            subsections: [
              {
                name: 'schema',
                link: '/documentation/institutions#ins_schema'
              },
              {
                name: 'fields',
                link: '/documentation/institutions#ins_fieldsview'
              }
            ]
          },
          {
            header: 'throughAPI',
            link: '/documentation/institutions#ins_API',
            subsections: [
              {
                name: 'fields',
                link: '/documentation/institutions#ins_fieldsapi'
              },
              {
                name: 'indicativeExamples',
                link: '/documentation/institutions#ins_examplesapi'
              }
            ]
          }
        ]
      },
      {
        header: 'requestService',
        link: '/documentation/institutions#ins_support',
        sections: [
          {
            header: 'ediplomasSupportAccess',
            link: '/documentation/institutions#ins_suspport-access',
            subsections: []
          },
          {
            header: 'eduPersonEntitlementExamples',
            link: '/documentation/institutions#ins_support-examples',
            subsections: []
          },
          {
            header: 'notValidValuesExamples',
            link: '/documentation/institutions#ins_support-invalid',
            subsections: []
          }
        ]
      }
    ]
  },
  {
    id: 'organisations',
    header: 'organisations',
    isLink: false,
    link: '',
    categories: [
      {
        header: '',
        link: '',
        sections: [
          {
            header: 'orgClients',
            link: '/documentation/organisations#org_organisations-clients',
            subsections: []
          },
          {
            header: 'orgStepsEdiplomas',
            link: '/documentation/organisations#org_registration-steps',
            subsections: [
              {
                name: 'step1',
                link: '/documentation/organisations#org_registration-step1'
              },
              {
                name: 'step2',
                link: '/documentation/organisations#org_registration-step2'
              },
              {
                name: 'step3',
                link: '/documentation/organisations#org_registration-step3'
              },
              {
                name: 'step4',
                link: '/documentation/organisations#org_registration-step4'
              }
            ]
          },
          {
            header: 'orgClientWithApp',
            link: '/documentation/organisations#org_registration-oauth2',
            subsections: []
          },
          {
            header: 'orgSupport',
            link: '/documentation/organisations#org_support',
            subsections: []
          }
        ]
      }
    ]
  }
];

export default SideNavbarContent;
