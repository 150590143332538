import React, { Component } from 'react';
import Footer from './Footer';
import { scrollTop } from './lib/Util';
import './TermsOfUse.css';

class Terms extends Component {
  componentDidMount() {
    scrollTop();
  }

  handleLanguage = (lang) => {
    this.props.handleLanguage(lang);
  };

  render = () => (
    <>
      <div className="TermsOfUse">
        <div className="content">
          <h1 className="center header">
            {this.props.polyglot.t(`TermsOfUse.termsOfUse`)}
          </h1>
          <ol id="termsOfUseList">
            <li>
              <p>{this.props.polyglot.t(`TermsOfUse.termsParagraph1`)}</p>
            </li>
            <li>
              <p>{this.props.polyglot.t(`TermsOfUse.termsParagraph2`)}</p>
            </li>
            <li>
              <p>{this.props.polyglot.t(`TermsOfUse.termsParagraph3`)}</p>
            </li>
            <li>
              <p>{this.props.polyglot.t(`TermsOfUse.termsParagraph4`)}</p>
            </li>
            <li>
              <p>{this.props.polyglot.t(`TermsOfUse.termsParagraph5`)}</p>
            </li>
            <li>
              <p>{this.props.polyglot.t(`TermsOfUse.termsParagraph6`)}</p>
            </li>
            <li>
              <p>
                {this.props.polyglot.t(`TermsOfUse.termsParagraph7Part1`)}{' '}
                &nbsp;
                {this.props.polyglot.t(`TermsOfUse.termsParagraph7Part2`)}{' '}
                &nbsp;
                {this.props.polyglot.t(`TermsOfUse.termsParagraph7Part3`)}
              </p>
            </li>
          </ol>
        </div>
      </div>
      <Footer polyglot={this.props.polyglot} />
    </>
  );
}

export default Terms;
