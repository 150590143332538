import React from 'react';
import InstitutionsGuideEn from './en/InstitutionsGuideEn';
import InstitutionsGuideEl from './el/InstitutionsGuideEl';

const InstitutionsGuide = (props) => {
  if (props.polyglot.currentLocale === 'el') {
    return (
      <div id="start">
        <InstitutionsGuideEl />
      </div>
    );
  }
  return (
    <div id="start">
      <InstitutionsGuideEn />
    </div>
  );
};
export default InstitutionsGuide;
