import React from 'react';
import './LanguageSwitch.css';

const LanguageSwitch = (props) => (
  <>
    <span
      className={
        'language-switch ' +
        (props.inMobileMenu ? 'language-switch-mobile' : '')
      }
    >
      <button
        className={`${props.polyglot.currentLocale === 'el' ? 'active' : ''}`}
        onClick={() => props.handleLanguage('el')}
      >
        EL
      </button>
      |
      <button
        className={`${props.polyglot.currentLocale === 'en' ? 'active' : ''}`}
        onClick={() => props.handleLanguage('en')}
      >
        EN
      </button>
    </span>
  </>
);

export default LanguageSwitch;
