import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Authentication = (props) => {
  const authContext = useContext(AuthContext);

  const refresh = async () => {
    await authContext.refresh();
  };

  useEffect(() => {
    refresh();
  }, []);

  if (authContext.loading > 0) {
    return <></>;
  }
  if (!authContext.loggedIn) {
    if (props.token) {
      return <Redirect to={`/organisation/login/?token=${props.token}`} />;
    }
    if (props.next) {
      return <Redirect to={`/organisation/login/?next=${props.next}`} />;
    }
    return <Redirect to="/organisation/login" />;
  }
  return null;
};

export default Authentication;
