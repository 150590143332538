import React from 'react';
import './Steps.css';

export const Steps = (props) => (
  <>
    <div className="Steps">
      {props.steps.map((step, index) => (
        <div
          key={step}
          className={`step ${props.active === index + 1 ? 'active' : ''}`}
        >
          <div className="content">{step}</div>
        </div>
      ))}
    </div>
  </>
);
