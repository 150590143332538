import React from 'react';
import './News.css';

import BlueprintsArticleEn from './BlueprintsArticleEn';
import BlueprintsArticleEl from './BlueprintsArticleEl';

const BlueprintsArticle = (props) => {
  if (props.polyglot.currentLocale === 'en') {
    return (
      <BlueprintsArticleEn
        polyglot={props.polyglot}
        handleLanguage={props.handleLanguage}
      />
    );
  } else {
    return (
      <BlueprintsArticleEl
        polyglot={props.polyglot}
        handleLanguage={props.handleLanguage}
      />
    );
  }
};

export default BlueprintsArticle;
