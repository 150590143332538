import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';

const DocumentationHome = (props) => {
  const [redirect, setRedirect] = useState('');

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}
      <h1 className="header nav-anchor" id="documentation">
        {props.polyglot.t(`Documentation.documentation`)}
      </h1>
      <div>
        <p>{props.polyglot.t(`Documentation.descriptionParagraph1`)}</p>
        <p>{props.polyglot.t(`Documentation.descriptionParagraph2`)}</p>
      </div>
      <div className="cards">
        <div
          className="card"
          onClick={() => setRedirect('/documentation/developers')}
        >
          <div className="image">
            <i className="fa fa-3x fa-code" />
          </div>
          <div className="content">
            <div className="header">
              <Link to="/documentation/developers">
                {props.polyglot.t(`Documentation.developers`)}
              </Link>
            </div>
            <div className="description">
              <span>
                {props.polyglot.t(`Documentation.connectionThirdParties`)}
              </span>
            </div>
          </div>
        </div>
        <div
          className="card"
          onClick={() => setRedirect('/documentation/institutions')}
        >
          <div className="image">
            <i className="fa fa-3x fa-university" />
          </div>
          <div className="content">
            <div className="header">
              <Link to="/documentation/institutions">
                {props.polyglot.t(`Documentation.institutions`)}
              </Link>
            </div>
            <div className="description">
              <span>
                {props.polyglot.t(`Documentation.institutionIntegration`)}
              </span>
            </div>
          </div>
        </div>
        <div
          className="card"
          onClick={() => setRedirect('/documentation/organisations')}
        >
          <div className="image">
            <i className="fa fa-3x fa-briefcase" />
          </div>
          <div className="content">
            <div className="header">
              <Link to="/documentation/organisations">
                {props.polyglot.t(`Documentation.organisations`)}
              </Link>
            </div>
            <div className="description">
              <span>
                {props.polyglot.t(`Documentation.organisationRegistration`)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentationHome;
