import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const SideNavbar = (props) => {
  const [openCategory, setCategory] = useState('');
  const [open, toggle] = useState(false);

  useEffect(() => setCategory(props.openCategory), [props.openCategory]);

  const changeCategory = (category) => {
    if (openCategory === category) {
      category = '';
    }
    setCategory(category);
  };

  const toggleMenu = () => toggle(!open);

  const toggleMenuLink = (e) => {
    const { target } = e;
    if (target.tagName === 'A') {
      toggleMenu();
    }
  };

  return (
    <>
      <div className="nav-toggle">
        <button onClick={toggleMenu}>
          <span className={`fas ${open ? 'fa-times' : 'fa-bars'}`} />
        </button>
      </div>
      <div className={`nav ${open ? '' : 'nav-mobile-hidden'}`}>
        <div className="nav-content-wrapper">
          <div className="nav-content">
            <h2 className="header">{props.header}</h2>
            <ul className="nav-list" onClick={toggleMenuLink}>
              {props.content.map((item, i) =>
                item.isLink ? (
                  <li key={i}>
                    <Link to={item.link}>
                      {item.header
                        ? props.polyglot.t(
                            `Documentation.navbar.${item.header}`
                          )
                        : ''}
                    </Link>
                  </li>
                ) : (
                  <li
                    className={`category ${
                      openCategory !== item.id ? 'collapsed' : ''
                    }`}
                    key={i}
                  >
                    <button onClick={() => changeCategory(item.id)}>
                      {props.polyglot.t(`Documentation.navbar.${item.header}`)}
                      <span className="fas fa-chevron-up" />
                    </button>
                    <Link to={`/documentation/${item.id}#start`}>
                      <span className="fas fa-link" />
                    </Link>
                    <ul className="contents">
                      {item.categories.map((category, k) => (
                        <li key={`${i}_${k}`}>
                          <Link
                            to={category.link}
                            className={`${
                              category.link ===
                              `/documentation/${openCategory}#${props.active}`
                                ? 'active'
                                : ''
                            }`}
                          >
                            <h4>
                              {category.header
                                ? props.polyglot.t(
                                    `Documentation.navbar.${category.header}`
                                  )
                                : ''}
                            </h4>
                          </Link>
                          <ul className="section">
                            {category.sections.map((section, l) => (
                              <li key={`${i}_${k}_${l}`}>
                                <Link
                                  to={section.link}
                                  className={`${
                                    section.link ===
                                    `/documentation/${openCategory}#${props.active}`
                                      ? 'active'
                                      : ''
                                  }`}
                                >
                                  {section.header
                                    ? props.polyglot.t(
                                        `Documentation.navbar.${section.header}`
                                      )
                                    : ''}
                                </Link>
                                <ul className="subsection">
                                  {section.subsections.map((subsection, m) => (
                                    <li
                                      className="inner"
                                      key={`${i}_${k}_${l}_${m}`}
                                    >
                                      <Link
                                        to={subsection.link}
                                        className={`${
                                          subsection.link ===
                                          `/documentation/${openCategory}#${props.active}`
                                            ? 'active'
                                            : ''
                                        }`}
                                      >
                                        {subsection.name
                                          ? props.polyglot.t(
                                              `Documentation.navbar.${subsection.name}`
                                            )
                                          : ''}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideNavbar;
