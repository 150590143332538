import React from 'react';
import CodeExample from '../../components/CodeExample';
import {
  OAuth2Endpoints,
  OAuth2Callback,
  DiplomaAccessHeader,
  DiplomaAccessResponseEl
} from '../APIExamples';
import { Heading } from '../Common';

const DevelopersGuideEl = () => (
  <div className="Developers-Guide">
    <h1 className="header">Προγραμματιστές</h1>
    <div className="section">
      <h2 className="header" id="dev_connection-oauth2-guide">
        Διασύνδεση τρίτων υπηρεσιών (OAuth2)
      </h2>
      <div className="inner section">
        <p>
          Η συγκεκριμένη σελίδα περιγράφει την προγραμματιστική διασύνδεση
          εφαρμογών τρίτων με την πλατφόρμα του eDiplomas. Οι εφαρμογές που
          συνδέονται με το eDiplomas μπορούν, με την κατάλληλη εξουσιοδότηση, να
          αποκτούν πρόσβαση σε Τίτλους Σπουδών μέσω API.
        </p>
        <p>
          Στις επόμενες παραγράφους περιγράφεται η λειτουργία τέτοιων εφαρμογών,
          που αποκαλούνται <em>εφαρμογές-πελάτες του eDiplomas</em>.
        </p>
        <div className="noteblock">
          <strong>Σημείωση:</strong> Για την διασύνδεση εφαρμογών-πελατών με το
          eDiplomas, πρέπει να έχει προηγηθεί η{' '}
          <a href="/documentation/organisations#org_org-with-app">εγγραφή</a>{' '}
          τους στην πλατφόρμα.
        </div>
      </div>
      <div className="inner section">
        <Heading text="Quick Start" id="dev_quick-start" level="3" />
        <div className="inner section">
          <Heading text="OAuth 2" id="dev_oauth2" level="4" />
          <p>
            Στον πυρήνα του eDiplomas βρίσκεται ένα σύστημα OAuth2. Οι
            λεπτομέρειες υλοποίησης καθώς και διασύνδεσης με ένα OAuth2 σύστημα
            περιγράφονται στο{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749"
              target="_blank"
              rel="noopener noreferrer"
            >
              RFC 6749
            </a>
            . Για τη διασύνδεση με την OAuth2 υποδομή του eDiplomas απαιτείται
            ένας OAuth2 Client υλοποιημένος σύμφωνα με το RFC 6749 και με
            υποστήριξη χρήσης της μεθόδου Authorization Code Grant όπως φαίνεται
            στο RFC 6749 στην{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#inner section-4.1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ενότητα 4.1.
            </a>
          </p>
          <p>
            Για την χρήση των υπηρεσιών OAuth2 απαιτείται η δήλωση ενός Client
            στην πλατφόρμα eDiplomas μέσω της{' '}
            <a href="/documentation/organisations#org_org-with-app">Εγγραφής</a>
            .
          </p>
          <p>
            Ένας OAuth2 Client αλληλεπιδρά με έναν Authorization Server (Ο
            πολίτης/πτυχιούχος δίνει εξουσιοδότηση) και με έναν Resource Server
            (Ο Client χρησιμοποιεί το token του πολίτη/πτυχιούχου για να
            αποκτήσει πρόσβαση στην πληροφορία). Ο Authorization Server έχει ένα
            endpoint εξουσιοδότησης και ένα λήψης token πολίτη/πτυχιούχου. Για
            το eDiplomas, τα endpoints αυτά είναι:
          </p>
          <CodeExample data={OAuth2Endpoints.results} indented />
          <p>
            Με τη σειρά του o πελάτης θα πρέπει κατά την εγγραφή του να δηλώσει
            ενα endpoint στο οποίο θα ανακατευθύνεται ο πολίτης/πτυχιούχος μόλις
            ολοκληρωθεί η διαδικασία στον Authorization Server. Ο OAuth2 Client
            θα στέλνει αυτή την πληροφορία στον Authorization Server όπως
            περιγράφεται και στο RFC 6749.
          </p>
          <CodeExample data={OAuth2Callback.results} indented />
          <p>
            Για τη διασύνδεση με το eDiplomas προτείνεται η χρήση κάποιου
            έτοιμου OAuth2 Client που θα ρυθμιστεί σύμφωνα με τα παραπάνω. Σε
            περίπτωση που δεν αρκεί αυτό, δείτε την ενότητα με την περιγραφή του
            flow.
          </p>
        </div>
        <div className="inner section">
          <Heading
            text="Πρόσβαση σε τίτλο σπουδών"
            id="dev_diploma-access"
            level="4"
          />
          <p>
            Εφόσον ο πελάτης μέσω του OAuth έχει εξασφαλίσει τα tokens του
            πολίτη/πτυχιούχου, τότε μπορεί να τα χρησιμοποιήσει για να αποκτήσει
            πρόσβαση στους τίτλους σπουδών του πολίτη/πτυχιούχου που αντιστοιχεί
            στα tokens. Ο OAuth2 Client πρέπει να κάνει ένα{' '}
            <em className="highlight">GET</em> αίτημα στον{' '}
            <em className="highlight">Resource Server</em> στο οποίο θα
            περιέχεται το access token του πολίτη/πτυχιούχου για τον οποίο
            αναζητείται ο τίτλος σπουδών. Το{' '}
            <em className="highlight">access token</em> θα προστίθεται στο
            αίτημα αυτό μέσω του{' '}
            <em className="highlight">Authorization header</em> με την μέθοδο
            του <em className="highlight">Bearer token</em>, όπως περιγράφεται
            στο{' '}
            <a
              href="https://tools.ietf.org/html/rfc6750"
              target="_blank"
              rel="noopener noreferrer"
            >
              RFC 6750
            </a>
            , στην{' '}
            <a
              href="https://tools.ietf.org/html/rfc6750#inner section-2.1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ενότητα 2.1. (Authorization Request Header Field)
            </a>
            . Αν το <em className="highlight">access token</em> είναι έγκυρο, ο
            Resource Server θα απαντήσει με τις πληροφορίες που αφορούν τα
            scopes του συγκεκριμένου <em className="highlight">access token</em>
            . Τα scopes που επιστρέφονται στον Client είναι αυτά που έχουν
            δηλωθεί κατα την εγγραφή του στην πλατφόρμα του <em>eDiplomas</em>
          </p>
          <CodeExample
            header="Authorization Header:"
            data={DiplomaAccessHeader.results}
            indented={false}
          />
          <CodeExample
            header="Απάντηση:"
            data={DiplomaAccessResponseEl.results}
            indented
          />
        </div>
      </div>
      <div className="inner section">
        <Heading
          text="Εξουσιοδότηση για custom clients"
          id="dev_authorization-flow"
          level="3"
        />
        <div className="inner section">
          <Heading
            text="Διαδικασία εξουσιοδότησης"
            id="dev_authorization-process"
            level="4"
          />
          <p>
            Η εφαρμογή του πελάτη πρέπει να οδηγήσει τον πολίτη/πτυχιούχο στoν
            Authorization Server, οπού θα εξουσιοδοτήσει την πρόσβαση σε
            στοιχεία που αφορούν τους τίτλους σπουδών του. Η επιτυχής
            εξουσιοδότηση θα οδηγήσει τον πολίτη/πτυχιούχο πίσω στο
            callbackEndpoint, στις παραμέτρους του οποίου θα υπάρχει ο OAuth2
            κωδικός. H αίτηση εξουσιοδότησης περιγράφεται απο το RFC 6749 στην{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#inner section-4.1.1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ενότητα 4.1.1
            </a>{' '}
            και η απάντηση του Authorization Server στην{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#section-4.1.2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ενότητα 4.1.2
            </a>
            . Τον κωδικό αυτό θα πρέπει να παρουσιάσει ο OAuth2 Client στο
            tokenEndpoint για να αποκτήσει τα tokens του πολίτη/πτυχιούχου. Για
            την αλληλεπίδραση του OAuth2 Client με το{' '}
            <em className="highlight">token endpoint</em> του{' '}
            <em className="highlight">Authorization Server</em>, απαιτείται η
            παρουσίαση διαπιστευτηρίων με χρήση του HTTP Basic Authentication,
            όπως περιγράφεται από το{' '}
            <a
              href="https://tools.ietf.org/html/rfc7617"
              target="_blank"
              rel="noopener noreferrer"
            >
              RFC 7617
            </a>
            . Το αίτημα για tokens πολίτη/πτυχιούχου με χρήση του code
            περιγράφεται στην{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#section-4.1.3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ενότητα 4.1.3
            </a>{' '}
            και η απάντηση του Authorization Server στην{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#section-4.1.4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ενότητα 4.1.4
            </a>
            .
          </p>
        </div>
        <div className="inner section">
          <Heading text="Ανανέωση token" id="dev_token-renewal" level="4" />
          <p>
            Για την ανανέωση των <em className="highlight">access tokens</em>{' '}
            μετά τη λήξη τους χρησιμοποιούνται τα{' '}
            <em className="highlight">refresh tokens</em>. Ο OAuth2 Client
            πρέπει να κάνει μια GET αίτηση στο{' '}
            <em className="highlight">Token Endpoint</em> με τις παραμέτρους{' '}
            <em className="highlight">grant_type</em> με τιμή "refresh_token"
            και <em className="highlight">refresh_token</em> με τιμή το ίδιο το
            refresh_token. Σημείωση: Όπως και σε κάθε αίτηση προς το{' '}
            <em className="highlight">Token Endpoint</em>, απαιτείται HTTP Basic
            Authentication (βλ. Διαδικασία Εξουσιοδότησης). Η διαδικασία
            ανανέωσης token περιγράφεται στο RFC 6749,{' '}
            <a
              href="https://tools.ietf.org/html/rfc6749#inner section-6"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ενότητα 6
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default DevelopersGuideEl;
