import React from 'react';
import Footer from '../Footer';
import './News.css';

import { Helmet } from 'react-helmet';

const EmrexArticleEl = (props) => (
  <div className="NewsPage">
    <div className="content">
      <div className="breadcrumbs">
        <a className="home" href="/">
          <span className="fas fa-home"> </span>{' '}
          {props.polyglot.t(`newsBreadcrumbs.home`)}
        </a>{' '}
        |
        <span className="paths">
          {props.polyglot.t(`newsBreadcrumbs.news`)} >{' '}
          <a href="/news/emrex">Το eDiplomas στο δίκτυο EMREX</a>
        </span>
      </div>
      <h1 className="header">Το eDiplomas στο δίκτυο EMREX</h1>
      <h3>Μοιραστείτε το πτυχίο σας με χιλιάδες ιδρύματα που συμμετέχουν στο δίκτυο EMREX</h3>
      <Helmet>
        <title>Το eDiplomas στο δίκτυο EMREX</title>
      </Helmet>
      <p>
        Το GUnet, ως μέλος του ευρωπαϊκού δικτύου <a href='https://emrex.eu/' target='_blank' rel="noreferrer">EMREX</a>, ολοκλήρωσε τις εργασίες διασύνδεσης της υπηρεσίας <a href='https://ediplomas.gr/' target='_blank' rel="noreferrer">eDiplomas</a> με το δίκτυο EMREX, παρέχοντας έτσι στους αποφοίτους των ελληνικών πανεπιστημίων πρόσβαση σε διασυνοριακές υπηρεσίες βεβαίωσης τίτλων σπουδών.
      </p>
      <p>
        Στο δίκτυο EMREX συμμετέχουν πάνω από 1.600 φορείς, από 10 ευρωπαϊκές χώρες. Ο απόφοιτος ενός ελληνικού πανεπιστήμιου, ξεκινώντας από σχετική εφαρμογή (π.χ. φοιτητολόγιο) οποιουδήποτε συνδεδεμένου στο EMREX πανεπιστημίου στο εξωτερικό, μπορεί να επιλέξει τον ελληνικό κόμβο EMREX και μέσω αυτού να διαθέσει εύκολα και αξιόπιστα τις βεβαιώσεις των τίτλων σπουδών που είναι διαθέσιμες μέσω της υπηρεσίας eDiplomas.
      </p>
      <p>
        Εκτός από την ψηφιακή μορφή της βεβαίωσης του τίτλου σπουδών που έχει διαμορφωθεί για τη μηχανική ανάγνωση και επαλήθευση από εφαρμογές (EMREX clients), o πτυχιούχος και η εφαρμογή-πελάτης θα λάβει και τη βεβαίωση του τίτλου σπουδών του σε μορφή PDF, η οποία θα φέρει την εγκεκριμένη ψηφιακή σφραγίδα (Qualified PAdES LTA) που χρησιμοποιείται από το eDiplomas και έχει τη μέγιστη νομική ισχύ σε σχέση με όλους τους τύπους ψηφιακής υπογραφής.  Σύμφωνα με τον Ευρωπαϊκό Κανονισμό eIDAS, η ψηφιακή αυτή σφραγίδα αναγνωρίζεται ρητά ως νομικά ισοδύναμη με την παραδοσιακή σφραγίδα, σε όλα τα κράτη μέλη της Ευρωπαϊκής Ένωσης.
      </p>
    </div>
    <Footer polyglot={props.polyglot} />
  </div>
);

export default EmrexArticleEl;
