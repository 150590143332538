import React from 'react';
import Footer from '../Footer';
import './News.css';

import { Helmet } from 'react-helmet';

const BlueprintsArticleEn = (props) => (
  <div className="NewsPage">
    <div className="content">
      <div className="breadcrumbs">
        <a className="home" href="/">
          <span className="fas fa-home"> </span>{' '}
          {props.polyglot.t(`newsBreadcrumbs.home`)}
        </a>{' '}
        |
        <span className="paths">
          {props.polyglot.t(`newsBreadcrumbs.news`)} >{' '}
          <a href="/news/jades"> eDiplomas Blueprints January 2022</a>
        </span>
      </div>
      <h1 className="header">eDiplomas Blueprints January 2022</h1>
      <Helmet>
        <title>eDiplomas - eDiplomas Blueprints January 2022</title>
      </Helmet>
      <p>
        <a
          href="https://blueprints.ediplomas.gr"
          rel="noreferrer"
          target="_blank"
        >
          eDiplomas Blueprints
        </a>{' '}
        is addressing the question of what types of diplomas have been issued by
        the Greek Universities and for that reason has introduced the concept of
        diploma prototypes. The introduction of diploma prototypes also
        facilitates the decoupling of the varying per graduate certificate
        information from its constant part and steers the redesigning of the
        Students Information Systems towards this direction.
      </p>
      <p>
        Diploma prototypes are time persistent templates that encapsulate the
        non changing part of a diploma i.e. those attributes not related with
        the details of the awarded person, the grade and the date of graduation.
        It does encapsulate information about the curriculum, the issuing
        organization(s) and the diploma title . A diploma prototype is a core
        element of a digital diploma entity since it conveys important
        information regarding the classification of the diploma to the verifying
        parties.
      </p>
      <p>
        The digital version of the diploma prototypes extends the paper-based
        format by complementing its fields with the corresponding identifiers
        from other registries. Also, it introduces a new unique identifier
        (blueprintID) based on which the national registry of the Greek
        Universities Diploma prototypes will be created. The registry is
        anticipated to include more than 2500 records and it will be publicly
        available so as to facilitate searching and provide access to
        information on diplomas prototypes.
      </p>
      <p>
        In January 2022, upon the request of the General Secretary of Higher
        Education, the service eDiplomas Blueprints was made available for
        public use. GUnet’s goal in this phase is to coordinate the Greek
        Universities towards registering all diploma prototypes that correspond
        to undergraduate diplomas issued 2010 onwards.
      </p>
      <p>
        The eDiplomas Blueprints launch is an important achievement in the
        endeavor of digitization of the Greek Universities diplomas, it will
        fast-forward the process of digital diplomas issuing and will underpin
        the reliability of the end service.
      </p>
    </div>
    <Footer polyglot={props.polyglot} />
  </div>
);

export default BlueprintsArticleEn;
