import React from 'react';
import { Heading } from '../Common';
import MailExample from '../../components/MailExample';

const requestForRegistration = (
  <>
    <span>Επωνυμία: Acme Corporation Ltd</span>
    <br />
    <span>
      Κατηγορία Νομικού Προσώπου: Νομικό Πρόσωπο Δημοσίου Δικαίου (ΝΠΔΔ)
    </span>
    <br />
    <span>ΑΦΜ Φορέα: 123456789</span>
    <br />
    <br />
    <span>Στοιχεία Επικοινωνίας Φορέα</span>
    <br />
    <span>Όνομα Επώνυμο: Γιάννης Παπαδόπουλος</span>
    <br />
    <span>Τηλέφωνο: 2106263647</span>
  </>
);
const requestForOauth2Registration = (
  <>
    <span>Επωνυμία: Acme Corporation Ltd</span>
    <br />
    <span>
      Κατηγορία Νομικού Προσώπου: Νομικό Πρόσωπο Ιδιωτικού Δικαίου (ΝΠIΔ)
    </span>
    <br />
    <span>ΑΦΜ Φορέα: 123456789</span>
    <br />
    <br />
    <span>Στοιχεία Επικοινωνίας Τεχνικού Εκπροσώπου</span>
    <br />
    <span>Όνομα Επώνυμο: Γιάννης Παπαδόπουλος</span>
    <br />
    <span>Τηλέφωνο: 2106263647</span>
  </>
);

const OrganisationInfoEl = (props) => (
  <>
    <div className="inner section">
      <Heading
        text="Υπηρεσίες eDiplomas για Φορείς"
        id={`${props.idPrefix}organisations-clients`}
        level="3"
      />
      <p>
        Η εφαρμογή{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a>{' '}
        απευθύνεται σε φορείς που επιθυμούν να ενταχθούν στην πλατφόρμα
        eDiplomas και να αποκτήσουν πρόσβαση στις υπηρεσίες βεβαίωσης τίτλων
        σπουδών μέσω των εφαρμογών τους ή των εκπροσώπων που έχουν ορίσει. Οι
        εγγεγραμμένοι φορείς στο{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a> μπορεί να
        είναι νομικά πρόσωπα του ιδιωτικού ή δημόσιου τομέα. Εφόσον ένας φορέας
        έχει εγγραφεί, του παρέχεται η δυνατότητα πρόσβασης σε τίτλους σπουδών
        των ΑΕΙ της χώρας που έχουν συνδεθεί στο eDiplomas (
        <a href="https://ediplomas.gr/institutions">eDiplomas Institutions</a>).
      </p>
      <p>
        H πρόσβαση ενός φορέα σε ένα τίτλο σπουδών προϋποθέτει ότι ο κάτοχος του
        τίτλου έχει προηγουμένως επιλέξει από το περιβάλλον του eDiplomas το
        συγκεκριμένο φορέα και έχει εξουσιοδοτήσει την πρόσβαση στους τίτλους
        του. Η εξουσιοδότηση πρόσβασης εκδίδεται για συγκεκριμένο φορέα και
        αποστέλλεται από τον κάτοχο του τίτλου στον φορέα μέσω ενός απλού
        συνδέσμου που κάθε εκπρόσωπος του φορέα μπορεί να χρησιμοποιήσει για να
        λάβει τα ψηφιακά υπογεγραμμένα στοιχεία των τίτλων.
      </p>
      <p>
        Φορείς του δημόσιου και του ιδιωτικού τομέα, που έχουν οργανωμένες
        εφαρμογές διαχείρισης αιτήσεων π.χ για θέσεις εργασίας, ή Πανεπιστήμια
        με εφαρμογές διαχείρισης αιτήσεων για εγγραφή σε μεταπτυχιακά
        προγράμματα σπουδών, μπορούν να συνδέσουν απευθείας τις εφαρμογές τους
        με το eDiplomas αξιοποιώντας τη σχετική προγραμματιστική διεπαφή (API).
        Στην περίπτωση αυτή η αποστολή της εξουσιοδότησης και η άντληση των
        στοιχείων διεκπεραιώνεται αυτόματα μεταξύ του eDiplomas και του
        πληροφοριακού συστήματος του φορέα (βλ.{' '}
        <a href="http://open.gunet.gr/ediplomas-presentation/video.html?story=1">
          Αίτηση για θέση εργασίας στην ACME Corp
        </a>
        ).
      </p>
      <p>
        Ειδικά στους φορείς του δημοσίου τομέα, παρέχεται πρόσβαση σε υπηρεσίες
        αυτεπάγγελτου ελέγχου των τίτλων σπουδών μέσω των εκπροσώπων που έχουν
        ορίσει για το σκοπό αυτό. Ο αυτεπάγγελτος έλεγχος αφορά αποκλειστικά
        τίτλους που έχουν ήδη κατατεθεί στο φορέα, με άλλους μηχανισμούς (π.χ.
        έγχαρτη μορφή) και η διενέργειά του δεν προϋποθέτει την εξουσιοδότηση
        από τον κάτοχο του τίτλου.
      </p>
    </div>
    <div className="inner section">
      <Heading
        text="Εγγραφή Φορέα στο eDiplomas Register για χρήση του ediplomas.gr"
        id={`${props.idPrefix}registration-steps`}
        level="3"
      />
      <p>
        Συνοπτικά τα βήματα για να εγγραφεί ένας φορέας στο eDiplomas και να
        ενεργοποιήσει την πρόσβαση του στις υπηρεσίες για φορείς είναι τα εξής:
      </p>
      <ol>
        <li>Αίτηση αρχικής καταχώρισης του νομικού προσώπου στο eDiplomas</li>
        <li>
          Σύνδεση του Διαχειριστή Φορέα στο{' '}
          <a href="https://register.ediplomas.gr">eDiplomas Register</a>
        </li>
        <li>Αποδοχή πρόσκλησης από τους Εκπροσώπους Φορέα</li>
        <li>Επιβεβαίωση της σύνδεσης του φορέα</li>
      </ol>
      <p>Παρακάτω περιγράφονται τα επιμέρους βήματα πιο αναλυτικά.</p>
      <Heading
        text="Βήμα 1. Αίτηση αρχικής καταχώρισης του νομικού προσώπου στο eDiplomas"
        id={`${props.idPrefix}registration-step1`}
        level="5"
      />
      <p>
        Η αρχική καταχώριση αφορά τα στοιχεία του ΑΦΜ, ονομασίας, κατηγορίας του
        νομικού προσώπου, καθώς και τα στοιχεία επικοινωνίας του. Η καταχώριση
        γίνεται κατόπιν αποστολής των απαιτούμενων στοιχείων από εταιρική email
        δ/νση του φορέα στο email{' '}
        <a href="mailto:eDiplomas.registration@gunet.gr">
          eDiplomas.registration@gunet.gr
        </a>{' '}
        , σύμφωνα με το παρακάτω πρότυπο, και ακολουθείται από σχετική ενημέρωση
        για την εξέλιξη του αιτήματος.
      </p>
      <h5>Πρότυπο email αίτησης</h5>
      <MailExample
        mailto="eDiplomas.registration@gunet.gr"
        subject="eDiplomas Register: Αίτηση καταχώρισης νομικού προσώπου στο eDiplomas"
        content={requestForRegistration}
      />
      <Heading
        text="Βήμα 2. Σύνδεση του Διαχειριστή Φορέα στο eDiplomas Register"
        id={`${props.idPrefix}registration-step2`}
        level="5"
      />
      <p>
        Η αρχικοποίηση ενός φορέα στο eDipomas, γίνεται πρακτικά με την είσοδο
        του διαχειριστή του φορέα στο{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a>. Ο
        Διαχειριστής Φορέα (ΔΦ), είναι το ή τα μέλη του φορέα, που έχουν
        πρόσβαση στο λογαριασμό TAXISnet του φορέα. Ο ρόλος του ΔΦ στο
        οικοσύστημα του eDiplomas περιορίζεται αποκλειστικά στο περιβάλλον του{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a> και στη
        δήλωση των φυσικών προσώπων, μελών του φορέα, που θα έχουν το ρόλο
        Εκπροσώπου Φορέα (ΕΦ) στις υπηρεσίες του eDiplomas. Η διαδικασία
        εγγραφής ενός ΕΦ ξεκινάει όταν ο ΔΦ δηλώσει την email δ/νση του ΕΦ, ώστε
        να σταλεί σε αυτόν πρόσκληση από το{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a>. Ο ρόλος
        του ΔΦ δεν μπορεί να έχει ταυτόχρονα πρόσβαση στις υπηρεσίες eDiplomas
        για φορείς (π.χ. αυτεπάγγελτος έλεγχος ή έλεγχος μέσω εξουσιοδότησης).
        Πρόσβαση στις υπηρεσίες αυτές έχουν αποκλειστικά οι ΕΦ.
      </p>
      <Heading
        text="Βήμα 3. Αποδοχή πρόσκλησης από τους Εκπροσώπους Φορέα"
        id={`${props.idPrefix}registration-step3`}
        level="5"
      />
      <p>
        Η κάθε πρόσκληση ΕΦ περιλαμβάνει ένα μοναδικό σύνδεσμο προς το{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a> και αφορά
        την πρόσβαση σε μια συγκεκριμένη κατηγορία υπηρεσιών (π.χ. αυτεπάγγελτος
        έλεγχος ή έλεγχος μέσω εξουσιοδότησης). Όταν ο παραλήπτης ακολουθήσει
        τον σχετικό σύνδεσμο, θα πρέπει να κάνει είσοδο με τον προσωπικό του
        TAXISnet λογαριασμό και στη συνέχεια να αποδεχθεί τον ρόλο του ΕΦ για
        τον φορέα του. Μετά την αποδοχή του ρόλου, συνδέεται ο λογαριασμός του
        ΕΦ με αυτόν του φορέα και ενεργοποιείται η πρόσβαση του ΕΦ στις
        υπηρεσίες του eDiplomas για φορείς. Μέσω του{' '}
        <a href="https://register.ediplomas.gr">eDiplomas Register</a> ο ΕΦ
        μπορεί κάθε στιγμή:
        <ul>
          <li>
            Να ενημερωθεί για το σύνολο των eDiplomas ρόλων που του έχουν
            αποδοθεί από τον φορέα του (ΔΦ).
          </li>
          <li>
            Να αποσυνδέσει το TAXISnet λογαριασμό του από το eDiplomas και να
            ακυρώσει τον ρόλο του.
          </li>
        </ul>
      </p>
      <p>
        Κάθε ΕΦ μπορεί να συμμετέχει σε ένα φορέα, ωστόσο μπορεί στο φορέα αυτό
        να έχει πρόσβαση σε μία ή περισσότερες eDiplomas υπηρεσίες.
      </p>
      <Heading
        text="Βήμα 4. Επιβεβαίωση της σύνδεσης του Φορέα"
        id={`${props.idPrefix}registration-step4`}
        level="5"
      />
      <p>
        Μετά την ολοκλήρωση των παραπάνω βημάτων, ολοκληρώνεται ο κύκλος
        σύνδεσης του φορέα στην πλατφόρμα eDiplomas, οπότε
        <ul>
          <li>
            Εμφανίζεται το όνομα του φορέα στη λίστα των{' '}
            <a href="https://ediplomas.gr/graduate">
              eDiplomas εγγεγραμμένων φορέων
            </a>{' '}
            που ένας κάτοχος τίτλου μπορεί να επιλέξει για να δώσει πρόσβαση.
          </li>
          <li>
            Ενεργοποιείται η πρόσβαση του ΕΦ στις{' '}
            <a href="https://ediplomas.gr/organisation/menu">
              eDiplomas υπηρεσίες για φορείς
            </a>
            .
          </li>
        </ul>
      </p>
      <p>
        Η είσοδος των ΕΦ στο eDiplomas γίνεται με τον προσωπικό τους TAXISnet
        λογαριασμό, για να αποκτήσουν το ρόλο του φορέα τους στην πλατφόρμα και
        να εκτελέσουν εργασίες εξ ονόματος αυτού.
      </p>
    </div>
    <div className="inner section">
      <Heading
        text="Εγγραφή Φορέα για σύνδεση με API"
        id={`${props.idPrefix}registration-oauth2`}
        level="3"
      />
      <p>
        Οι οργανισμοί που διαθέτουν οργανωμένα πληροφοριακά συστήματα και
        επιθυμούν να συνδέσουν απευθείας τις εφαρμογές τους με το eDiplomas
        αξιοποιώντας το σχετικό API, μπορούν να αξιοποιήσουν τη σχετική υπηρεσία
        αποστέλλοντας ειδικό αίτημα στη διεύθυνση{' '}
        <a href="mailto:eDiplomas.registration@gunet.gr">
          eDiplomas.registration@gunet.gr
        </a>{' '}
        σύμφωνα με το παρακάτω πρότυπο.
      </p>
      <h5>Πρότυπο email αίτησης</h5>
      <MailExample
        mailto="eDiplomas.registration@gunet.gr"
        subject="eDiplomas Register: Αίτηση καταχώρισης νομικού προσώπου για σύνδεση με API"
        content={requestForOauth2Registration}
      />
    </div>
    <div className="inner section">
      <Heading
        text="Επικοινωνία & Υποστήριξη"
        id={`${props.idPrefix}support`}
        level="3"
      />
      <p>
        Γενικές διευκρινίσεις και υποστήριξη για τη σύνδεση φορέων στο eDiplomas
        παρέχεται από το GUnet στους ενδιαφερόμενους φορείς, αποστέλλοντας από
        εταιρική email δ/νση του φορέα το σχετικό ερώτημα προς το email{' '}
        <a href="mailto:info@gunet.gr">info@gunet.gr</a> σημειώνοντας στο
        Θέμα/Subject του email:
        <ul>
          <li>
            <strong>Σύνδεση νέου φορέα [Ονομασία Φορέα, ΑΦΜ Φορέα]</strong> όταν
            το ερώτημα αφορά την σύνδεση νέου φορέα.
          </li>
          <li>
            <strong>Υποστήριξη φορέα [Ονομασία Φορέα, ΑΦΜ Φορέα]</strong> όταν
            το ερώτημα αφορά ήδη συνδεδεμένο φορέα.
          </li>
        </ul>
      </p>
    </div>
  </>
);

export default OrganisationInfoEl;
