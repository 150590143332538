import React from 'react';
import OrganisationInfo from '../OrganisationInfo';

const OrganisationsGuideEl = (props) => (
  <>
    <h1 className="header">Φορείς</h1>
    <div className="inner section">
      <p>
        Στη σελίδα αυτή περιγράφεται η διαδικασία εγγραφής ενός Φορέα
        (Οργανισμός, Επιχείρηση, Υπηρεσία του Δημοσίου κ.ο.κ) για να αποκτήσει
        πρόσβαση στις υπηρεσίες του eDiplomas, μέσω της πλατφόρμας eDiplomas
        Register.
      </p>
    </div>
    <div className="inner section">
      <OrganisationInfo idPrefix="org_" polyglot={props.polyglot} />
    </div>
  </>
);

export default OrganisationsGuideEl;
