import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Message } from '../components/Message';
import Footer from '../Footer';
import './Organisation.css';
import Terms from './Terms';
import Menu from './Menu'; // WIP !!
import TokenEntry from './TokenEntry';
import LoginError from './LoginError';
import LogoutPage from './LogoutPage';
import GeneralError from '../GeneralError';
import Verify from './Verify';
import Check from './Check';
import LoggedIn from '../lib/LoggedIn';
import Authentication from '../lib/Authentication';
import InspectAllowed from '../lib/InspectAllowed';
import { scrollTop } from '../lib/Util';
import ExternalRedirect from '../lib/ExternalRedirect';

class Organisation extends Component {
  state = {
    userMenu: false
  };

  componentDidMount = async () => {
    scrollTop();
  };

  toggleUserMenu = () => {
    this.setState((prevState) => ({ userMenu: !prevState.userMenu }));
  };

  closeMenu = () => {
    this.setState({ userMenu: false });
  };

  render = () => {
    if (this.props.error) {
      return <GeneralError polyglot={this.props.polyglot} />;
    }

    return (
      <div className="Organisation Page">
        <div className="container" onClick={this.closeMenu}>
          <div className="content organisationContent">
            <h2 className="container-header">
              <i className="fas fa-briefcase" />{' '}
              {this.props.polyglot.t('Organisation.organisation')}
            </h2>
            <Switch>
              <Route
                path="/organisation/verify"
                render={(props) => (
                  <Verify
                    polyglot={this.props.polyglot}
                    history={props.history}
                  />
                )}
              />
              <Route
                path="/organisation/check"
                render={() => (
                  <>
                    <Authentication next="check" />
                    <LoggedIn>
                      <InspectAllowed
                        onYes={() => <Check polyglot={this.props.polyglot} />}
                        multiClient={() => (
                          <div className="error-description">
                            <Message
                              error
                              header={this.props.polyglot.t(
                                `Organisation.check.moreThanOneClients`
                              )}
                            >
                              <p>
                                {this.props.polyglot.t(
                                  `Organisation.check.moreClientsAdvice`
                                )}
                                .
                              </p>
                            </Message>
                            <div className="back-link">
                              <Link to="/" className="back-link">
                                <span className="fas fa-arrow-left" />{' '}
                                {this.props.polyglot.t(
                                  `GeneralError.goBackHome`
                                )}
                              </Link>
                            </div>
                          </div>
                        )}
                        onNo={() => (
                          <div className="error-description">
                            <h3>
                              {this.props.polyglot.t(
                                'Organisation.check.inspect'
                              )}
                            </h3>
                            <p>
                              {this.props.polyglot.t(
                                'Organisation.check.inspectDescription'
                              )}
                            </p>
                            <Message
                              error
                              header={this.props.polyglot.t(
                                'Organisation.check.oops'
                              )}
                            >
                              <p>
                                {this.props.polyglot.t(
                                  'Organisation.check.notAuthorizedToUse'
                                )}{' '}
                                <strong>
                                  {this.props.polyglot.t(
                                    'Organisation.check.inspectShort'
                                  )}
                                </strong>
                                .
                              </p>
                            </Message>
                            <Link to="/" className="back-link">
                              <span className="fas fa-arrow-left" />{' '}
                              {this.props.polyglot.t(
                                'Organisation.check.backHome'
                              )}
                            </Link>
                          </div>
                        )}
                      />
                    </LoggedIn>
                  </>
                )}
              />
              <Route
                path="/organisation/login/error"
                render={() => <LoginError polyglot={this.props.polyglot} />}
              />
              <Route
                path="/organisation/login"
                render={(props) => (
                  <Terms
                    query={props.location.search}
                    polyglot={this.props.polyglot}
                  />
                )}
              />
              <Route
                path="/organisation/logout"
                render={(props) => (
                  <LogoutPage
                    location={props.location}
                    polyglot={this.props.polyglot}
                  />
                )}
              />
              <Route
                path="/organisation/redirect/logout"
                render={() => (
                  <ExternalRedirect
                    to="/api/client/logout"
                    language={this.props.polyglot.currentLocale}
                  />
                )}
              />
              <Route
                path="/organisation/menu"
                render={() => <Menu polyglot={this.props.polyglot} />}
              />
              <Route
                path="/organisation/:token?"
                render={(props) => (
                  <TokenEntry
                    token={props.match.params.token}
                    history={props.history}
                    polyglot={this.props.polyglot}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
        <Footer polyglot={this.props.polyglot} />
      </div>
    );
  };
}

export default Organisation;
