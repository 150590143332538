import React, { Component } from 'react';

class CircleLoader extends Component {
  render = () => (
    <div
      className={`circle-loader ${this.props.loading ? '' : 'finished'} ${
        this.props.verified !== null && !this.props.loading
          ? this.props.verified
            ? this.props.hashesMatch == null || this.props.hashesMatch === true
              ? 'success'
              : 'no-match'
            : 'fail'
          : ''
      }`}
    >
      {this.props.verified !== null && !this.props.loading && (
        <div className="mark">
          <span>
            {this.props.verified ? (
              this.props.hashesMatch == null ||
              this.props.hashesMatch === true ? (
                <span className="checkmark fas fa-check" />
              ) : (
                <span className="no-match-mark fas fa-exclamation" />
              )
            ) : (
              <span className="failmark fas fa-exclamation" />
            )}
          </span>
        </div>
      )}
    </div>
  );
}

export default CircleLoader;
