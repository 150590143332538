import React from 'react';

const ErrorSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 300 350"
    width="450"
    height="500"
  >
    <defs>
      <path
        d="M103.12 70.17C107.34 66.4 119.84 65.47 140.63 67.36C171.8 70.21 189.66 76.05 190.28 80.52C191.5 89.46 192.12 91.07 183.53 103.59C177.8 111.93 184.55 114.37 203.77 110.92C244.26 106.94 269.21 120.45 278.62 151.44C288.03 182.43 284.75 203.88 268.8 215.8C197.15 265.54 145.78 290.61 114.69 291.01C68.07 291.6 65 268.36 57.64 258.23C50.28 248.1 69.29 217.11 70.52 204.6C71.34 196.25 67.04 189.5 57.64 184.33C37.19 164.87 27.37 147.59 28.17 132.49C28.98 117.39 42.68 103.29 69.29 90.18L103.12 70.17Z"
        id="d9MPbjNPD"
      />
      <path d="" id="a3Y6cngDe" />
      <path d="" id="b3SRU9ujZR" />
      <path d="" id="bLucFNC6n" />
      <path
        d="M129.84 155.02L121.88 142.19L138.09 151.85L117.8 124.59L140.63 142.19L131.25 111.9L150 125.85L107.65 49.76C97.93 45.11 87.04 43.1 75 43.75C62.96 44.4 56.71 55.91 56.25 78.29L129.84 155.02Z"
        id="b1CRBvylSw"
      />
      <path
        d="M209.88 268.86C216.2 275.84 252.22 262.55 254.22 248.57C255.55 239.26 253.33 228.61 247.55 216.63L170.45 154.07L148.89 134.1L160.6 164.05L140.89 149.41L148.89 165.55L135.55 158.06L140.89 171.37C182.66 231.71 205.66 264.21 209.88 268.86Z"
        id="bS8XOs8k"
      />
      <path d="" id="caJ5BW99d" />
      <path
        d="M89.11 37.24C85.03 35.2 77.71 36.73 67.17 41.83L139.11 140.3L131.25 112.24L148.8 125.78L106.97 49.22C99.14 43.27 93.19 39.28 89.11 37.24Z"
        id="b2ixuPgcTv"
      />
      <path
        d="M159.48 163.72L246.77 262.5C255.09 257.07 260.15 252.64 261.96 249.21C263.77 245.78 261.04 236.35 253.79 220.92L148.56 133.92L159.21 163.6L140.62 148.58"
        id="e14Zd7XrXu"
      />
      <path
        d="M143.5 178.04L151.25 187.87C177.8 171.77 188.84 163.3 184.38 162.45C178.82 161.39 168.21 145.57 165.28 147.66C164.68 148.08 165.85 148.63 165.85 150.9C165.85 159.83 156.72 164.71 138.47 165.56L143.5 178.04Z"
        id="ahmY39enF"
      />
    </defs>
    <g>
      <g>
        <g>
          <use
            xlinkHref="#d9MPbjNPD"
            opacity="1"
            fill="#eae3ea"
            fillOpacity="0.77"
          />
          <g>
            <use
              xlinkHref="#d9MPbjNPD"
              opacity="1"
              fillOpacity="0"
              stroke="#eae3ea"
              strokeWidth="1"
              strokeOpacity="0.72"
            />
          </g>
        </g>
        <g>
          <use
            xlinkHref="#a3Y6cngDe"
            opacity="1"
            fill="#7c0c40"
            fillOpacity="1"
          />
          <g>
            <use
              xlinkHref="#a3Y6cngDe"
              opacity="1"
              fillOpacity="0"
              stroke="#000000"
              strokeWidth="1"
              strokeOpacity="1"
            />
          </g>
        </g>
        <g>
          <g>
            <use
              xlinkHref="#b3SRU9ujZR"
              opacity="1"
              fillOpacity="0"
              stroke="#000000"
              strokeWidth="1"
              strokeOpacity="1"
            />
          </g>
        </g>
        <g>
          <g>
            <use
              xlinkHref="#bLucFNC6n"
              opacity="1"
              fillOpacity="0"
              stroke="#000000"
              strokeWidth="1"
              strokeOpacity="1"
            />
          </g>
        </g>
        <g>
          <use
            xlinkHref="#b1CRBvylSw"
            opacity="1"
            fill="#dedcc7"
            fillOpacity="1"
          />
          <g>
            <use
              xlinkHref="#b1CRBvylSw"
              opacity="1"
              fillOpacity="0"
              stroke="#000000"
              strokeWidth="1"
              strokeOpacity="1"
            />
          </g>
        </g>
        <g>
          <use
            xlinkHref="#bS8XOs8k"
            opacity="1"
            fill="#dedcc7"
            fillOpacity="1"
          />
          <g>
            <use
              xlinkHref="#bS8XOs8k"
              opacity="1"
              fillOpacity="0"
              stroke="#000000"
              strokeWidth="1"
              strokeOpacity="1"
            />
          </g>
        </g>
        <g>
          <g>
            <use
              xlinkHref="#caJ5BW99d"
              opacity="1"
              fillOpacity="0"
              stroke="#000000"
              strokeWidth="1"
              strokeOpacity="1"
            />
          </g>
        </g>
        <g>
          <use
            xlinkHref="#b2ixuPgcTv"
            opacity="1"
            fill="#dedcc7"
            fillOpacity="1"
          />
          <g>
            <use
              xlinkHref="#b2ixuPgcTv"
              opacity="1"
              fillOpacity="0"
              stroke="#000000"
              strokeWidth="1"
              strokeOpacity="1"
            />
          </g>
        </g>
        <g>
          <use
            xlinkHref="#e14Zd7XrXu"
            opacity="1"
            fill="#dedcc7"
            fillOpacity="1"
          />
          <g>
            <use
              xlinkHref="#e14Zd7XrXu"
              opacity="1"
              fillOpacity="0"
              stroke="#000000"
              strokeWidth="1"
              strokeOpacity="1"
            />
          </g>
        </g>
        <g>
          <use
            xlinkHref="#ahmY39enF"
            opacity="1"
            fill="#b76a5e"
            fillOpacity="1"
          />
          <g>
            <use
              xlinkHref="#ahmY39enF"
              opacity="1"
              fillOpacity="0"
              stroke="#000000"
              strokeWidth="1"
              strokeOpacity="1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ErrorSvg;
