export default {
  Graduate: {
    graduate: 'Graduate',
    step1: {
      title: 'Step 1',
      text: 'Organization Selection'
    },
    step2: {
      title: 'Step 2',
      text: 'Degree Selection'
    },
    step3: {
      title: 'Step 3',
      text: 'Authorization Link'
    },
    // Step 1
    title1: 'Who is allowed to access my diplomas?',
    paragraph1a:
      'Select the name of the organization/entity to which you want to grant access to information regarding your university degree(s). You can also select the option "Κάθε ταυτοποιημένος χρήστης" to grant access to anyone, with a TAXISnet account, you give the link.',
    paragraph1b: 'Check your',
    paragraph1c: 'Authorization History',
    paragraph1d: 'to see if there is an active authorization that can be used.',
    placeholder1: 'Organization Selection...',
    organization: 'Selected Organization:',
    back: 'Back',
    continue: 'Continue',
    // Step2
    title2: 'What data are they allowed to access?',
    paragraph2:
      "Log into your TAXISnet account to check and select the degrees' data for which you will grant access.",
    bullet1: 'Redirecting and logging into TAXISnet.',
    bullet2: 'Selection of the data that will be shared.',
    // Step 3
    title3: 'Authorization Link',
    paragraph3a: 'The link you will have to send to ',
    paragraph3b:
      "in order for them to download your degree's information is given below.",
    forgotToLogOutTaxis: 'Forgot to log out of TAXISnet',
    wantToLogOutTaxis: 'To log out follow the following link',
    taxisLogout: 'Log out of TAXISnet',
    warning1:
      'For your security, log out of TAXISnet if you have completed your work on this workstation.',
    warning2:
      'It is possible that you will remain logged in other applications that use login through TAXISnet service.',
    token: 'Code',
    QRCode: 'QR Code',
    show: 'Show',
    hide: 'Hide',
    duration: 'Duration',
    paragraph4: 'This link will be remain until: ',
    codeCopied: 'Link copied'
  },
  Landing: {
    category1: 'I am an owner of a University degree',
    paragraph1:
      'I want to grant access to information about my University degrees',
    category2: 'I am an organization representative',
    paragraph2: 'I want to gain access to information about University degrees',
    continue: 'Continue',
    showMore: 'I want to know more',
    backToTop: 'Back to top',
    ImGrad: 'I am an owner of a University degree',
    ImOrg: 'I am an organization representative',
    continueAs: 'Continue As',
    logout: 'Logout',
    contents: {
      title: 'Contents',
      bullet1: 'Overview',
      bullet2: 'Graduates',
      bullet3: 'Organizations'
    },
    Cards: {
      title1: 'Security',
      paragraph1:
        "By using digital signing and cryptography technologies, the authenticity of the degree's information is ensured and the access is given only to authorized clients.",
      title2: 'Ease',
      paragraph2:
        'After just a couple of clicks and the use of TAXISnet credentials, the user authorizes a third-party to draw information about the degrees they own.',
      title3: 'Interoperability',
      paragraph3:
        'Clients with candidate data submission applications of their own, can connect to eDiplomas through the use of well defined standards.'
    },
    title1: 'Overview',
    paragraph3:
      'Through eDiplomas, the citizen and owner of degrees issued by greek HEIs is able, by using their TAXISnet account, to authorize a client (public Institution or company) to receive information regarding their degrees. Citizens ',
    paragraph3extra:
      'explicitly select the data to be shared and the client that will receive it',
    paragraph4:
      "The platform uses technologies which ensure the protection of private data as well as the authenticity of the degrees' information. By making the submission and degree verification processes simple and fast for the owner as well as the client, it is, at the same time, attempted to eliminate the phenomenon of fake and counterfeit degrees.",
    title2: 'Graduates',
    paragraph5:
      "Ediplomas has been developed by the national HEIs in collaboration with the Greek Universities Network (GUnet). It is constantly evolving and advancing, by making use of the technical expertise and digital infrastructure of the country's Institutions.",
    title3: 'Organizations',
    paragraph6:
      "Avoid the issuing of degree copies and the delivery - shipment to third parties (f.e. future employers) processes. Log in, by using your TAXISnet credentials and choose easily and fast who can have access and to which parts of your degrees' information.",
    paragraph7a: 'Log in to',
    paragraph7b:
      "using the institution's TAXISnet account, invite via email the members of the institution who will have access to the eDiplomas services for institutions and gain quick and easy access to reliable data of degrees.",
    presentations: 'Learn more about ediplomas',
    eDiplomas: 'eDiplomas',
    presentationFilename: 'Presentation',
    demoFilename: 'Demo',
    documentationName: 'Documentation',
    uoaAnnouncement: 'UoA Announcement',
    priviledge2019: 'PRIViLEDGE Project Blog 11/2019',
    priviledge2020: 'PRIViLEDGE Project Blog 11/2020',
    emrex: 'eDiplomas joins EMREX',
    videoEBSI: 'EBSI Multi University Pilot 04/03/2022',
    blueprintsInfo: 'eDiplomas Blueprints 10/01/2022',
    jadesInfo: 'JAdES Plugtest 11/2021 - 01/2022',
    articleEBSI:
      'EBSI: Cypriot Citizen’s journey as a Greek University graduate 12/2021',
    articlesTitle: 'Articles & News',
    presentationsTitle: 'Presentations',
    presentationGunet: 'GUnet Technical Meeting 6/2019',
    presentationUoi:
      'GUnet Technical Meeting - University of Ioannina in eDiplomas 06/2022',
    presentationEUNIS: 'EUNIS 2020 Virtual Helsinki 6/2020',
    interactivePresentation: 'Interactive Presentation of eDiplomas 4/2022',
    presentationΗμερίδα: 'eDiplomas Chania Crete Meeting (Greek) 11/2020',
    blueprintsNewsHeader:
      'eDiplomas Blueprints platform for template registration (10/01/2022 - 28/02/2022)',
    blueprintsNews1: 'All Greek Universities are invited to submit to',
    blueprintsNews2:
      'all the bachelor or equivalent diploma templates that have been issued since 1/1/2010.',
    news: 'News'
  },
  Organisation: {
    organisation: 'Organization',
    taxis: {
      taxisLogin: 'Log in with TAXISnet',
      accessGranted:
        'Access to eDiplomas services for organisations is granted to:',
      registeredEntity:
        'designated representatives of the organisation regarding each service (only for registered organisations)',
      taxisUsers:
        'any citizen, if the holder of the degree has selected the authorization option dedicated to "TAXISnet Users"',
      accessComment:
        'Access is granted through the TAXISnet account of the representative/individual.',
      doLogin: 'Log in'
    },
    tokenEntry: {
      diplomasByAuthorization: 'Degrees through Authorization',
      searchHeader: 'Gain access by using the code you have received.',
      hint: 'The degree owner is required to issue the code.',
      paragraph1point1:
        "In order to access information regarding a citizen's university degree, you have to become authorized by them. Then the citizen will receive a ",
      paragraph1point2: 'code',
      paragraph1point3: ', which you have to enter in the form below.',
      label: 'Code',
      downloadUntil:
        'You can download the certifications of the following degrees until',
      codeNote: 'Auth. Code',
      searchButton: 'Search',
      errorMessageHeader: 'Oops!',
      errorMessageInvalidOrExpired:
        'The code you enter is invalid, expired or not issued for your organization.',
      errorMessageGeneral: 'Something went wrong. Try again later.',
      signatureCopied: 'Signature copied',
      newSearch: 'Search with another code'
    },
    logout: {
      logout: 'Logout',
      haveLoggedOut: 'You have logged out of eDiplomas.',
      wantToLogOutTaxis:
        'If you want to log out of TAXISnet follow the link below',
      taxisLogout: 'Log out of TAXISnet',
      warning1:
        'For your security, log out of TAXISnet if you have completed your work on this workstation.',
      warning2:
        'It is possible that you will remain logged in for other applications that use login through TAXISnet service.',

      backHome: 'Return to Homepage'
    },
    taxisLogout: {
      logout: 'Log out of TAXISnet',
      haveLoggedOut: 'You have successfully logged out of TAXISnet.',
      goBackHome: 'Return to Homepage'
    },
    menu: {
      diplomasByAuthorization: 'Degrees through Authorization',
      getAccessToDegreesInfo:
        'Get access to information regarding the degrees you are authorized for by',
      signatureVerification: 'Signature Validity Verification',
      verifySignatureValidityDiplomasIssuedBy:
        "Verify the digital signatures' validity of degrees issued by",
      ediplomas: 'eDiplomas',
      diplomaCheck: 'Ex-Officio Degree Check',
      verifyDegreeAuthenticity:
        'Verify the authenticity of degree copies that have been submitted to your entity and have not been issued by',
      onlyPublicSector: '(only for public-sector entities)'
    },
    menuGraduate: {
      authorizeDiploma: 'Degree Authorization',
      newAuthorizationInfo:
        'Authorize access to information regarding your degree.',
      viewDiplomas: 'Degrees',
      controlDiplomas: 'Search and download the certification of your degree.',
      viewAuthorizations: 'Authorization History',
      controlAuthorizations:
        'See the authorizations you have granted and the history of the access on your data.'
    },
    check: {
      inspect: 'Ex-Officio Degree Check',
      inspectDescription:
        'By using the ex-officio degree check service, you will be able to verify the validity of degree copies you have in your possession, if you are authorized by the Institution in charge.',
      byFilling: 'By filling the',
      institution: 'Insitution',
      ssn: 'SSN',
      the: 'the',
      andOtherYouCanGet:
        'as well as various data regarding the degree, you can search for it in the platform and receive the',
      signed: 'signed',
      digital: 'digital',
      editionFrom: 'version issued by ',
      change: 'Edit',
      institutionSelect: 'Institution Selection',
      department: 'Department',
      level: 'Level',
      fullname: 'Fullname',
      date: 'Date',
      firstname: 'First Name',
      lastname: 'Last Name',
      wrongDate: 'Invalid Date',
      theLaw:
        'I am aware that any search operation is subject to legal oversight according to the General Data Protection Regulation (EU) 2016/679. In this case, each search must be documented by title information deposited to the organization',
      search: 'Search',
      continue: 'Continue',
      searchResults: 'Search Results',
      successfulFetch: 'Successful retrieval of results',
      unsuccessfulFetch: 'Unsuccessful retrieval of results',
      notFound: 'There are no results for the above data.',
      note: 'Note',
      maybeNotSubmittedAt:
        'It is possible that the degree you searched for has not been registered to',
      byInstitution: 'by the corresponding Institution yet',
      info: 'Information about the Institutions and the degrees available in',
      canBeFoundAt: 'can be found here:',
      gradInfo: 'Degree/Graduate Data',
      dateInfo: 'Date of graduation or completion',
      notAuthorized:
        'You are not authorized to perform ex-officio checks in the chosen department',
      bachelor: 'Bachelor',
      master: 'Master',
      doctorate: 'Doctorate',
      option: 'Option',
      moreThanOneClients:
        'You are already logged in to this application with another client',
      moreClientsAdvice: 'Log out from that client to continue',
      notAuthorizedToUse: 'You do not have the required permissions to use',
      oops: 'Error',
      backHome: 'Back to home page',
      inspectShort: 'Ex-Officio Degree Check'
    },
    loginError: {
      loginFailed: 'Login Failed!',
      taxisSuccessFulButTin: 'Login to TAXISnet was successful, but your TIN ',
      notAuthorized: 'does not match to an user authorized',
      forEdiplomasUse: 'to use eDiplomas',
      backHome: 'Return to Homepage'
    },
    degree: 'Degree',
    degrees: 'Degrees',
    print: 'Print',
    pdfVersion: 'Download PDF',
    close: 'Close',
    copy: 'Copy',
    Qr: 'QR Code',
    Text: 'Text',
    navigateToMenu: 'Navigate to the menu',
    dateFollowsFormat: 'The date follows the format DD:MM:YYYY'
  },
  AdoptionRates: {
    participatingInstitutions: 'Participating Institutions',
    participatingInstitution: 'Participating Institution',
    registeredDiplomas: 'Registered Diplomas',
    comments: 'Comments',
    contact: 'Contact',
    requestServing: 'Request Support',
    signedPdf: 'Signed PDF',
    yes: 'Yes',
    no: 'No',
    diplomas: {
      undergraduate: 'Bachelor',
      postgraduate: 'Master',
      doctorate: 'Doctoral'
    },
    contactInfo: {
      web: 'Web',
      telephone: 'Telephone',
      email: 'E-mail'
    },
    betaAdopters: 'Pre-registration',
    betaAdoptersLink: 'Institutions in pre-registration'
  },
  GeneralError: {
    oops: 'Oops!',
    somethingWentWrong: 'Something went wrong',
    tryLater: 'Try again later.',
    goBackHome: 'Return to Homepage',
    tryAgain: 'Try Again'
  },
  diplomaFields: {
    gradeValue: 'Grade (Value)',
    gradeDescription: 'Grade (Description)',
    date: 'Date',
    issuerInstitution: 'Issuing Institution',
    maintainerInstitution: 'Maintainer',
    issuerUnit: 'Issuing Unit',
    level: 'Level of Education',
    title: 'Degree Title',
    validFrom: 'Date of Completion',
    dateIssued: 'Date of Graduation',
    ownerInfo: 'Owner',
    firstNameEl: 'First Name',
    middleNameEl: 'Middle Name',
    lastNameEl: 'Last Name',
    parentNames: "Parents' Names",
    birthDate: 'Date of Birth',
    fatherNameEl: "Father's Name",
    motherNameEl: "Mother's Name",
    signature: 'Signature',
    signedAt: 'Timestamp',
    signedFor: 'Signed for',
    notes: 'Diploma Notes'
  },
  educationalLevels: {
    6: "6 - Bachelor's or equivalent level",
    64: '64 - Academic',
    641: '641 - Insufficient for level completion',
    645: '645 - First degree (3–4 years)',
    646: '646 - Long first degree (more than 4 years)',
    647: "647 - Second or further degree (following a bachelor's or equivalent programme)",
    65: '65 - Professional',
    651: '651 - Insufficient for level completion',
    655: '655 - First degree (3–4 years)',
    656: '656 - Long first degree (more than 4 years)',
    657: "657 - Second or further degree (following a bachelor's or equivalent programme)",
    66: '66 - Orientation unspecified',
    661: '661 - Insufficient for level completion',
    665: '665 - First degree (3–4 years)',
    666: '666 - Long first degree (more than 4 years)',
    667: "667 - Second or further degree (following a bachelor's or equivalent programme)",
    7: "7 - Master's or equivalent level",
    74: '74 - Academic',
    741: '741 - Insufficient for level completion',
    746: '746 - Long first degree (at least 5 years)',
    747: "747 - Second or further degree (following a bachelor's or equivalent programme)",
    748: "748 - Second or further degree (following a master's or equivalent programme)",
    75: '75 - Professional',
    751: '751 - Insufficient for level completion',
    756: '756 - Long first degree (at least 5 years)',
    757: "757 - Second or further degree (following a bachelor's or equivalent programme)",
    758: "758 - Second or further degree (following a master's or equivalent programme)",
    76: '76 - Orientation unspecified',
    761: '761 - Insufficient for level completion',
    766: '766 - Long first degree (at least 5 years)',
    767: "767 - Second or further degree (following a bachelor's or equivalent programme)",
    768: "768 - Second or further degree (following a master's or equivalent programme) ",
    8: 'Doctoral or equivalent'
  },
  Verifier: {
    signatureVerification: 'Signature Verification',
    verify: 'Verify Siganture',
    invalidSignature: 'The digital signature you entered is not valid!',
    invalidInput: 'The text you entered is not a valid signature format',
    errorHeader: 'Unable to verify signature',
    enterSignature:
      "Please enter the degree's digital signature you want to verify in the form below",
    signature: "Degree's Digital Signature",
    notMatchingHeader: 'Valid Signature',
    notMatchingContent: "The degree's content has changed.",
    newCheck: 'Check another digital signature'
  },
  TermsOfUse: {
    termsOfUse: 'Terms of Use',
    termsParagraph1:
      'GUnet takes all the measures necessary for ensuring the correct functioning of the service. GUnet and the participating HEIs bear no responsibility for any damage (direct or indirect, positive or deponent) occurred to the user from the use or inability to use, from delays during the use, mistakes or oversights, connection interruptions, damages of the power supply network or other factors.',
    termsParagraph2:
      "Logging into the service takes place by the use of each user's personal credentials. The user that wishes to use the said service, is responsible for every data submission or authorization that they perform.",
    termsParagraph3:
      'The user that wishes to use the said service, oughts to provide real, precise, valid and complete information during its use.',
    termsParagraph4:
      "The information that refers to degree's data and appears in the service is provided through direct connection to the corresponding HEIs' information systems. GUnet takes all the measures necessary for ensuring the precision of the data appearing in the service, however GUnet is responsible only for the reappearance of the information and is under no circumstances responsible for its content, neither is in charge for providing clarifications or explanations of any sort on the said data. The participating HEIs who provide the primary pieces of information are in charge of the previous tasks.",
    termsParagraph5:
      "All the data provided by the user is not being used for any other cause other than the one described by this service. The precision of the data submitted by the user are their own responsibility and any damage (direct or indirect, positive or deponent) derived from the submission of imprecise data, holds themselves accountable and is under no circumstances GUNet's or the participating HEI's responsibility.",
    termsParagraph6:
      "The service does not store degree's data nor user's personal information and credentials, apart from the Social Security Number (SSN) which serves as the basic asset for the implementation of the service's functionality. This asset is stored only temporarily, is encrypted and is in the user's convenience to ask for its deletion, as long as they proceed to the revocation of their authorizations granted to clients.",
    termsParagraph7Part1:
      "The service uses only session cookies for the user's access to the application, through TAXISnet credentials. Cookies are small text files which are stored in each user's hard drive and do not contain personal data for the identification of the users of the web nor they acquire knowledge about any of the documents or files on the user's computer. These cookies are automatically deleted after the logout from the service. The user can",
    termsParagraph7Part2: 'adjust',
    termsParagraph7Part3:
      'their Web navigation program in such a way that it either notifies them for the use of "cookies" in specific applications or it does not allow accepting the use of "cookies" in any case. Noting that in case the that user does not approve the use of "cookies" for their identification, their connection to the service through their TAXISnet credentials is not possible.'
  },
  Documentation: {
    documentation: 'Documentation',
    developers: 'Developers',
    connectionThirdParties: "Third-party applications' connection to eDiplomas",
    institutions: 'Institutions',
    institutionIntegration: 'Institution integration into eDiplomas',
    descriptionParagraph1:
      "Ediplomas is a new online platform for the verification of HEI degrees ownership. Through eDiplomas, the citizen and owner of degrees issued by greek HEIs is able, by using their TAXISnet account, to authorize a client (public Institution or company) to receive information regarding their degrees. The platform uses technologies which ensure the protection of private data as well as the authenticity of the degrees' information. By making the submission and degree verification processes simple and fast for the owner as well as the client, it is, at the same time, attempted to eliminate the phenomenon of the fake and counterfeit degrees.",
    descriptionParagraph2:
      'Ediplomas is consisted by separate information systems, each of them offering a specific service and providing the corresponding interface for connecting to third-parties.',
    organisations: 'Organisations',
    organisationRegistration: 'Organisation registration on eDiplomas services',
    backToTop: 'Back to Top',
    navbar: {
      contents: 'Contents',
      documentation: 'Home',
      developers: 'Developers',
      institutions: 'Institutions',
      organisations: 'Organisations',
      oauthIntegrationGuide:
        'Integration using OAuth2 for third-party services',
      description: 'Description',
      quickStart: 'Quick Start',
      orgClients: 'eDiplomas Services for Organizations',
      scopes: 'Scopes',
      clientRegistration: 'Client Registration',
      oauth2: 'OAuth2',
      accessToDegree: 'Access to Degrees',
      customClientAuthFlowDesc: 'Authorization for custom clients',
      authProcess: 'Authorization Process',
      tokenRenewal: 'Token Renewal',
      degreeTemplateCreation: 'Degree Template Creation',
      generally: 'Overview',
      indicativeExample: 'Example',
      gradDataInsert: 'Graduate Information Entry',
      throughView: 'Through View',
      schema: 'Schema',
      fields: 'Fields',
      throughAPI: 'Through API',
      indicativeExamples: 'Examples',
      requestService: 'Request support',
      ediplomasSupportAccess: 'Access to Ediplomas Support',
      eduPersonEntitlementExamples: 'eduPersonEntitlement Examples',
      notValidValuesExamples: 'Examples of Invalid Values',
      introduction: 'Introduction',
      basicConcepts: 'Basic Concepts',
      orgExOfficio: 'Entity Registration for Ex-Officio checks',
      orgClientNoApp: 'Entity Registration for ediplomas.gr',
      orgClientWithApp: 'Organisation Registration for API Usage',
      orgStepsEdiplomas: 'Organisation Registration for ediplomas.gr',
      orgSupport: 'Contact & Support',
      step1:
        'Step 1. Request for initialization of a legal entity on eDiplomas',
      step2:
        "Step 2. Log in to eDiplomas Register by the Organisation's Administrator",
      step3:
        "Step 3. Invitation acceptance by the Organisation's representatives",
      step4:
        "Step 4. Confirmation of the Organisation's registration in eDiplomas"
    }
  },
  newsBreadcrumbs: {
    home: 'Home',
    news: 'News'
  },
  Footer: {
    services: 'Services',
    info: 'Information',
    contact: 'Contact',
    web: 'Web',
    email: 'E-mail for Organizations and HEIs',
    participatingInstitutions: 'Participating Institutions',
    signatureVerification: 'Signature Verification',
    diplomaCheck: 'Ex-officio Degree Check',
    termsOfUse: 'Terms of Use',
    revocation: 'Authorization Revocation',
    templateHandlingApp: 'Degree Template Administration Service',
    ediplomasRegister: 'Organisation Registration in eDiplomas',
    developersGuide: 'Documentation',
    supportRequests: 'Support Requests'
  }
};
