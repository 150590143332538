import React from 'react';
import { Message } from './Message';

export const Announcement = (props) => {
  return (
    <Message
      icon="newspaper"
      backgroundColor={props.backgroundColor}
      header={props.header}
      url={props.url}
    >
      <p dangerouslySetInnerHTML={{ __html: props.text }} />
    </Message>
  );
};
