import React from 'react';
import './Collapse.css';

export const Collapse = (props) => {
  return (
    <div className="Collapse">
      <div
        onClick={props.handleClick}
        className={`inv-boxed ${props.open ? 'bordered' : ''}`}
      >
        <div className="institutionLogoContainer">
          <img className="institutionLogo" src={props.logo} />
        </div>
        {props.title}
      </div>
      <div className={`body ${props.open ? 'open' : ''}`}>
        <div className="section">
          <div className="boxed">
            <span className="fas fa-graduation-cap"></span> Καταχωρισμένοι
            Τίτλοι{' '}
          </div>
          <div>
            <span className="stat">Προπτυχιακά:</span>{' '}
            <span>{props.bachelors}</span>
          </div>
          <hr className="solid" />
          <div>
            <span className="stat">Μεταπτυχιακά: </span>{' '}
            <span>{props.masters}</span>
          </div>
          <hr className="solid" />
          <div>
            <span className="stat">Διδακτορικά: </span>{' '}
            <span>{props.phds}</span>
          </div>
        </div>
        <div className="section">
          <div className="boxed">
            <span className="fas fa-envelope"></span> Επικοινωνία
          </div>
          <div>
            <span className="stat">Ιστός: </span>{' '}
            <span>
              <a
                href={`http://${props.web}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.web}
              </a>
            </span>
          </div>
          <hr className="solid" />
          <div>
            <span className="stat">Τηλέφωνο: </span> <span>{props.phone}</span>
          </div>
          <hr className="solid" />
          <div>
            <span className="stat">E-mail: </span> <span>{props.email}</span>
          </div>
        </div>
        <div className="section">
          <div className="boxed">
            <span className="fas fa-file-signature"></span> Παροχή Υπ/μένου PDF
          </div>
          <div>
            <span>
              <div className="signedPdf">
                {props.signedPdf
                  ? props.polyglot.t(`AdoptionRates.yes`)
                  : props.polyglot.t(`AdoptionRates.no`)}
              </div>
            </span>
          </div>
        </div>
        <div className="section">
          <div className="boxed">
            <span className="fas fa-plus"></span> Σχόλια
          </div>
          <div>
            <span>
              <div className="comment">{props.comments}</div>
            </span>
          </div>
        </div>
        <div className="section">
          <div className="boxed">
            <span className="fas fa-ticket-alt"></span> Εξυπηρέτηση Αιτημάτων
          </div>
          <div>
            <span className="stat">Προπτυχιακά:</span>{' '}
            <span>
              {props.support.bachelor
                ? props.polyglot.t(`AdoptionRates.yes`)
                : props.polyglot.t(`AdoptionRates.no`)}
            </span>
          </div>
          <hr className="solid" />
          <div>
            <span className="stat">Μεταπτυχιακά: </span>{' '}
            <span>
              {props.support.master
                ? props.polyglot.t(`AdoptionRates.yes`)
                : props.polyglot.t(`AdoptionRates.no`)}
            </span>
          </div>
          <hr className="solid" />
          <div>
            <span className="stat">Διδακτορικά: </span>{' '}
            <span>
              {props.support.doctorate
                ? props.polyglot.t(`AdoptionRates.yes`)
                : props.polyglot.t(`AdoptionRates.no`)}
            </span>
          </div>
        </div>
      </div>
      <hr className="solid" />
      <hr
        onClick={props.handleClick}
        className={`arrow-down ${props.open ? 'arrow-up' : ''}`}
      />
    </div>
  );
};
