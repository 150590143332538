import React from 'react';
import ReactDOMServer from 'react-dom/server';
import './MailTemplate.css';

const MailTemplate = (props) => {
  let staticContent = ReactDOMServer.renderToStaticMarkup(props.content);
  staticContent = staticContent.replace(/<\s*br(\/)?\s*>/g, '\n');
  staticContent = staticContent.replace(/<\s*(\/)?\s*(div|p)\s*>/g, '\n');
  staticContent = staticContent.replace(/<\/?((?!<).)*>/gm, '');

  return (
    <a
      className={props.className}
      href={`mailto:${encodeURI(props.mailto)}?subject=${encodeURI(
        props.subject
      )}&body=${encodeURI(staticContent)}`}
      data-tooltip="Αποστολή E-mail"
    >
      <span className="fa fa-envelope" />
    </a>
  );
};

export default MailTemplate;
