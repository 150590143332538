import React from 'react';
import OrganisationInfo from '../OrganisationInfo';

const OrganisationsGuideEn = (props) => (
  <>
    <h1 className="header">Organisations</h1>
    <div className="inner section">
      <p>
        This page describes the registration process for an Entity
        (Organisation, Corporation, Public Service etc) so that it can access
        eDiplomas' services through the eDiplomas Register platform.
      </p>
    </div>
    <div className="inner section">
      <OrganisationInfo idPrefix="org_" polyglot={props.polyglot} />
    </div>
  </>
);

export default OrganisationsGuideEn;
