import React from 'react';

const AuthContext = React.createContext({
  loggedIn: false,
  clientName: '',
  loading: 1,
  canInspect: false,
  inspectInstitutions: [],
  logout: () => {},
  login: () => {},
  refresh: () => {}
});

class AuthProvider extends React.Component {
  state = {
    loggedIn: false,
    clientName: '',
    userName: '',
    loading: 1 /* Start as loading */,
    canInspect: false,
    inspectInstitutions: [],
    logout: () => this.handleLogout(),
    login: () => this.handleLogin(),
    refresh: () => this.refresh()
  };

  refresh = () => {
    // Do not check if last attempt was less than a second ago
    if (
      !localStorage.getItem('last_auth_refresh') ||
      Date.now() - localStorage.getItem('last_auth_refresh') > 1000
    ) {
      this.setState(
        (prevState) => ({ loading: prevState.loading + 1 }),
        this.getAuthData
      );
    }
  };

  componentDidMount = () => {
    this.getAuthData();
  };

  getAuthData = async () => {
    localStorage.setItem('last_auth_refresh', Date.now());
    this.setState({
      error: false
    });
    try {
      const res = await fetch('/api/client/get_auth_data', {
        method: 'GET',
        credentials: 'include'
      });
      let resJson;
      resJson = await res.json();
      if (!resJson.logged_in) {
        this.handleLogout();
      } else {
        this.setState({
          loggedIn: true,
          clientName: resJson.name,
          userName: resJson.user_name,
          canInspect: resJson.can_inspect,
          inspectInstitutions: resJson.inspect_institutions,
          clients: resJson.clients,
          error: false
        });
      }
    } catch (e) {
      this.handleLogout();
      this.setState({ error: true });
    }
    this.setState((prevState) => ({
      ...prevState,
      loading: prevState.loading - 1
    }));
  };

  handleLogout = () => {
    this.setState({ loggedIn: false, clientName: '' });
  };

  render = () => (
    <AuthContext.Provider value={this.state}>
      {this.props.children}
    </AuthContext.Provider>
  );
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
