import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './gunet.svg';
import './Footer.css';

const Footer = (props) => (
  <div className="Footer">
    <span className="left content">
      <div id="services">
        <h3 className="header">{props.polyglot.t('Footer.services')}</h3>
        <ul>
          <li>
            <span className="fas fa-check-circle" />
            &nbsp;&nbsp;
            <Link to="/organisation/verify">
              {' '}
              {props.polyglot.t(`Footer.signatureVerification`)}
            </Link>
          </li>
          <li>
            <span className="fas fa-search" />
            &nbsp;&nbsp;
            <Link to="/organisation/check">
              {props.polyglot.t(`Footer.diplomaCheck`)}
            </Link>
          </li>
          <li>
            <span className="fas fa-undo" />
            &nbsp;&nbsp;
            <a
              href="https://authorization.ediplomas.gr/mydiplomas/consents"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.polyglot.t(`Footer.revocation`)}
            </a>
          </li>
          <li>
            <span className="fas fa-tools" />
            &nbsp;&nbsp;
            <a
              href="https://blueprints.ediplomas.gr"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.polyglot.t(`Footer.templateHandlingApp`)}
            </a>
          </li>
          <li>
            <span className="fas fa-scroll" />
            &nbsp;&nbsp;
            <a
              href="https://register.ediplomas.gr"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.polyglot.t(`Footer.ediplomasRegister`)}
            </a>
          </li>
          <li>
            <span className="fas fa-envelope-open-text" />
            &nbsp;&nbsp;
            <a
              href="https://support.ediplomas.gr"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.polyglot.t(`Footer.supportRequests`)}
            </a>
          </li>
        </ul>
      </div>
      <div id="info">
        <h3 className="header">{props.polyglot.t('Footer.info')}</h3>
        <ul>
          <li>
            <span className="fas fa-university" />
            &nbsp;&nbsp;
            <Link to="/institutions">
              {' '}
              {props.polyglot.t(`Footer.participatingInstitutions`)}
            </Link>
          </li>
          <li>
            <span className="fas fa-handshake" />
            &nbsp;&nbsp;
            <Link to="/terms">{props.polyglot.t(`Footer.termsOfUse`)}</Link>
          </li>
          <li>
            <span className="fas fa-code" />
            &nbsp;&nbsp;
            <Link to="/documentation">
              {props.polyglot.t(`Footer.developersGuide`)}
            </Link>
          </li>
        </ul>
      </div>
      <div id="contact">
        <h3 className="header">{props.polyglot.t('Footer.contact')}</h3>
        <ul>
          <li>
            <span className="strong subheader">
              <span className="fas fa-globe-africa" />
              &nbsp;&nbsp;
              {props.polyglot.t('Footer.web')}:
            </span>
            <a
              href="https://www.gunet.gr"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.gunet.gr
            </a>
          </li>
          <li>
            <span className="strong subheader">
              <span className="fas fa-briefcase" />
              &nbsp;&nbsp;
              {props.polyglot.t('Footer.email')}:
            </span>
            <a href="mailto:info@gunet.gr">info@gunet.gr</a>
          </li>
          <li>
            <div className="copyright-area">
              <img id="FooterLogo" src={Logo} alt="GUnet Logo" />
              <div id="copyright-tag">
                <small>
                  <span className="far fa-copyright" /> 2023 | GUnet
                </small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </span>
  </div>
);

export default Footer;
