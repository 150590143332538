import React, { Component } from 'react';
import { CopyToClipboardNative } from '../components/CopyToClipboardNative';
import { formatDiploma, dateFields, utcDatetime } from '../lib/Scopes';
import './CompactDiploma.css';
import { Tooltip } from '../components/Tooltip';

class CompactDiploma extends Component {
  state = {
    copied: false
  };

  handleFocusSignature = () => {
    this.signatureInput.select();
  };

  handleCopySignature = () => {
    this.signatureInput.select();
    this.setState({ copied: 'true' });
    this.copyTimeout = setTimeout(() => {
      this.setState({ copied: 'false' });
    }, 1800);
  };

  render() {
    return (
      <div className="CompactDiploma">
        <table>
          <tbody>
            {formatDiploma(this.props.selectedDiploma.diploma)
              .filter((group) => group.items.length !== 0)
              .map((group) => (
                <tr key={group.name} className="diplomaRows">
                  <td className="side_description">
                    <span className={`fas fa-${group.icon}`} />
                  </td>
                  <td>
                    <table className="ui table compact inner-table">
                      <tbody>
                        {group.items
                          .filter((item) => Object.keys(item).length !== 0)
                          .map((item) => {
                            if (dateFields.includes(item.name)) {
                              return (
                                <tr key={item.name}>
                                  <td>
                                    <strong>
                                      {this.props.polyglot.t(
                                        `diplomaFields.${item.name}`
                                      )}
                                    </strong>
                                  </td>
                                  <td>
                                    <Tooltip
                                      tip={this.props.polyglot.t(
                                        'Organisation.dateFollowsFormat'
                                      )}
                                    >
                                      <span className="with-description">
                                        {item.value}
                                      </span>
                                    </Tooltip>
                                  </td>
                                </tr>
                              );
                            }
                            return (
                              <tr key={item.name}>
                                <td>
                                  <strong>
                                    {this.props.polyglot.t(
                                      `diplomaFields.${item.name}`
                                    )}
                                  </strong>
                                </td>
                                <td>
                                  {item.name === 'level'
                                    ? this.props.polyglot.t(
                                        `educationalLevels.${item.value}`
                                      )
                                    : item.value}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            {this.props.displaySignature && (
              <tr id="signatureRow">
                <td className="side_description">
                  <span className="fas fa-signature" />
                </td>
                <td>
                  <table className="ui table compact inner-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            {this.props.polyglot.t('diplomaFields.signedFor')}
                          </strong>
                        </td>
                        <td>
                          {this.props.selectedDiploma.diploma.clientDisplayName}{' '}
                          ({this.props.selectedDiploma.diploma.clientID})
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            {this.props.polyglot.t('diplomaFields.signedAt')}
                          </strong>
                        </td>
                        <td>
                          {utcDatetime(
                            this.props.selectedDiploma.diploma.timestamp
                          )}
                        </td>
                      </tr>
                      <tr id="textSignatureRow">
                        <td>
                          <strong>
                            {this.props.polyglot.t(`diplomaFields.signature`)}
                          </strong>
                        </td>
                        <td>
                          <div className="ui action input signature">
                            <input
                              className="signature-input"
                              type="text"
                              onFocus={this.handleFocusSignature}
                              ref={(input) => (this.signatureInput = input)}
                              value={this.props.selectedDiploma.signedAuditId}
                            />
                            <CopyToClipboardNative
                              text={this.props.selectedDiploma.signedAuditId}
                              onCopy={this.handleCopySignature}
                            >
                              <button className="ui button">
                                <span className="not-mobile">
                                  {this.props.polyglot.t('Organisation.copy')}
                                </span>
                              </button>
                            </CopyToClipboardNative>
                          </div>
                          <div
                            id="copiedNotification"
                            className={`${
                              this.state.copied === 'true'
                                ? 'show disappeared'
                                : ''
                            }`}
                          >
                            {this.props.polyglot.t(
                              `Organisation.tokenEntry.signatureCopied`
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CompactDiploma;
