import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Settings from './Settings';
import Footer from './Footer';
import './App.css';
import './Landing.css';
import './animations.css';
import { scrollTop } from './lib/Util';
import demoImage from './icons/demoIcon.svg';
import documentImage from './icons/documentIcon.svg';
import News from './components/News';

class Landing extends Component {
  state = {
    fadedSections: false,
    onTop: true,
    onHalf: false,
    pastOrganisation: false,
    choseGraduate: false,
    choseOrganization: false,
    readyToRedirect: false,
    redirect: false,
    mobile: true
  };

  handleLogin = (clientId) => {
    this.setState({ loggedIn: true, clientId });
  };

  handleLogout = () => {
    this.setState({ loggedOut: false, clientId: '' });
  };

  handleMoreUnhover = () => {
    this.setState({
      fadedSections: false
    });
  };

  handleMoreHover = () => {
    this.setState({
      fadedSections: true
    });
  };

  updateWindowSize = () => {
    if (window.innerWidth <= 782) {
      this.setState({
        mobile: true
      });
    } else {
      this.setState({
        mobile: false
      });
    }
    this.handleScroll();
  };

  componentDidMount() {
    scrollTop();
    this.updateWindowSize();
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    window.addEventListener('resize', this.updateWindowSize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateWindowSize);
  }

  goToGraduate = () => {
    scrollTop();
    this.setState({
      choseGraduate: true,
      readyToRedirect: true
    });
    setTimeout(() => {
      this.setState({
        redirect: true
      });
    }, 500);
  };

  goToOrganization = () => {
    scrollTop();
    this.setState({
      choseOrganization: true,
      readyToRedirect: true
    });
    setTimeout(() => {
      this.setState({
        redirect: true
      });
    }, 500);
  };

  handleScroll = () => {
    if (window.scrollY < 200) {
      this.setState({
        onTop: true,
        onHalf: false
      });
    } else {
      this.setState({
        onTop: false
      });
      if (document.documentElement.clientWidth <= 782) {
        this.setState({
          onHalf: window.scrollY < document.documentElement.clientHeight + 200
        });
        this.setState({
          pastOrganisation:
            window.scrollY < document.documentElement.clientHeight
        });
      }
    }
  };

  handleLanguage = (lang) => {
    this.props.handleLanguage(lang);
  };

  render() {
    if (this.state.redirect) {
      if (this.state.choseGraduate) {
        return <Redirect push to="/graduate/menu" />;
      }
      return <Redirect push to="/organisation/menu" />;
    }

    return (
      <div
        className={`App ${this.state.readyToRedirect ? 'redirect-ready' : ''}`}
        id="App"
      >
        <div id="Landing">
          {!this.state.mobile && (
            <a
              href={`${this.state.onTop ? '#More' : '#'}`}
              id="More"
              className="More block"
              onMouseEnter={this.handleMoreHover}
              onMouseLeave={this.handleMoreUnhover}
            >
              {this.state.onTop
                ? this.props.polyglot.t('Landing.showMore')
                : this.props.polyglot.t('Landing.backToTop')}{' '}
              <br />
              <span
                className={`fas ${
                  this.state.onTop ? 'fa-arrow-down' : 'fa-arrow-up'
                }`}
              />
            </a>
          )}
          <div className="ui equal width center aligned padded grid">
            <div className="compact row">
              <div
                id="graduate"
                className={`column Section Section-left ${
                  this.state.fadedSections ? 'Faded' : ''
                } ${this.state.choseGraduate ? 'full-width' : ''} ${
                  this.state.choseOrganization ? 'min-width' : ''
                }`}
              >
                <div className="Homecard" onMouseEnter={this.animate}>
                  <div
                    className={`Description ${
                      this.state.choseOrganization ? 'custom-hidden' : false
                    }`}
                  >
                    <span className="fas fa-user-graduate" />
                    {/*            <GraduationCap /> */}
                    <h2>{this.props.polyglot.t('Landing.category1')}</h2>
                    <div className="text-area">
                      <p>{this.props.polyglot.t('Landing.paragraph1')}</p>
                    </div>
                  </div>
                  <div
                    className={`center aligned ${
                      this.state.choseOrganization ? 'custom-hidden' : false
                    }`}
                  >
                    <button
                      className="ui fancy button"
                      onClick={this.goToGraduate}
                    >
                      {this.props.polyglot.t('Landing.continue')}{' '}
                      <span className="fas fa-arrow-right animatable" />
                    </button>
                  </div>
                </div>
                {this.state.mobile && (
                  <a
                    href="#organization"
                    id="organization-link"
                    className="More block"
                  >
                    {this.props.polyglot.t(`Landing.category2`)}
                    <br />
                    <span className="fas fa-arrow-down" />
                  </a>
                )}
              </div>
              <div
                id="organization"
                className={`column Section Section-right ${
                  this.state.fadedSections ? 'Faded' : ''
                } ${this.state.choseOrganization ? 'full-width' : ''} ${
                  this.state.choseGraduate ? 'min-width' : ''
                }`}
              >
                {this.state.mobile && (
                  <div
                    className={`compact row ${
                      this.state.readyToRedirect ? 'custom-hidden' : ''
                    }`}
                  >
                    <a href="#" id="graduate-link" className="More block">
                      {this.props.polyglot.t(`Landing.category1`)}
                      <br />
                      <span className="fas fa-arrow-up" />
                    </a>
                  </div>
                )}
                <div className="Homecard">
                  <div
                    className={`Description ${
                      this.state.choseGraduate ? 'custom-hidden' : false
                    }`}
                  >
                    {/* <Briefcase /> */}
                    <span className="fas fa-university" />
                    <h2>{this.props.polyglot.t('Landing.category2')}</h2>
                    <div className="text-area">
                      <p>{this.props.polyglot.t('Landing.paragraph2')}</p>
                    </div>
                  </div>
                  <div
                    className={`center aligned ${
                      this.state.choseGraduate ? 'custom-hidden' : false
                    }`}
                  >
                    <button
                      className="ui fancy button"
                      onClick={this.goToOrganization}
                    >
                      {this.props.polyglot.t('Landing.continue')}{' '}
                      <span className="fas fa-arrow-right animatable" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`Help ${
            this.state.readyToRedirect ? 'custom-hidden' : ''
          }`}
        >
          {this.state.mobile && (
            <a
              href={`${this.state.onHalf ? '#More' : '#'}`}
              id="More"
              className="More block"
            >
              {this.state.onHalf
                ? this.props.polyglot.t('Landing.showMore')
                : this.props.polyglot.t('Landing.backToTop')}
              <br />
              <span
                className={`fas ${
                  this.state.onHalf ? 'fa-arrow-down' : 'fa-arrow-up'
                }`}
              />
            </a>
          )}
          <div className="Help-Content">
            <div className="content-section" id="description">
              <h2>{this.props.polyglot.t('Landing.title1')}</h2>
              <p>
                {this.props.polyglot.t('Landing.paragraph3')}
                <strong>
                  {this.props.polyglot.t('Landing.paragraph3extra')}
                </strong>
                .
              </p>
              <p>{this.props.polyglot.t('Landing.paragraph4')}</p>
              <p>{this.props.polyglot.t('Landing.paragraph5')}</p>
              <News header={this.props.polyglot.t('Landing.news')} />
              {Settings.announcement.content(this.props.polyglot)}
              <div className="landing-cards">
                <div className="card">
                  <div className="image">
                    <i className="fa fa-5x fa-lock" />
                  </div>
                  <div className="content">
                    <h3>{this.props.polyglot.t('Landing.Cards.title1')}</h3>
                    <p>{this.props.polyglot.t('Landing.Cards.paragraph1')}</p>
                  </div>
                </div>
                <div className="card">
                  <div className="image">
                    <i className="fa fa-5x fa-check-circle" />
                  </div>
                  <div className="content">
                    <h3>{this.props.polyglot.t('Landing.Cards.title2')}</h3>
                    <p>{this.props.polyglot.t('Landing.Cards.paragraph2')}</p>
                  </div>
                </div>
                <div className="card">
                  <div className="image">
                    <i className="fa fa-5x fa-exchange-alt" />
                  </div>
                  <div className="content">
                    <h3>{this.props.polyglot.t('Landing.Cards.title3')}</h3>
                    <p>{this.props.polyglot.t('Landing.Cards.paragraph3')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Help-Content Help-Gray">
            <div className="content-section-paragraphs split">
              <h2>{this.props.polyglot.t('Landing.title2')}</h2>
              <p>{this.props.polyglot.t('Landing.paragraph6')}</p>
              <h2>{this.props.polyglot.t('Landing.title3')}</h2>
              <p>
                {this.props.polyglot.t('Landing.paragraph7a')}{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://register.ediplomas.gr/"
                >
                  eDiplomas Register
                </a>{' '}
                {this.props.polyglot.t('Landing.paragraph7b')}
              </p>
            </div>
          </div>
          <div className="Help-Content">
            <div className="content-section" id="material">
              <h2>{this.props.polyglot.t('Landing.presentations')}</h2>
              <div className="links-container">
                <div className="links-section">
                  <h3>{this.props.polyglot.t('Landing.articlesTitle')}</h3>
                  <ul>
                    <li>
                      <a href="/news/emrex" rel="noreferrer">
                        {this.props.polyglot.t('Landing.emrex')}
                      </a>
                    </li>
                    <li>
                      <a href="/news/ebsi-video" rel="noreferrer">
                        {this.props.polyglot.t('Landing.videoEBSI')}
                      </a>
                    </li>
                    <li>
                      <a href="/news/blueprints" rel="noreferrer">
                        {this.props.polyglot.t('Landing.blueprintsInfo')}
                      </a>
                    </li>
                    <li>
                      <a href="/news/jades">
                        {this.props.polyglot.t('Landing.jadesInfo')}
                      </a>
                    </li>
                    <li>
                      <a href="/news/ebsi" rel="noreferrer">
                        {this.props.polyglot.t('Landing.articleEBSI')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="links-section">
                  <h3>{this.props.polyglot.t('Landing.presentationsTitle')}</h3>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="/presentationContent/eDiplomas_UoI_GUnet_Meeting_Athens_June_2022.pdf"
                      >
                        {this.props.polyglot.t('Landing.presentationUoi')}
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="http://open.gunet.gr/ediplomas-presentation/"
                      >
                        {this.props.polyglot.t(
                          'Landing.interactivePresentation'
                        )}
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="/presentationContent/eDiplomas_CRETE_5-11-2020_GR.pdf"
                      >
                        {this.props.polyglot.t('Landing.presentationΗμερίδα')}
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="/presentationContent/eDiplomas_EUNIS_HELSINKI_June_2020.pdf"
                      >
                        {this.props.polyglot.t('Landing.presentationEUNIS')}
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="/presentationContent/eDiplomas_GUnet_Meeting_Athens_June_2019.pdf"
                      >
                        {this.props.polyglot.t('Landing.presentationGunet')}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="info-cards doc-links">
                <div className="card" id="card-demo">
                  <div className="image">
                    <img id="demo-image" src={demoImage} />
                  </div>
                  <div className="content">
                    <div className="description">
                      <a
                        href="http://open.gunet.gr/ediplomas-presentation/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {this.props.polyglot.t('Landing.demoFilename')}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card" id="card-docs">
                  <div className="image">
                    <img id="book-image" src={documentImage} />
                    {/* src={bookImage} */}
                  </div>
                  <div className="content">
                    <div className="description">
                      <a href="/documentation">
                        {this.props.polyglot.t('Landing.documentationName')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer polyglot={this.props.polyglot} />
      </div>
    );
  }
}

export default Landing;
